import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete, Checkbox, Container, FormControl, InputAdornment, List, Paper } from '@mui/material'
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useForm, Controller } from 'react-hook-form';
import apiService from '../../../api/apiService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import DataContext from '../../../context/DataContext';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import notifyService from '../../../api/notifySerivce';
import { Link, useParams } from 'react-router-dom';
const Investorprofile = () => {
  const { id } = useParams()
  let userId
  if (id !== undefined) {
    userId = atob(id)
  }
  const { validateNumberonly, validatePhoneNumber, validateEmail, yearListApi, loading,
    setLoading, yearListValue, navigator, VisuallyHiddenInput, icon, checkedIcon } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm(
    { values: formData }
  );
  const [lableName, setlableName] = useState('Add')
  useEffect(() => {
    if (window.location.hash !== '#/admin/investor/add') {
      (async () => await getApi())()
      setlableName('Update')
    }
    dropdownValueApi()
    fundprogramlistApi()
    yearListApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [preferredInvestorTypeList, setPreferredInvestorTypeList] = useState([])
  const [fundingStatusesList, setFundingStatusesList] = useState([])
  const [investmentTicketSize, setInvestmentTicketSize] = useState([])
  const [instrumentTypeData, setInstrumentTypeData] = useState([])
  const [revenuesList, setLevenuesList] = useState([])
  const [startupinstrumentTypeList, setStartupinstrumentTypeList] = useState([])
  const [businessModelsData, setBusinessModelsData] = useState([])
  const [purposeList, setPurposeList] = useState([])
  const [technologiesList, setTechnologiesList] = useState([])
  const [sectorData, setSectorData] = useState([])
  const [startupStagesList, setStartupStagesList] = useState([])
  const [geoPreList, setGeoPreList] = useState([])
  const [revenueStageList, setRevenueStageList] = useState([])
  const [investmentData, setInvestmentData] = useState([])
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=investor`, '', 'get').then((res) => {
      var val = res.data.dropDownValues
      // setPreferredInvestorTypeList(val.prefInvestorTypes)
      setInvestmentTicketSize(val.desiredFundingAmount)
      setFundingStatusesList(val.previousFundingStatus)
      // setLevenuesList(val.revenueStages)
      setBusinessModelsData(val.businessModels)
      // setPurposeList(val.purposes)
      // setTechnologiesList(val.technologyStacks)
      setSectorData(val.sectors)
      setStartupStagesList(val.startupStages)
      setGeoPreList(val.geoPreferences)
      setRevenueStageList(val.revenueStages)
      setInstrumentTypeData(val.prefFundingInstruments)
      setInvestmentData(val.investCharacterstics)

    })
  }
  const getApi = async () => {
    const id = Number(userId)
    await apiService(`userprofile/get?userId=${id}`, '', 'get').then((res) => {
      if (res) {
        let result = res.data
        if (result.investTicketSize !== undefined) {
          setFormData(result)
          // setOrganizationTypeValue(result.organizationType);
          setInstrumentTypeValueValue(result.investmentInstrumentType);
          if (result.investmentCharacterstics) {
            if (result.investmentCharacterstics.length === 1) {
              if (result.investmentCharacterstics[0] === "") {
                result.investmentCharacterstics = undefined
              }
            }
            if (result.investmentCharacterstics !== undefined) {
              setInvestmentCharactersticsValue(result.investmentCharacterstics)
            }
          }
          if (result.geoPreferences) {
            if (result.geoPreferences.length === 1) {
              if (result.geoPreferences[0] === "") {
                result.geoPreferences = undefined
              }
            }
            if (result.geoPreferences !== undefined) {
              setGeoPreferencesValue(result.geoPreferences)
            }
          }
          setInvestTicketSizeValue(result.investTicketSize)
          // setPreferToValue(result.preferTo)
          setStartupStagesValue(result.startupStages)
          setRevenueStageValue(result.revenueStage)
          if (result.organizationLogoName === undefined) {
            setLogoDisplayUrl('/images/user.png')
          } else {
            setLogoDisplayUrl(result.organizationLogoName)
            setLogoDisplayValue(result.organizationLogoName)
          }
          setFundingStatusesValue(result.fundingStatuses)
          if (result.preferredSectors) {
            setPreferredSectors(result.preferredSectors)
          }

          var con = result.openToCoInvest.toString()
          setValue('openToCoInvest', con)
          setOpenToCoInvestValue(con)
          // setGeoPreferencesValue(result.geoPreferences)
          setBusinessModelsValue(result.businessModels)
        } else {
          setValue('openToCoInvest', result.openToCoInvest.toString())
          setOpenToCoInvestValue(result.openToCoInvest.toString())
          setFormData(result)
        }

        setAcceptPrivacyPolicyChecked(result.acceptPrivacyPolicy)
        setAcceptTermsAndConditionsChecked(result.acceptTermsAndConditions)
        setValue('acceptPrivacyPolicy', result.acceptPrivacyPolicy)
        clearErrors('acceptPrivacyPolicy')
        setValue('acceptTermsAndConditions', result.acceptTermsAndConditions)
        clearErrors('acceptTermsAndConditions')
        if (result.noOfPortfolioStartups === 0) {
          setValue('noOfPortfolioStartups', '')
        }
      }
    })
  }
  const [termsAndConditionsHTML, setTermsAndConditionsHTML] = useState('')
  function termsAndConditionsMarkup() {
    return { __html: termsAndConditionsHTML };
  }
  const [privacyPolicyHTML, setPrivacyPolicyHTML] = useState('')
  function privacyPolicyMarkup() {
    return { __html: privacyPolicyHTML };
  }
  const [instrumentTypeValue, setInstrumentTypeValueValue] = useState([]);
  const handleSelectInstrument = (value) => {
    setInstrumentTypeValueValue(value);
    setValue('investmentInstrumentType', value)
    clearErrors('investmentInstrumentType');
  }


  const [investmentCharactersticsValue, setInvestmentCharactersticsValue] = useState([]);
  const handleSelectInvestment = (value) => {
    setInvestmentCharactersticsValue(value);
    setValue('investmentCharacterstics', value)
    clearErrors('investmentCharacterstics');
  };

  const [investTicketSizeValue, setInvestTicketSizeValue] = useState([]);
  const handleSelectTicketSize = (value) => {
    setInvestTicketSizeValue(value);
    setValue('investTicketSize', value)
    clearErrors('investTicketSize');
  };

  // const preferToData = [
  //   "Co-Invest",
  //   "Lead the round",
  //   "Solo Invest"
  // ]
  // const [preferToValue, setPreferToValue] = useState('');
  // const handleSelectPrefer = (value) => {
  //   setPreferToValue(value);
  //   setValue('preferTo', value)
  //   clearErrors('preferTo');
  // };

  const [businessModelsValue, setBusinessModelsValue] = useState([]);
  const handleSelectBusiness = (value) => {
    setBusinessModelsValue(value);
    setValue('businessModels', value)
    clearErrors('businessModels');
  }


  const [startupStagesValue, setStartupStagesValue] = useState([]);
  const handleSelectStageStartup = (value) => {
    setStartupStagesValue(value)
    setValue('startupStages', value)
    clearErrors('startupStages');
  }

  const [revenueStageValue, setRevenueStageValue] = useState([]);
  const handleSelectRevenueStageValue = (value) => {
    setRevenueStageValue(value)
    setValue('revenueStage', value)
    clearErrors('revenueStage');
  }

  const [geoPreferencesValue, setGeoPreferencesValue] = useState([]);
  const handleSelectGeoPreferencesValue = (value) => {
    // if (value[value.length - 1] === "allSelect") {
    //   setGeoPreferencesValue(geoPreList);
    //   return;
    // } if (value[value.length - 1] === "allRemove") {
    //   setGeoPreferencesValue([]);
    //   return;
    // } else {
    //   setGeoPreferencesValue(value);
    // }
    setGeoPreferencesValue(value);
    setValue('geoPreferences', value)
    clearErrors('geoPreferences');
  }
  const [fundingStatusesValue, setFundingStatusesValue] = useState([]);
  const handleSelectFundingStatuses = (value) => {
    setFundingStatusesValue(value)
    setValue('fundingStatuses', value)
    clearErrors('fundingStatuses');
  }

  const [preferredSectors, setPreferredSectors] = useState([]);
  const handleSelectSector = (value) => {
    setPreferredSectors([])
    if (value.length === 0) {
      return;
    }
    if (value[value.length - 1] === 'Select All') {


      var dataa = sectorData.filter((item) => item !== 'Remove All' && item !== 'Select All')

      setPreferredSectors(dataa);
      setValue('preferredSectors', dataa)
      clearErrors('preferredSectors');

    } else if (value[value.length - 1] === 'Remove All') {
      setPreferredSectors([]);
      setValue('preferredSectors', [])

    } else {
      setPreferredSectors(value);
      setValue('preferredSectors', value)
      clearErrors('preferredSectors');

    }
  };

  const [fundingList, setFundingList] = useState([]);
  const fundprogramlistApi = async () => {
    await apiService(`partner/fundprograms`, '', 'get').then((res) => {
      if (res) {
        setFundingList(res.data.fundingPrograms)
      }
    })
  }

  // const [organizationTypeValue, setOrganizationTypeValue] = useState('');
  const handleSelectOrganizationType = (value) => {
    // setOrganizationTypeValue(value);
    setValue('organizationType', value)
    clearErrors('organizationType');
    const name = fundingList.filter(item => ((item.partner)).includes(value)
      || ((item.partner)).includes(value)
    )
    setValue('fundingProgramVersionConfigId', name[0].versionId)
  }

  const [logoDisplayValue, setLogoDisplayValue] = useState(null)
  const [logoDisplayUrl, setLogoDisplayUrl] = useState('/images/user.png')
  let logoselecetdFile
  const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
    setValue(filekey, "")
    setValue(fileName, "")
    setdata('')
    setUrl('')
    if (event.target === undefined) {
      logoselecetdFile = event
    } else {
      logoselecetdFile = event.target.files[0]
    }
    if (logoselecetdFile) {
      var reader = new FileReader();
      var imagetype = logoselecetdFile.type
      var imagedatatype = imagetype.split("/")
      var img_crt_type = imagedatatype[1]
      if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png") {
        if (event.target === undefined) {
          setdata(event)
        } else {
          setdata(event.target.files[0])
        }
        var fileValue = logoselecetdFile
        reader.readAsDataURL(logoselecetdFile);
        reader.onload = () => {
          var logourl1 = reader.result;
          var spl = logourl1.split(',')
          var ImageValue = spl[1]
          var img_name = fileValue.name
          setUrl(logourl1)
          setValue(filekey, ImageValue)
          setValue(fileName, img_name)
          clearErrors(filekey);
          clearErrors(fileName);
          setLogoInvalid(false)
        }
      }
    }
  }


  const handleSelectYearData = (value) => {
    setValue('estdYear', value)
    clearErrors('estdYear')
  }
  const [acceptPrivacyPolicyChecked, setAcceptPrivacyPolicyChecked] = useState(null);
  const handleChangeAcceptPrivacyPolicy = (event) => {
    setAcceptPrivacyPolicyChecked(event.target.checked);
    if (event.target.checked) {
      setValue('acceptPrivacyPolicy', event.target.checked)
      clearErrors('acceptPrivacyPolicy')
    } else {
      setValue('acceptPrivacyPolicy', null)
    }
  };
  const [acceptTermsAndConditionsChecked, setAcceptTermsAndConditionsChecked] = useState(null);
  const handleChangeAcceptTermsAndConditions = (event) => {
    setAcceptTermsAndConditionsChecked(event.target.checked);
    if (event.target.checked) {
      setValue('acceptTermsAndConditions', event.target.checked)
      clearErrors('acceptTermsAndConditions')
    } else {
      setValue('acceptTermsAndConditions', null)
    }
  };
  const [openToCoInvestValue, setOpenToCoInvestValue] = useState(false)
  const [noOfPortfolioStartupsValue, setNoOfPortfolioStartupsValue] = useState(false)
  const [logoInvalid, setLogoInvalid] = useState(false);
  const onSubmit = (data) => {
    if (logoDisplayValue !== null) {

      var getform = data
      if (data.openToCoInvest === "true") {
        getform.openToCoInvest = true
      } else {
        getform.openToCoInvest = false
      }
      if (data.investmentCharacterstics === '') {
        getform.investmentCharacterstics = []
      }
      if (data.geoPreferences === '') {
        getform.geoPreferences = []
      }
      getform.id = Number(data.id)
      getform.noOfPortfolioStartups = Number(data.noOfPortfolioStartups)
      debugger
      if (getform.noOfPortfolioStartups !== 0) {
        setLoading(true);
        setNoOfPortfolioStartupsValue(false)
        apiService('admin/userprofile/save', getform, 'post').then((res) => {
          setLoading(false);
          if (res) {
            if (res.data.responseStatus === "Success") {
              notifyService('success', 'Success', 'Saved successfully !!')
              handleCancel()
            }
          }
        })
      } else {
        setNoOfPortfolioStartupsValue(true)
      }
      // if (typeof (data.geoPreferences) !== "object") {
      //   getform.geoPreferences = data.geoPreferences.split(',');
      // }


    } else {
      setLogoInvalid(true)
      notifyService('danger', 'Error', 'Please upload Profile Logo')
    }
  };
  const handleCancel = () => {
    navigator('/admin/investor/list')
  }
  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date}/${month}/${year}`;
  }
  return (
    <section>
      <div className='container-fluid'>
        <div className='form_div chart'>
          <div className='div_header'>
            <h6>{lableName} Investor Profile Details</h6>
          </div>
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className='d-none'>
                <div className="form-group">
                  <Controller name="id" control={control} defaultValue="0"
                    render={({ field }) =>
                      <TextField
                        {...field}
                      />} />
                </div>
              </div>
              <div className='d-none'>
                <div className="form-group">
                  <Controller name="organizationLogoName" control={control} defaultValue=""
                    render={({ field }) =>
                      <TextField
                        {...field}
                      />} />
                </div>
              </div>
              <div className='d-none'>
                <div className="form-group">
                  <Controller name="organizationLogo" control={control} defaultValue=""
                    render={({ field }) =>
                      <TextField
                        {...field}
                      />} />
                </div>
              </div>
              <div className='d-none'>
                <div className="form-group">
                  <Controller name="fundingProgramVersionConfigId" control={control} defaultValue="0"
                    render={({ field }) =>
                      <TextField
                        {...field}
                      />} />
                </div>
              </div>
              <div className='col-lg-9 col-12'>
                <div className='row'>
                  <div className='col-lg-6 col-12'>
                    <div className="form-group mt-2">
                      <Controller name="name" control={control} defaultValue="" rules={{ required: 'Organization name is required' }}
                        render={({ field }) =>
                          <TextField
                            variant='outlined'
                            label="Name of Organization *"
                            placeholder='Enter Organization Name'
                            autoFocus
                            fullWidth
                            {...field}
                          />
                        } />
                      <FormHelperText className='text-danger'>{errors.name && errors.name.message}</FormHelperText>
                    </div>
                  </div>
                  <div className='col-lg-6 col-12'>
                    <div className="form-group mt-2">
                      <Controller name="estdYear" control={control} defaultValue="" rules={{ required: 'Year of Establishment is required' }}
                        render={({ field }) =>
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            options={yearListValue}
                            onChange={(e, selectedOptions) => handleSelectYearData(selectedOptions)}
                            renderInput={(params) => <TextField {...params} label="Year of Establishment *" />}
                          />
                        } />
                      <FormHelperText className='text-danger'>{errors.estdYear && errors.estdYear.message}</FormHelperText>
                    </div>
                  </div>
                  <div className='col-lg-6 col-12'>
                    <div className="form-group mt-2">
                      <Controller name="noOfPortfolioStartups" control={control} defaultValue="" rules={{ required: 'Number of Portfolio Startups is required'}}
                        render={({ field }) =>
                          <TextField
                            variant='outlined'
                            label="Number of Portfolio Startups *"
                            placeholder='Enter Number of Portfolio Startups'
                            fullWidth
                            {...field}
                            onKeyDown={(e) => validateNumberonly(e)}
                          />
                        } />
                      <FormHelperText className='text-danger'>{errors.noOfPortfolioStartups && errors.noOfPortfolioStartups.message}</FormHelperText>
                      <FormHelperText className='text-danger'>{noOfPortfolioStartupsValue === true && 'Portfolio can not be zero'}</FormHelperText>
                    </div>
                  </div>
                  <div className='col-lg-6 col-12'>
                    <div className="form-group mt-2">
                      <Controller name="website" control={control} defaultValue="" rules={{ required: 'Website is required' }}
                        render={({ field }) =>
                          <TextField
                            variant='outlined'
                            label="Website *"
                            placeholder='Enter Website'
                            fullWidth
                            {...field}
                          />} />
                      <FormHelperText className='text-danger'>{errors.website && errors.website.message}</FormHelperText>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-12 text-center mb-3'>
                <div className='logo_div'>
                  <img src={logoDisplayUrl} className='user_logo' alt='' />
                  <div className='camera_icon'>
                    <Button onChange={(e) => handleFileUpload(e, "organizationLogo", "organizationLogoName", setLogoDisplayValue, setLogoDisplayUrl)} component="label" startIcon={<CameraAltIcon />}>
                      <VisuallyHiddenInput type="file" />
                    </Button>
                  </div>
                </div>
                <div>
                  <span>Organization Logo <span className='text-danger'>*</span></span>
                </div>
                <div>
                  <span className='fn-12'>Recommented Size (128px * 128px)</span>
                </div>
                <div>
                  <span className='fn-12'>Maximum 10 mb allowed doc (png, jpg, jpeg) <span className='text-danger'>*</span></span>
                </div>
                <>
                  {
                    (logoInvalid === true) ? (<span className='text-danger'>Organization logo is required</span>) : (<></>)
                  }
                </>
              </div>
              <div className='col-lg-12 col-12'>
                <div className="form-group mt-2">
                  <Controller name="organizationBrief" control={control} defaultValue="" rules={{ required: 'Brief about Organziation is required' }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        multiline
                        label="Brief about Organziation *"
                        placeholder='Enter Brief about Organziation'
                        fullWidth
                        {...field}
                      />} />
                  <FormHelperText className='text-danger'>{errors.organizationBrief && errors.organizationBrief.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-3 col-12'>
                <div className="form-group mt-2">
                  <Controller name="email" control={control} defaultValue="" rules={{ required: 'Email Id is required', validate: validateEmail }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Email Id *"
                        placeholder='Enter Email Id'
                        fullWidth
                        inputProps={
                          { readOnly: lableName === 'Update' ? true : false, }
                        }
                        {...field}
                      />
                    } />
                  <FormHelperText className='text-danger'>{errors.email && errors.email.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-3 col-12'>
                <div className="form-group mt-2">
                  <Controller name="phone" control={control} defaultValue="" rules={{ required: 'Contact is required', validate: validatePhoneNumber }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Contact *"
                        placeholder='Enter Contact'
                        fullWidth
                        {...field}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +91
                            </InputAdornment>
                          ),
                          maxLength: 10
                        }}
                      />
                    } />
                  <FormHelperText className='text-danger'>{errors.phone && errors.phone.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-3 col-12'>
                <div className="form-group mt-2">
                  <Controller name="city" control={control} defaultValue="" rules={{ required: 'City is required' }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="City *"
                        placeholder='Enter City'
                        fullWidth
                        {...field}
                      />
                    } />
                  <FormHelperText className='text-danger'>{errors.city && errors.city.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-3 col-12'>
                <div className="form-group mt-2">
                  <Controller name="state" control={control} defaultValue="Tamil Nadu" rules={{ required: 'State is required' }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="State *"
                        placeholder='Enter State'
                        fullWidth
                        {...field}
                      />
                    } />
                  <FormHelperText className='text-danger'>{errors.state && errors.state.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-3 col-12'>
                <div className="form-group mt-2">
                  <Controller name="country" control={control} defaultValue="India" rules={{ required: 'Country is required' }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Country *"
                        placeholder='Enter Country'
                        fullWidth
                        {...field}
                      />
                    } />
                  <FormHelperText className='text-danger'>{errors.country && errors.country.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-3 col-12'>
                <div className="form-group mt-2">
                  <Controller name="portfolio" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Link to Portfolio"
                        placeholder='Enter Link to Portfolio'
                        fullWidth
                        {...field}
                      />
                    } />
                  <FormHelperText className='text-danger'>{errors.portfolio && errors.portfolio.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-3 col-12'>
                <div className="form-group mt-2">
                  <Controller name="linkedIn" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Link to LinkedIn"
                        placeholder='Enter Link to LinkedIn'
                        fullWidth
                        {...field}
                      />
                    } />
                  <FormHelperText className='text-danger'>{errors.linkedIn && errors.linkedIn.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-3 col-12'>
                <div className="form-group mt-2">
                  <Controller name="twitter" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Link to twitter"
                        placeholder='Enter Link to twitter'
                        fullWidth
                        {...field}
                      />
                    } />
                  <FormHelperText className='text-danger'>{errors.twitter && errors.twitter.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-12 col-12'>
                <div className='div_header'>
                  <h6>Point of Contact Details</h6>
                </div>
                <div className='row'>
                  <div className='col-lg-3 col-12'>
                    <div className="form-group mt-2">
                      <Controller name="pocName" control={control} defaultValue="" rules={{ required: 'Name is required' }}
                        render={({ field }) =>
                          <TextField
                            variant='outlined'
                            label="Name *"
                            placeholder='Enter Name'
                            fullWidth
                            {...field}
                          />
                        } />
                      <FormHelperText className='text-danger'>{errors.pocName && errors.pocName.message}</FormHelperText>
                    </div>
                  </div>
                  <div className='col-lg-3 col-12'>
                    <div className="form-group mt-2">
                      <Controller name="pocDesignation" control={control} defaultValue="" rules={{ required: 'Designation is required' }}
                        render={({ field }) =>
                          <TextField
                            variant='outlined'
                            label="Designation *"
                            placeholder='Enter Designation'
                            fullWidth
                            {...field}
                          />
                        } />
                      <FormHelperText className='text-danger'>{errors.pocDesignation && errors.pocDesignation.message}</FormHelperText>
                    </div>
                  </div>
                  <div className='col-lg-3 col-12'>
                    <div className="form-group mt-2">
                      <Controller name="pocEmail" control={control} defaultValue="" rules={{ required: 'Email Id is required', validate: validateEmail }}
                        render={({ field }) =>
                          <TextField
                            variant='outlined'
                            label="Email Id *"
                            placeholder='Enter Email Id'
                            fullWidth
                            {...field}
                          />
                        } />
                      <FormHelperText className='text-danger'>{errors.pocEmail && errors.pocEmail.message}</FormHelperText>
                    </div>
                  </div>
                  <div className='col-lg-3 col-12'>
                    <div className="form-group mt-2">
                      <Controller name="pocPhone" control={control} defaultValue="" rules={{ required: 'Phone Number is required', validate: validatePhoneNumber }}
                        render={({ field }) =>
                          <TextField
                            variant='outlined'
                            label="Phone Number *"
                            placeholder='Enter Phone Number'
                            fullWidth
                            {...field}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +91
                                </InputAdornment>
                              ), maxLength: 10
                            }}
                          />} />
                      <FormHelperText className='text-danger'>{errors.pocPhone && errors.pocPhone.message}</FormHelperText>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-12 col-12'>
                <div className='div_header'>
                  <h6>Startup Preference</h6>
                </div>
                <div className='row'>
                  <div className='col-lg-6 col-12'>
                    <div className="form-group mt-2">
                      <FormControl sx={{ width: '100%' }}>
                        <Controller name="fundingStatuses" control={control} defaultValue="" rules={{ required: 'Last funding round status is required' }}
                          render={({ field }) =>
                            <Autocomplete
                              disableClearable
                              disablePortal
                              multiple
                              {...field}
                              limitTags={2}
                              value={fundingStatusesValue}
                              options={fundingStatusesList}
                              onChange={(e, selectedOptions) => handleSelectFundingStatuses(selectedOptions)}
                              renderInput={(params) => <TextField {...params} label="Last funding round status *" />}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                            />
                          } />
                        <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                        <FormHelperText className='text-danger'>{errors.fundingStatuses && errors.fundingStatuses.message}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className='col-lg-6 col-12'>
                    <div className="form-group mt-2">
                      <FormControl sx={{ width: '100%' }}>
                        <Controller name="preferredSectors" control={control} defaultValue="" rules={{ required: 'Sector is required' }}
                          render={({ field }) =>
                            <Autocomplete
                              disableClearable
                              disablePortal
                              multiple
                              {...field}
                              limitTags={2}
                              value={preferredSectors}
                              options={sectorData}
                              onChange={(e, selectedOptions) => handleSelectSector(selectedOptions)}
                              renderInput={(params) => <TextField {...params} label="Sectors *" />}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                            />
                          } />
                        <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                        <FormHelperText className='text-danger'>{errors.preferredSectors && errors.preferredSectors.message}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className='col-lg-4 col-12'>
                    <div className="form-group mt-2">
                      <FormControl sx={{ width: '100%' }}>
                        <Controller name="organizationType" control={control} defaultValue="" rules={{ required: 'Organization Type is required' }}
                          render={({ field }) =>
                            <Autocomplete
                              disableClearable
                              disablePortal
                              {...field}
                              options={fundingList.map((option) => option.partner)}
                              onChange={(e, selectedOptions) => handleSelectOrganizationType(selectedOptions)}
                              renderInput={(params) => <TextField {...params} label="Organization Type *" />}
                            />
                          } />
                        <FormHelperText className='text-danger'>{errors.organizationType && errors.organizationType.message}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className='col-lg-4 col-12'>
                    <div className="form-group mt-2">
                      <FormControl sx={{ width: '100%' }}>
                        <Controller name="investTicketSize" control={control} defaultValue="" rules={{ required: 'Investment Ticket Size is required' }}
                          render={({ field }) =>
                            <Autocomplete
                              disableClearable
                              disablePortal
                              multiple
                              {...field}
                              limitTags={2}
                              value={investTicketSizeValue}
                              options={investmentTicketSize}
                              onChange={(e, selectedOptions) => handleSelectTicketSize(selectedOptions)}
                              renderInput={(params) => <TextField {...params} label="Investment Ticket Size (₹)*" />}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                            />
                          }
                        />
                        <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                        <FormHelperText className='text-danger'>{errors.investTicketSize && errors.investTicketSize.message}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className='col-lg-4 col-12'>
                    <div className="form-group mt-2">
                      <FormControl sx={{ width: '100%' }}>
                        <Controller name="startupStages" control={control} defaultValue="" rules={{ required: 'Stage of Startup is required' }}
                          render={({ field }) =>
                            <Autocomplete
                              disableClearable
                              disablePortal
                              multiple
                              {...field}
                              limitTags={2}
                              value={startupStagesValue}
                              options={startupStagesList}
                              onChange={(e, selectedOptions) => handleSelectStageStartup(selectedOptions)}
                              renderInput={(params) => <TextField {...params} label="Stage of Startup *" />}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                            />
                          } />
                        <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                        <FormHelperText className='text-danger'>{errors.startupStages && errors.startupStages.message}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className='col-lg-4 col-12'>
                    <div className="form-group mt-2">
                      <FormControl sx={{ width: '100%' }}>
                        <Controller name="geoPreferences" control={control} defaultValue="" rules={{ required: false }}
                          render={({ field }) =>
                            <Autocomplete
                              disableClearable
                              disablePortal
                              multiple
                              limitTags={2}
                              {...field}
                              value={geoPreferencesValue}
                              options={geoPreList}
                              onChange={(e, selectedOptions) => handleSelectGeoPreferencesValue(selectedOptions)}
                              renderInput={(params) => <TextField {...params} label="Geographical Preferences" />}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                            />
                          } />
                        <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                        <FormHelperText className='text-danger'>{errors.geoPreferences && errors.geoPreferences.message}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className='col-lg-8 col-12'>
                    <div className="form-group">
                      <FormLabel>Are you open to co-investing with other Investment agencies on the TANFUND platform? *</FormLabel>
                      <Controller name="openToCoInvest" control={control} defaultValue="" rules={{ required: false }}
                        render={({ field }) =>
                          <RadioGroup
                            row
                            value={openToCoInvestValue}
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            {...field}
                          >
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                          </RadioGroup>
                        } />
                      <FormHelperText className='text-danger'>{errors.openToCoInvest && errors.openToCoInvest.message}</FormHelperText>
                    </div>
                  </div>
                  <div className='col-lg-4 col-12'>
                    <div className="form-group mt-2">
                      <FormControl sx={{ width: '100%' }}>
                        <Controller name="revenueStage" control={control} defaultValue="" rules={{ required: 'Funding Status is required' }}
                          render={({ field }) =>
                            <Autocomplete
                              disableClearable
                              disablePortal
                              multiple
                              limitTags={2}
                              {...field}
                              value={revenueStageValue}
                              options={revenueStageList}
                              onChange={(e, selectedOptions) => handleSelectRevenueStageValue(selectedOptions)}
                              renderInput={(params) => <TextField {...params} label="Revenue Stage *" />}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                            />
                          } />
                        <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                        <FormHelperText className='text-danger'>{errors.revenueStage && errors.revenueStage.message}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className='col-lg-4 col-12'>
                    <div className="form-group mt-2">
                      <FormControl sx={{ width: '100%' }}>
                        <Controller name="investmentInstrumentType" control={control} defaultValue="" rules={{ required: 'Investment Characteristic is required' }}
                          render={({ field }) =>
                            <Autocomplete
                              disableClearable
                              disablePortal
                              multiple
                              limitTags={2}
                              {...field}
                              value={instrumentTypeValue}
                              options={instrumentTypeData}
                              onChange={(e, selectedOptions) => handleSelectInstrument(selectedOptions)}
                              renderInput={(params) => <TextField {...params} label="Investment Instrument Type *" />}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                            />
                          } />
                        <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                        <FormHelperText className='text-danger'>{errors.investmentInstrumentType && errors.investmentInstrumentType.message}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className='col-lg-4 col-12'>
                    <div className="form-group mt-2">
                      <FormControl sx={{ width: '100%' }}>
                        <Controller name="businessModels" control={control} defaultValue="" rules={{ required: 'Business Models is required' }}
                          render={({ field }) =>
                            <Autocomplete
                              disableClearable
                              disablePortal
                              multiple
                              limitTags={2}
                              {...field}
                              value={businessModelsValue}
                              options={businessModelsData}
                              onChange={(e, selectedOptions) => handleSelectBusiness(selectedOptions)}
                              renderInput={(params) => <TextField {...params} label="Business Models *" />}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                            />
                          } />
                        <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                        <FormHelperText className='text-danger'>{errors.businessModels && errors.businessModels.message}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className='col-lg-12 col-12'>
                    <div className="form-group mt-2">
                      <Controller name="startupEvaluation" control={control} defaultValue="" rules={{ required: false }}
                        render={({ field }) =>
                          <TextField
                            variant='outlined'
                            label="What do you value the most when evaluating a startup?"
                            placeholder='E.g., Team, Product, Market Size, etc.'
                            fullWidth
                            {...field}
                          />} />
                      <FormHelperText className='text-danger'>{errors.startupEvaluation && errors.startupEvaluation.message}</FormHelperText>
                    </div>
                  </div>
                  <div className='col-lg-12 col-12'>
                    <div className="form-group mt-2">
                      <FormControl sx={{ width: '100%' }}>
                        <Controller name="investmentCharacterstics" control={control} defaultValue="" rules={{ required: false }}
                          render={({ field }) =>
                            <Autocomplete
                              disableClearable
                              disablePortal
                              multiple
                              limitTags={2}
                              {...field}
                              value={investmentCharactersticsValue}
                              options={investmentData}
                              onChange={(e, selectedOptions) => handleSelectInvestment(selectedOptions)}
                              renderInput={(params) => <TextField {...params} label="Investment Characteristic you look into startups" />}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                            />
                          } />
                        <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                        <FormHelperText className='text-danger'>{errors.investmentCharacterstics && errors.investmentCharacterstics.message}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className='col-lg-12 col-12'>
                    <div className="form-group mt-2">
                      <Controller name="comments" control={control} defaultValue="" rules={{ required: false }}
                        render={({ field }) =>
                          <TextField
                            variant='outlined'
                            multiline
                            label="Any additional comments or information you would like to share *"
                            placeholder='Enter comments'
                            fullWidth
                            {...field}
                          />} />
                      <FormHelperText className='text-danger'>{errors.comments && errors.comments.message}</FormHelperText>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 mb-3'>
                <div className='div_header'>
                  <h6>Expression of interest</h6>
                </div>
                <Paper style={{ maxHeight: 300, overflow: 'auto' }}>
                  <div className='eoi_div'>
                    <p className='mb-0'>{getValues(`name`)}</p>
                    <p>{getValues(`city`)},{getValues(`state`)},{getValues(`country`)}</p>
                    <p>{getDate()}</p>
                    <p className='mb-0'>To,</p>
                    <p className='mb-0'>Mission Director & CEO,</p>
                    <p className='mb-0'>Tamil Nadu Startup and Innovation Mission,</p>
                    <p>EDII  Campus, Chennai - 600032</p>
                    <p>Subject: Expression of Interest for TANFUND Collaboration - Reg</p>
                    <p>Dear Sir/Madam,</p>
                    <p>We, {getValues(`name`)}, hereby express our keen interest in collaborating with TANFUND.</p>
                    <p>Hereby we agree to:
                      <ol className='mt-3'>
                        <li>Exploring diverse investment avenues in Tamil Nadu via the TANFUND Platform.</li>
                        <li>Evaluating the prospects of establishing an operational base in Tamil Nadu.</li>
                        <li>Offering our consent to showcase our organisation's logo and name in alignment with the collaborative efforts on the TANFUND portal.</li>
                      </ol>
                    </p>
                    <p className='mb-0'><i>Declaration:</i>
                      <ol>
                        <li>I am a duly incorporated legal entity, established in accordance with the applicable laws and regulations of the Union of India, duly authorized to engage in the business of making investments in startup enterprises, and I am in full compliance with all relevant statutory provisions and regulatory requirements.</li>
                        <li>I hereby understand the level of risk involved in investing in a startup, and hereby irrevocably and unconditionally undertake that Startup TN or TANFUND shall not undertake any liability, from and against any and all Losses, disputes (including without limitation legal and other professional fees, and expenses) incurred towards the investments undertaken by means of TANFUND Portal.</li>
                        <li>I hereby understand that StartupTN or TANFUND, in its absolute discretion, reserves the right to reject my application or discontinue access to TANFUND platform at any juncture, for reasons including, but not limited to, the provision of inaccurate or erroneous information.</li>
                      </ol>
                    </p>
                    <p>Sincerely,</p>
                    <p className='mb-0'>{getValues(`pocName`)}</p>
                    <p className='mb-0'>{getValues(`pocDesignation`)}</p>
                    <p className='mb-0'>{getValues(`name`)}</p>
                  </div>
                </Paper>
              </div>
              <div className='col-lg-12 col-12'>
                <div className='d-flex'>
                  <div>
                    <Controller name="acceptTermsAndConditions" control={control} defaultValue="" rules={{ required: 'Required' }}
                      render={({ field }) =>
                        <FormControlLabel {...field} control={<Checkbox checked={acceptTermsAndConditionsChecked} onChange={handleChangeAcceptTermsAndConditions} />}
                        />
                      } />
                    <FormHelperText className='text-danger'>{errors.acceptTermsAndConditions && errors.acceptTermsAndConditions.message}</FormHelperText>
                  </div>
                  <div className='mt-2'>
                    <p>
                      I herewith agree with <Link className='c-blue' to={'https://startuptn-public-documents.s3.ap-south-1.amazonaws.com/TANFUND_Terms.pdf'} target="_blank">Terms and Conditions</Link>.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-12 col-12'>
                <div className='d-flex'>
                  <div>
                    <Controller name="acceptPrivacyPolicy" control={control} defaultValue="" rules={{ required: 'Required' }}
                      render={({ field }) =>
                        <FormControlLabel {...field} control={<Checkbox checked={acceptPrivacyPolicyChecked} onChange={handleChangeAcceptPrivacyPolicy} />}
                        />
                      } />
                    <FormHelperText className='text-danger'>{errors.acceptPrivacyPolicy && errors.acceptPrivacyPolicy.message}</FormHelperText>
                  </div>
                  <div className='mt-2'>
                    <p>
                      I herewith agree with <Link className='c-blue' to={'https://startuptn-public-documents.s3.ap-south-1.amazonaws.com/TANFUND_PrivacyPolicy.pdf'} target="_blank">Privacy Policy</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                className="btn btn-primary submit px-3"
              >
                {
                  lableName === 'Add' ? (<span>Save</span>) : (<span>Update</span>)
                }
              </LoadingButton>
              <Button variant="contained" onClick={handleCancel} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Investorprofile