import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Container, Divider, Drawer, Tab } from '@mui/material'
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import DataContext from '../../../../context/DataContext';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: alpha(theme.palette.common.black, 0.15),
    // '&:hover': {
    //     backgroundColor: alpha(theme.palette.common.black, 0.25),
    // },
    color: "black",
    marginLeft: "10px",
    width: '80%',
    border: '0.5px solid gainsboro',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '17ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const listStartup = [
    {
        "img": "./favlogo.jpg",
        "CompanyName": "KNV Solar",
        "Des": "Company Sector"
    },
    {
        "img": "/images/GreenpodLabs.png",
        "CompanyName": "GalaxEye Space",
        "Des": "Company Sector"
    },
    {
        "img": "./favlogo.jpg",
        "CompanyName": "Greenpod Labs",
        "Des": "Company Sector"
    },
    {
        "img": "/images/GreenpodLabs.png",
        "CompanyName": "Ippo Pay",
        "Des": "Company Sector"
    },
    {
        "img": "./favlogo.jpg",
        "CompanyName": "Kaigal",
        "Des": "Company Sector"
    },
    {
        "img": "/images/GreenpodLabs.png",
        "CompanyName": "Blunav Technologies",
        "Des": "Company Sector"
    },

]

const Startuplistfilter = ({ path, startupName, viewStartupDetails, handleClose }) => {
    const { navigator,selectedYear } = useContext(DataContext)
    const [searchInputValue, setSearchInputValue] = useState('')

    useDidMountEffect(() => {
        listApi()
    }, [searchInputValue, startupName])
    const handleSearch = (value) => {
        setSearchInputValue(value)
    }
    const [startupListData, setStartupListData] = useState([])
    const [count, setCount] = useState(0)
    const listApi = () => {
        let req
        let url
        let method
        if (path === 'jury/tanfund') {
            req = {
                "fund": "tanfund",
                "searchString": searchInputValue
            }
            url = 'jury/startup/list'
            method = 'post'
        }
        if (path === 'jury/scst') {
            req = {
                "fund": "scst",
                "searchString": searchInputValue
            }
            url = 'jury/startup/list'
            method = 'post'
        }
        if (path === 'jury/tanseed') {
            req = {
                "fund": "tanseed",
                "searchString": searchInputValue
            }
            url = 'jury/startup/list'
            method = 'post'
        }
        if (path === 'investor/associated-startups') {
            let point = localStorage.getItem('point')
            if (point === 'start') {
                req = {}
                url = `partner/startups/list?searchString=${searchInputValue}`
                method = 'get'
            }
            if (point === 'inprogress') {
                req = {}
                url = `partner/startups/list?searchString=${searchInputValue}`
                method = 'get'
            }
            // if (point === 'completed') {
            //     req = {}
            //     url = `partner/startups/round/completed/list?searchString=${searchInputValue}`
            //     method = 'get'
            // }
        }
        if (path === 'validation') {
            req = {
                "round": "1",
                "year": selectedYear,
                "searchString": searchInputValue
            }

            // {
            //     "searchString": searchInputValue,
            //     "round": "1",
            //     "onboardType":0
            // }
            url = 'partner/scst/startups/list'
            method = 'post'
        }
        setStartupListData([])
        apiService(url, req, method).then((res) => {
            if (res) {
                if (res.data) {
                    setCount(res.data.count)
                    if (res.data.startups) {
                        setStartupListData(res.data.startups)
                    }
                }

            }
        }).catch((err) => {

        });
    }
    const handleStartupDetails = (startupId, partnerOrInvestorId) => {
        if (path === 'investor/associated-startups') {
            navigator(`/investor/associated-startups/details/${btoa(startupId)}/${btoa(partnerOrInvestorId)}`, { replace: true })
        } else if (path === 'validation') {
            navigator(`/scst/startups/validation/details/${btoa(startupId)}/${btoa(partnerOrInvestorId)}`, { replace: true })
        } else {
            navigator(`/${path}/details/${btoa(startupId)}/${btoa(partnerOrInvestorId)}`, { replace: true })
        }
        viewStartupDetails(startupId, partnerOrInvestorId)
        handleClose()
    }
    return (
        <>
            <div>
                <h5 className='list-head'>Startup List</h5>
                <p className='list-head1'>{count} Total</p>
                <div className='px-10'>
                    <SearchinputDiv onInput={(e) => handleSearch(e.target.value)}>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            value={searchInputValue}
                            type='search'
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </SearchinputDiv>
                </div>

                {
                    startupListData.map((row, index) => (
                        <ul className='mt-3 pl-2'>
                            <li className={row.startupName === startupName ? ('liststartup actStartup') : ('liststartup')} onClick={() => handleStartupDetails(row.startupId, row.partnerOrInvestorId)}>
                                {/* <img className='listimg' src={row.img} /> */}
                                <Avatar alt={row.startupName} src={row.logo} />
                                <div className='pl-3'>
                                    <p className='font-sm mb-0'>{row.startupName}</p>
                                </div>
                            </li>
                            <Divider />
                        </ul>
                    ))
                }

            </div>
        </>
    )
}

export default Startuplistfilter
