import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Applicationscst from './Applicationscst';
import Fundingscstview from './Fundingscstview';
import Founderscstview from './Founderscstview';
import Moreinfosave from '../investor-module/associated-startups-page/associated-details-page/pages/Moreinfosave';
import IncubatorAccelerationScst from './IncubatorAccelerationScst';
// import Moreinfosavescst from './Moreinfosavescst';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Scsttab = ({ startupGetData, startupValue, idNumber,viewStartupDetails }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <div className='overallTab'>
                <Box
                >
                    <Tabs
                        className='verticalTabCSS'
                        orientation="horizontal"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                    >
                        <Tab className='verticalLable' label="Application Overview" {...a11yProps(0)} />
                        <Tab className='verticalLable' label="Funding & Financials" {...a11yProps(1)} />
                        <Tab className='verticalLable' label="Founder Info" {...a11yProps(2)} />
                        <Tab className='verticalLable' label="Incubation & Accelaration" {...a11yProps(3)} />
                        <Tab className='verticalLable'
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Additional Info
                                    {startupValue.additionalInfoRequested === true ? (
                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                    ) : (<></>)}
                                </div>
                            }
                            {...a11yProps(4)}
                        />
                    </Tabs>
                    <TabPanel value={value} index={0}><Applicationscst details={startupGetData} detail={startupValue} /></TabPanel>
                    <TabPanel value={value} index={1}><Fundingscstview details={startupGetData} detail={startupValue} /></TabPanel>
                    <TabPanel value={value} index={2}><Founderscstview details={startupGetData} /></TabPanel>
                    <TabPanel value={value} index={3}><IncubatorAccelerationScst details={startupGetData} detail={startupValue} idNumber={idNumber} /></TabPanel>
                    <TabPanel value={value} index={4}><Moreinfosave idNumber={idNumber} detail={startupValue} viewStartupDetails={viewStartupDetails}/></TabPanel>
                </Box>
            </div>

        </div>
    )
}

export default Scsttab
