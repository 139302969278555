import React, { useContext, useEffect, useState } from 'react'
import apiService from '../../../api/apiService'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Box, Button, Container, Divider, Drawer, Tab } from '@mui/material'

// import Startupappication from './details-components/Startupappication';
// import Startupstartdetails from './details-components/Startupstartdetails';
// import Reviewrounds from './details-components/Reviewrounds';
// import Reviewquestion from './details-components/Reviewquestion';
// import Moreinfo from './details-components/Moreinfo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import './startupdetails.css'
import DataContext from '../../../context/DataContext';

// import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// import Associatedtab from './pages/Associatedtab';
// import Associatedrounds from './Associatedrounds';
// import Associatedroundssave from './Associatedroundssave';
import { Stack } from '@mui/material';
// import Associatedscsttab from './pages/Associatedscsttab';
// import Scoremodal from '../../../scst-page/Scoremodal';
// import Tanfundscoremodal from '../../../partner-module/startup-page/startup-details-page/Tanfundscoremodal';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
// import Startuplistfilter from './Startuplistfilter';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Associatedrounds from '../../investor-module/associated-startups-page/associated-details-page/Associatedrounds';
import Associatedtab from '../../investor-module/associated-startups-page/associated-details-page/pages/Associatedtab';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
// import Tanseedscoremodal from '../../../partner-module/startup-page/startup-details-page/Tanseedscoremodal';
// import { useLocation } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import Viewimage from '../../../api/Viewimage';

const TanseedAssociateddetails = ({ name, path }) => {
    const { navigator, setPageLoading, zoomToken } = useContext(DataContext)
    const { startupId } = useParams()
    const { id } = useParams()
    const { investorId } = useParams()
    debugger
    let idNumber = atob(startupId)
    let ids
    if (id) {
        ids = atob(id)
    }
    let partnerOrInvestorId
    if (investorId) {
        partnerOrInvestorId = atob(investorId)
    }
    const [startupGetData, setStartupGetData] = useState({})
    useDidMountEffect(() => {
        // eslint-disable-next-line
        var zoomTokenlet = localStorage.getItem('zoomToken')
        if (zoomTokenlet !== null) {
            zoomToken()
        }
        viewStartupDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [startupValue, setStartupValue] = useState({})
    const [roundNo, setRoundNo] = useState(1)
    const viewStartupDetails = async () => {
        setPageLoading(true)
        setStartupGetData({})
        setStartupValue({})
        apiService(`admin/startups/get?id=${idNumber}`, '', 'get').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data) {
                    if (res.data.applicationForm) {
                        setStartupGetData(res.data.applicationForm)
                    }
                    setStartupValue(res.data)
                    if (res.data.roundNo) {
                        setRoundNo(res.data.roundNo)
                        setNavTabValue((path === 'jury/tanseed' && res.data.roundNo > 1) ? '2' : '1')
                    }
                } else {
                    setStartupGetData({})
                }
            }
        })
        // infoGetInfo()
    }
    const [viewFile, setViewFile] = useState('')
    const [additionalInfo, setAdditionalInfo] = useState({})
    const infoGetInfo = () => {
        var id = Number(idNumber)
        apiService(`startup/partner/additionalinfo/get?id=${id}`, '', 'get').then((res) => {
            if (res) {
                setAdditionalInfo(res.data)
                setViewFile(res.data.additionalDocName)
            }
        })
    }
    // const [roundListData, setRoundListData] = useState([])
    // const roundJuryList = () => {
    //     var req = {
    //         "startupId": Number(idNumber),
    //         "partnerId": Number(partnerOrInvestorId),
    //     }
    //     apiService(`jury/panelround/review/list`, req, 'post').then((res) => {
    //         if (res) {
    //             if (res.data.rounds) {
    //                 setRoundListData(res.data.rounds)
    //             } else {
    //                 setRoundListData([])
    //             }

    //         }
    //     })
    // }
    const roundPartnerList = () => {
        var req = {
            "startupId": Number(idNumber)
        }
        apiService(`partner/panelround/questions/review/list`, req, 'post').then((res) => {
            if (res) {
                if (res.data.rounds) {
                    // setRoundListData(res.data.rounds)
                } else {
                    // setRoundListData([])
                }
            }
        })
    }
    // const [questionListData, setQuestionListData] = useState([])
    // const questionList = () => {
    //     var req = {
    //         "startupId": Number(idNumber),
    //         "partnerOrInvestorId": Number(partnerOrInvestorId),
    //     }
    //     apiService(`jury/panelround/questions/review/list`, req, 'post').then((res) => {
    //         if (res) {
    //             if (res.data.rounds) {
    //                 setQuestionListData(res.data.startupRounds)
    //             } else {
    //                 setQuestionListData([])
    //             }

    //         }
    //     })
    // }
    const location = useLocation();
    let role = localStorage.getItem('role')
    const handleBack = () => {
        if (role === 'ADMIN') {
            if (investorId === undefined) {
                var val = location.pathname.split('/')
                if (val[2] === 'tanseed') {
                    navigator(`admin/tanseed`)
                } else {
                    navigator(`admin/startup`)
                }

            } else {
                navigator(`admin/associated-startups`)
            }

        } else {
            navigator(`${path}/${id}`)
        }

    }


    const [navTabValue, setNavTabValue] = useState((path === 'jury/tanseed' && roundNo > 1) ? '2' : '1');
    const handleNavTabChange = (event, newValue) => {
        setNavTabValue(newValue);
    };
    const [openList, setOpenList] = React.useState(false);
    const handleStartupList = () => {
        setOpenList(true);
    }

    const handleClose = (value) => {
        setOpenList(false);
    };

    const isRegistered = startupGetData.dpiitRegistered;

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const handleClickOpen = (value) => {
      setViewImage(value);
      setOpen(true);
    };
    const handleClose1 = () => {
      setOpen(false);
    };

    return (
        <div className='box-show'>
            <section className='bg-white'>
                <div className='row'>

                    <div className='col-lg-12 p-3'>
                        <div className='p-relative'>
                            <div className='p-3'>
                                <div className='pb-0 d-flex start_aligin_center w-100'>
                                    <h5><Button onClick={handleBack}><ArrowBackIcon /></Button> Startup Details <Button variant="outlined" sx={{ display: { sm: 'block', lg: 'none', marginLeft: '10px' } }} onClick={handleStartupList}>
                                        <FilterAltOffIcon />
                                    </Button></h5>

                                </div>
                                <hr />
                                {/* <div className='header_tag'>
                                    <span className='d-flex'><img src={startupGetData.startupLogoName} className='img-fluid user_logos' alt='' /><h3 className='startupname'>{startupValue.startupName}</h3></span>
                                    <span className='black-clr'><img src='/images/phone.png' alt='' className='mr-2' /> {startupValue.startupPhone ? (startupValue.startupPhone) : ('-')}</span>
                                    <span className='black-clr'><img src='/images/email.png' alt='' className='mr-2' /> {startupValue.startupEmail ? (startupValue.startupEmail) : ('-')}</span>
                                    <span className='social-datas'>
                                        <Stack className='social-Stack' direction="row" spacing={1}>
                                            {(startupValue.linkedIn) && (
                                                <Link to={`${startupValue.linkedIn}`} target="_blank">
                                                    <img src='/images/linkedin.png' alt='LinkedIn' className='linkedinicon' />
                                                </Link>
                                            )}
                                            {(startupValue.website) && (
                                                <Link to={`${startupValue.website}`} target="_blank">
                                                    <img src='/images/globe.png' alt='Website' className='websiteicon' />
                                                </Link>
                                            )}
                                        </Stack>
                                    </span>
                                </div> */}

                                <div className='dflex'>
                                    <div className='top-banner'>
                                        <img src={startupGetData.startupLogoName} className='img-fluid user_logos' alt='' />
                                        <div className='content'>
                                            <h3 className='startupname-sur'>{startupValue.startupName}</h3>
                                            <span className='black-clr-sur'><img src='/images/phone.png' alt='' className='mr-2' /> {startupValue.startupPhone ? (startupValue.startupPhone) : ('-')}</span>
                                            <span className='black-clr-sur ml-3'><img src='/images/email.png' alt='' className='mr-2' /> {startupValue.startupEmail ? (startupValue.startupEmail) : ('-')}</span>
                                        </div>
                                    </div>

                                    <div className='ml-auto'>
                                        <p className='dflex'>
                                            {/* <b>{startupValue.startupName}</b> */}
                                            <div
                                                className={`dpiit-button ${isRegistered ? 'registered' : 'not-registered'}`}
                                            >
                                                {isRegistered && <FaCheckCircle className="icon" />}
                                                {isRegistered ? 'DPIIT REGISTERED' : 'Non DPIIT REGISTERED'}
                                            </div>
                                        </p>
                                        {/* {details.financialProjectionsName && ( */}
                                        <div className="dflex">
                                            <p className="ml-auto">
                                                <span
                                                    className="viewdetails-sur"
                                                    onClick={() => {
                                                        handleClickOpen(startupValue.pitchdeck);
                                                    }}
                                                >
                                                    View PitchDeck
                                                </span>
                                            </p>
                                        </div>
                                        {/* )} */}
                                    </div>
                                </div>

                                {/* <div className='row'>
                        <div className='col-lg-4 col-12'>
                            <div className='d-flex'>
                                <label className='mr-3'>Startup Name :</label>
                                <p className=''><b>{startupValue.startupName}</b></p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className='d-flex'>
                                <label className='mr-3'>Email Id :</label>
                                <p className=''><b>{startupValue.startupEmail ? (startupValue.startupEmail) : ('-')}</b></p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className='d-flex'>
                                <label className='mr-3'>Phone Number :</label>
                                <p className=''><b>{startupValue.startupPhone ? (startupValue.startupPhone) : ('-')}</b></p>
                            </div>
                        </div>
                    </div> */}
                                <hr />

                                <Box sx={{ width: '100%', typography: 'body1' }}>
                                    <TabContext value={navTabValue}>
                                        {
                                            investorId !== undefined && (
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <TabList onChange={handleNavTabChange} aria-label="lab API tabs example">
                                                        <Tab label="Startups" value="1" />
                                                        <Tab label='Round Status & Score' value="2" />
                                                    </TabList>
                                                </Box>

                                            )
                                        }
                                        <TabPanel value="1" sx={{ padding: '10px 0px' }}>
                                            <Associatedtab startupGetData={startupGetData} startupValue={startupValue} idNumber={idNumber} name={name} />
                                        </TabPanel>
                                        {
                                            investorId !== undefined && (
                                                <TabPanel value="2" sx={{ padding: '10px 0px' }}>
                                                    <Associatedrounds startupId={idNumber} partnerOrInvestorId={partnerOrInvestorId} name={name} />
                                                </TabPanel>
                                            )
                                        }

                                    </TabContext>
                                </Box>
                                {/* {
                                    (path === 'jury/tanfund') && (
                                        <Tanfundscoremodal idNumber={idNumber} path={path} partnerOrInvestorId={partnerOrInvestorId} viewStartupDetails={viewStartupDetails} />
                                    )
                                }
                                {
                                    path === 'jury/scst' && (
                                        <Scoremodal idNumber={idNumber} path={path} partnerOrInvestorId={partnerOrInvestorId} viewStartupDetails={viewStartupDetails} />
                                    )
                                }
                                {
                                    (path === 'jury/tanseed') && (
                                        <Tanseedscoremodal idNumber={idNumber} path={path} partnerOrInvestorId={partnerOrInvestorId} roundNo={roundNo} viewStartupDetails={viewStartupDetails} />
                                    )
                                } */}
                                {/* <Startuplistfilter /> */}
                                {/* <div className="tab_side_left">
                                    <button onClick={handleStartupList} className="fliter_btn">
                                        <FilterAltOffIcon />                                       
                                    </button>
                                </div> */}
                                {/* <Drawer anchor="left" open={openList} onClose={handleClose} className="scroe_module">

                                    <Box sx={{ width: 250 }} role="presentation">
                                        <div className='box-line-sm'>
                                            <Startuplistfilter path={path} startupName={startupValue.startupName} viewStartupDetails={viewStartupDetails} handleClose={handleClose} />
                                        </div>
                                    </Box>
                                </Drawer> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose1} />
        </div>
    )
}
export default TanseedAssociateddetails