import React, { useContext, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Box, Button, FormControl, FormControlLabel, Paper, MenuItem, FormHelperText, FormLabel, IconButton, InputAdornment, InputLabel, Modal, OutlinedInput, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Autocomplete, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import DataContext from '../../../context/DataContext';
import notifyService from '../../../api/notifySerivce';
import { MuiFileInput } from 'mui-file-input';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Fab from '@mui/material/Fab';
import { Link } from 'react-router-dom';
import PreviewIcon from '@mui/icons-material/Preview';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BorderBottom } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Viewimage from '../../../api/Viewimage';
import { useEffect } from 'react';
// import { toWords } from 'number-to-words';

const fileTypes = ["pdf"];
const fileTypesimg = ["jpg, png, jpeg"];



function numberToWordsIndian(num) {
    const units = [
        '', 'One', 'Two', 'Three', 'Four',
        'Five', 'Six', 'Seven', 'Eight', 'Nine',
        'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen',
        'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const tens = [
        '', '', 'Twenty', 'Thirty', 'Fourty',
        'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];

    function convertBelowThousand(n) {
        let word = '';
        if (n > 99) {
            word += units[Math.floor(n / 100)] + ' Hundred ';
            n %= 100;
        }
        if (n > 19) {
            word += tens[Math.floor(n / 10)] + ' ';
            word += units[n % 10];
        } else {
            word += units[n];
        }
        return word.trim();
    }

    function convertNumberToWords(n) {
        if (n === 0) return 'Zero';
        let words = '';
        let crore = Math.floor(n / 10000000);
        n %= 10000000;
        let lakh = Math.floor(n / 100000);
        n %= 100000;
        let thousand = Math.floor(n / 1000);
        n %= 1000;
        let hundred = Math.floor(n / 100);

        if (crore > 0) {
            words += convertBelowThousand(crore) + ' Crore ';
        }
        if (lakh > 0) {
            words += convertBelowThousand(lakh) + ' Lakh ';
        }
        if (thousand > 0) {
            words += convertBelowThousand(thousand) + ' Thousand ';
        }
        if (n > 0) {
            words += convertBelowThousand(n);
        }

        return words.trim();
    }

    return convertNumberToWords(num);
}




const Fundingscst = () => {
    const showAddInfo = localStorage.getItem("showInfo")
    debugger
    const [formData, setFormData] = useState({
        // fundHistory: [{}]
    });
    const { handleBackapp, navigator, VisuallyHiddenInput, modalstyle, setPageLoading } = useContext(DataContext)
    const { handleSubmit, control, setValue, clearErrors, watch, getValues, formState: { errors } } = useForm({ values: formData });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        fields: fundFields,
        append: fundAppend,
        remove: fundRemove
    } = useFieldArray({
        control,
        name: "fundHistory"
    });

    const {
        fields: utilizationFields,
        append: utilizationAppend,
        remove: utilizationRemove
    } = useFieldArray({
        control,
        name: "startupfundutilization"
    });

    const [fundingProgramDetails, setFundingProgramDetails] = useState([])
    // let preferredInvestorTypeList = []
    // let investmentTicketSize = []
    const [checkMethod, setCheckMethod] = useState('save')
    useDidMountEffect(() => {
        // eslint-disable-next-line                
        (async () => await getApi())()
        dropdownValueApi()
        // eslint-disable-next-line
    }, [])
    const [fundName, setFundName] = useState('')
    const [preferredInvestorTypeList, setPreferredInvestorTypeList] = useState([])
    const [investmentTicketSize, setInvestmentTicketSize] = useState([])
    const [instrumentTypeData, setInstrumentTypeData] = useState([])
    const [revenuesList, setLevenuesList] = useState([])
    const [startupinstrumentTypeList, setStartupinstrumentTypeList] = useState([])
    const [fundingProgramsList, setfundingProgramsList] = useState([])
    const [externalFund, setExternalFund] = useState([])
    const [utilizationDesc, setUtilizationDesc] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=startup`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            setPreferredInvestorTypeList(val.prefInvestorTypes)
            setInvestmentTicketSize(val.desiredFundingAmount)
            setInstrumentTypeData(val.prefFundingInstruments)
            setLevenuesList(val.revenueStages)
            setStartupinstrumentTypeList(val.previousFundHistoryInstruments)
            setfundingProgramsList(val.fundingPrograms)
            setExternalFund(val.externalFunding)
            setUtilizationDesc(val.utilizationOfFunds)
            debugger
        })
    }
    const getApi = async () => {
        setFormData({})
        setPageLoading(true)
        await apiService(`startup/fundingandfinancials/get`, '', 'get').then((res) => {
            if (res) {
                setPageLoading(false)
                let response = res.data
                if (response.id !== 0) {
                    setFormData(response)
                    if (response.editForm === false) {
                        setReadyOnlyValue(true)
                    }
                    // if (response.startupFundingPrograms) {
                    //     setFundingProgramDetails(response.startupFundingPrograms)
                    // }
                    // if (response.preferredInvestorType) {
                    //     setPreferredInvestorTypeValue(response.preferredInvestorType);
                    // }
                    if (response.pitchDeck) {
                        setIsUploaded(true)
                        setPitchDeckValue(response.pitchDeck)
                        setPitchDeckName(response.pitchDeck)
                        setPitchDeckURL(response.pitchDeckName)
                        setCheckMethod('update')
                    }
                    // if (response.investTicketSize) {
                    //     setInvestTicketSizeValue(response.investTicketSize)
                    // }
                    // if (response.investmentInstrumentType) {
                    //     setInvestmentInstrumentTypeValue(response.investmentInstrumentType)
                    // }

                } else {
                    setFormData(response)
                }
            }
        })
    }
    // const checkFundingValidation = () => {
    //     if (fundingProgramDetails.length !== 0) {
    //         setFundingProgramInvalid(false)
    //     } else {
    //         setFundingProgramInvalid(true)
    //     }
    // }
    const addFundDetailsBtn = () => {
        fundAppend({
            fundRaised: "",
            instrumentType: "",
            externalFunding: "",
            currencyValue: "",
        })
    }

    const addutilisationDetailsBtn = () => {
        utilizationAppend({
            expenses: "",
            amount: "",
            currencyValue: "",
        })
    }

    const [fundingProgramIndex, setFundingProgramIndex] = useState('')
    const addFundingProgramBtn = (index) => {
        setFundingProgramIndex(index)
        handleOpen()
    }
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // const fundingStageList = [
    //     "Bootstrapped",
    //     "Friends & Family",
    //     "Seed/Angle Funded",
    //     "Pre-Series",
    //     "Series A or Beyond",
    // ];
    // const [fundingStageValue, setFundingStageValue] = useState(undefined);
    // const handleSelectfundingStage = (value) => {
    //     // setFundingStageValue(value);
    //     setValue('fundingStage', value)
    //     clearErrors('fundingStage');
    // };

    const [preferredInvestorTypeValue, setPreferredInvestorTypeValue] = useState([]);
    const handleSelectPreferredInvestorType = (value) => {
        setPreferredInvestorTypeValue(value);
        setValue('preferredInvestorType', value)
        clearErrors('preferredInvestorType');
    };

    const handleSelectRevenues = (value) => {
        setValue('revenueStage', value)
        clearErrors('revenueStage');
    };

    // const [investTicketSizeValue, setInvestTicketSizeValue] = useState('');
    const handleSelectTicketSize = (value) => {
        // setInvestTicketSizeValue(value);
        setValue('investTicketSize', value)
        clearErrors('investTicketSize');
    };

    const [investmentInstrumentTypeValue, setInvestmentInstrumentTypeValue] = useState([]);
    const handleSelectInvestment = (value) => {
        setInvestmentInstrumentTypeValue(value);
        setValue('investmentInstrumentType', value)
        clearErrors('investmentInstrumentType');
    };

    // const [fundingProgramInvalid, setFundingProgramInvalid] = useState(false);
    // delete funding
    const deleteFundingProgram = (id, indexToDelete) => {
        if (id !== '0') {
            apiService(`startup/fundingprograms/remove?id=${id}`, '', 'get').then((res) => {
                if (res && res.data.responseStatus === 'Success') {
                    const updatedFundingPrograms = fundingProgramDetails.filter((_, index) => index !== indexToDelete);
                    setFundingProgramDetails(updatedFundingPrograms);
                    getApi();
                }
            });
        } else {
            const updatedFundingPrograms = fundingProgramDetails.filter((_, index) => index !== indexToDelete);
            setFundingProgramDetails(updatedFundingPrograms);
        }
    }


    const deleteUtilizationHistory = async (index) => {
        const id = getValues(`startupfundutilization.${index}.id`);
        if (id !== 0 && id !== undefined) {
            apiService(`startup/fundutilization/remove?id=${id}`, '', 'delete').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        utilizationRemove(index)
                        getApi()
                    }
                }
            })
        } else {
            utilizationRemove(index)
        }
        utilizationRemove(index)
    }

    // delete Tractions
    const deleteFundingHistory = async (index) => {
        const id = getValues(`fundHistory.${index}.id`);
        if (id !== 0 && id !== undefined) {
            apiService(`startup/fundhistory/remove?id=${id}`, '', 'get').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        fundRemove(index)
                        getApi()
                    }
                }
            })
        } else {
            fundRemove(index)
        }
    }

    const [pitchDeckValue, setPitchDeckValue] = useState(null)
    const [pitchDeckName, setPitchDeckName] = useState(null)
    const [pitchDeckURL, setPitchDeckURL] = useState(null)
    const [logoDisplayUrl, setLogoDisplayUrl] = useState('./images/user.png')
    const [logoDisplayValue, setLogoDisplayValue] = useState(null)
    const [logoDisplayName, setLogoDisplayName] = useState(null)
    // const [checkMethod, setCheckMethod] = useState('save')
    const [logoInvalid, setLogoInvalid] = useState(false);
    const inputRef = useRef(null);
    let logoselecetdFile = ''

    const handleFileUpload = (event, filekey, fileName, setdata, setName, setUrl, format) => {
        debugger
        let logoselecetdFile;
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            if (logoselecetdFile) {
                const fileSizeLimit = 5 * 1024 * 1024;
                if (logoselecetdFile.size > fileSizeLimit) {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        "Please upload a file smaller than 5 MB."
                    );
                    return;
                }
                var reader = new FileReader();
                var imagetype = logoselecetdFile.type
                var imagedatatype = imagetype.split("/")
                var img_crt_type = imagedatatype[1]
                if (img_crt_type === "pdf") {
                    setValue(filekey, "")
                    setValue(fileName, "")
                    setName('')
                    setUrl('')
                    if (event.target === undefined) {
                        setdata(event)
                    } else {
                        setdata(event.target.files[0])
                    }
                    var fileValue = logoselecetdFile
                    reader.readAsDataURL(logoselecetdFile);
                    reader.onload = () => {
                        var logourl1 = reader.result;
                        var spl = logourl1.split(',')
                        var ImageValue = spl[1]
                        var img_name = fileValue.name
                        setUrl(logourl1)
                        setValue(filekey, ImageValue)
                        setValue(fileName, img_name)
                        setName(img_name)
                        clearErrors(filekey);
                        clearErrors(fileName);
                        if (filekey === "startupLogo") {
                            setLogoInvalid(false)
                        }
                    }
                } else {
                    notifyService('danger', 'File Format Invalided', 'Please check your file format')
                }
            }
        } else {

            // setValue(filekey, "")
            // setValue(fileName, "")
            // setName('')
            // setUrl('')
            // inputRef.current.value = '';
        }

    }

    const onSubmit = (data) => {
        // checkFundingValidation()
        if (readyOnlyValue === false) {
            setPageLoading(true);
            var getform = data
            debugger
            apiService('startup/fundingandfinancials/save', getform, 'post').then((res) => {
                setPageLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        navigator('/startup/founder/SCST')
                    }
                }
            })
        }
    };
    const onPdfClick = () => {
        const pdfUrl = "./SCST-Pitch-Deck-Sample-Template.pptx";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "SCST_Pitch_Deck_Sample_Template.pptx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const isWhitespace = (value) => {
        return /^\s*$/.test(value);
    };

    const isSpecialCharacter = (value) => {
        const regex = /[!@#$%^&*(),.?":{}|<>]/;
        return regex.test(value);
    };

    const handleFileClear = () => {
        setPitchDeckValue('');
        setPitchDeckName('');
        setPitchDeckURL('');
    };

    const [showExternalFunding, setShowExternalFunding] = useState(false);

    const fundtype = [
        "Internal Funding",
        "External Funding",
    ]

    const handleSelectRole = (selectedOptions, value, index) => {
        setValue(`instrumentType`, value);
        clearErrors(`instrumentType`);

        // const selectedOption = selectedOptions[0];
        setShowExternalFunding(selectedOptions === "External Funding");
    }

    const handleSelectCurrency = (selectedOption, value, index) => {
        setValue('currencyValue', selectedOption);
        clearErrors('currencyValue');
    }

    const handleSelectCurrencyFund = (selectedOptions, value, index) => {
        setValue(`fundHistory.${index}.currencyValue`, value);
        clearErrors(`fundHistory.${index}.currencyValue`);
    }

    const handleSelectCurrencyUtilization = (selectedOptions, value, index) => {
        setValue(`currencyValue`, value);
        clearErrors(`currencyValue`);
    }

    const handleSelectExternalFunding = (value, index) => {
        setValue(`externalFunding`, value);
        clearErrors(`externalFunding`);
    }

    const handleSelectUtilization = (value, index) => {
        setValue(`utilizationDesc`, value);
        clearErrors(`utilizationDesc`);
    }

    const handleFundFiter = (value) => {
        setFundName(value)
    }

    const [isUploaded, setIsUploaded] = useState(false);
    const handleFileChange = (e) => {
        handleFileUpload(e, "pitchDeck", "pitchDeckName", setPitchDeckValue, setPitchDeckName, setPitchDeckURL, 'file');
        setIsUploaded(true);
    };

    const currencyValue = [
        'Thousand',
        'Lakhs',
        'Crore'
    ]

    const [open1, setOpen1] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const handleClickOpen = (value) => {
        setViewImage(value);
        setOpen(true);
    };
    const handleClose1 = () => {
        setOpen(false);
    };

    const [revenueText, setRevenueText] = useState('');
    const [revenueText1, setRevenueText1] = useState('');
    const [revenueText2, setRevenueText2] = useState('');

    const watchedRevenue = watch('annualRevenue', '');
    useEffect(() => {
        const numberValue = parseFloat(watchedRevenue.replace(/,/g, ''));
        if (!isNaN(numberValue)) {
            const words = numberToWordsIndian(numberValue);
            setRevenueText(`${words.charAt(0).toUpperCase() + words.slice(1)} Rupees`);
        } else {
            setRevenueText('');
        }
    }, [watchedRevenue]);

    const watchedRevenue1 = watch('revenueTurnover', '');
    useEffect(() => {
        const numberValue = parseFloat(watchedRevenue1.replace(/,/g, ''));
        if (!isNaN(numberValue)) {
            const words = numberToWordsIndian(numberValue);
            setRevenueText1(`${words.charAt(0).toUpperCase() + words.slice(1)} Rupees`);
        } else {
            setRevenueText1('');
        }
    }, [watchedRevenue1]);

    const watchedRevenue2 = watch('expectedFunding', '');
    useEffect(() => {
        const numberValue = parseFloat(watchedRevenue2.replace(/,/g, ''));
        if (!isNaN(numberValue)) {
            const words = numberToWordsIndian(numberValue);
            setRevenueText2(`${words.charAt(0).toUpperCase() + words.slice(1)} Rupees`);
        } else {
            setRevenueText2('');
        }
    }, [watchedRevenue2]);


    const handleInputChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            e.target.value = value;
        } else {
            e.target.value = value.replace(/[^\d]/g, '');
        }
    };


    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className='d-flex align-items-center'>
                        <span className='back_span' onClick={handleBackapp}> <img src='/images/Fig-back.png' className='img-fluid' alt='' /> </span> <h6 className='ml-2 mb-0'>Funding & Financials</h6>
                    </div>
                    <hr />
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='d-none'>
                                <div className="form-group">
                                    <Controller name="id" control={control} defaultValue="0"
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>
                            {/* <div className='col-lg-6 col-12'>
                                <div className="form-group mt-2">
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">Product/Service Uniqueness</FormLabel>

                                        <Controller name="uniqueness" control={control} defaultValue="" rules={{ required: 'Revenue Generated  is required' }}
                                            render={({ field }) =>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="Innovation"
                                                    name="radio-buttons-group"
                                                    inputProps={
                                                        { readOnly: readyOnlyValue, }
                                                    }
                                                    {...field}
                                                >
                                                    <span className='d-flex'>
                                                        <FormControlLabel value="Innovation" control={<Radio />} label="Innovation" />
                                                        <FormControlLabel value="Invention" control={<Radio />} label="Invention" />
                                                        <FormControlLabel value="Upgradation" control={<Radio />} label="Upgradation" />
                                                    </span>
                                                </RadioGroup>} />
                                        <FormHelperText className='text-danger'>{errors.uniqueness && errors.uniqueness.message}</FormHelperText>
                                    </FormControl>
                                </div>
                            </div> */}
                            {/* <div className='col-lg-6 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="fundProjection" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label='Funding Projection'
                                                placeholder='Enter Funding Projection'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.fundProjection && errors.fundProjection.message}</FormHelperText>
                                </div>
                            </div> */}
                            <div className='col-lg-6 col-12 mt-2'>
                                <div className="form-group mt-2">
                                    <Controller name="annualRevenue" control={control} defaultValue="" rules={{
                                        required: 'Revenue Generated is required', validate: {
                                            noWhitespace: value => !isWhitespace(value) || 'Please enter a valid revenue'
                                        },
                                        pattern: {
                                            // value: /^[a-zA-Z0-9\s]*$/,
                                            value: /^[0-9]*$/,
                                            message: 'Only numbers are allowed'
                                        }
                                    }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label={<><span>Revenue Generated (Last Year)</span> <span>&#8377; *</span></>}
                                                placeholder='Enter Revenue Generated '
                                                fullWidth
                                                type='text'
                                                // inputProps={
                                                //     { readOnly: readyOnlyValue, }
                                                // }
                                                {...field}
                                                error={!!errors.annualRevenue}
                                                helperText={errors.annualRevenue ? errors.annualRevenue.message : ''}
                                                inputProps={{
                                                    readOnly: readyOnlyValue,
                                                    onInput: handleInputChange,
                                                    maxLength: 10
                                                }}
                                            />} />
                                    <div className="revenue-display">
                                        {revenueText && (
                                            <p className='text-right'>
                                                {revenueText}
                                            </p>
                                        )}
                                    </div>
                                    {/* <FormHelperText className='text-danger'>{errors.annualRevenue && errors.annualRevenue.message}</FormHelperText> */}
                                </div>
                            </div>
                            {/* <div className="col-lg-4 mt-3">
                                <div className="form-group">
                                    <FormControl fullWidth>
                                        <Controller name="currencyValue" control={control} defaultValue="" rules={{ required: 'Fund Value is required' }}
                                            render={({ field }) =>
                                                <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    {...field}
                                                    options={currencyValue}
                                                    value={field.value}
                                                    onChange={(e, selectedOption) => {
                                                        field.onChange(selectedOption);
                                                        handleSelectCurrency(selectedOption)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Fund Value *" />
                                                    )}
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className='text-danger'>{errors.currencyValue && errors.currencyValue.message}</FormHelperText>
                                    </FormControl>
                                </div>
                            </div> */}
                            {
                                showAddInfo === "true" && (
                                    <>
                                        <div className='col-lg-6 col-12 mt-2'>
                                            <div className="form-group mt-2">
                                                <FormControl fullWidth>
                                                    <Controller name="revenueTurnover" control={control} defaultValue="" rules={{ required: 'Total Funds Raised till Date is required', validate: value => !isWhitespace(value) || 'Please enter a valid revenue' }}
                                                        render={({ field }) =>
                                                            <TextField
                                                                variant='outlined'
                                                                label="Revenue/Turnover for all completed financial years *"
                                                                placeholder='Enter Revenue/Turnover'
                                                                fullWidth
                                                                {...field}
                                                                type='text'
                                                                inputProps={
                                                                    { readOnly: readyOnlyValue, onInput: handleInputChange, maxLength: 10 }
                                                                }
                                                            />
                                                        } />
                                                    <div className="revenue-display">
                                                        {revenueText1 && (
                                                            <p className='text-right'>
                                                                {revenueText1}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <FormHelperText className='text-danger'>{errors.revenueTurnover && errors.revenueTurnover.message}</FormHelperText>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            <div className='col-lg-6 col-12 ' >
                                <div className="form-group ">
                                    <Controller name="expectedFunding" control={control} defaultValue="" rules={{
                                        required: 'Funding Expected  is required',
                                        validate: {
                                            // noSpecialChars: value => !isSpecialCharacter(value) || 'Special characters are not allowed',
                                            noWhitespace: value => !isWhitespace(value) || 'Please enter a valid revenue'
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9\s]*$/,
                                            message: 'Only alphabets and numbers are allowed'
                                        }
                                    }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label={<><span>Funding Expected</span> <span>&#8377; *</span></>}
                                                placeholder='Enter Funding Expected '
                                                sx={{ marginTop: "15px" }}
                                                fullWidth
                                                type='text'
                                                inputProps={
                                                    { readOnly: readyOnlyValue, onInput: handleInputChange, maxLength: 10 }
                                                }
                                                error={!!errors.expectedFunding}
                                                helperText={errors.expectedFunding ? errors.expectedFunding.message : ''}
                                                {...field}
                                            />} />
                                    <div className="revenue-display">
                                        {revenueText2 && (
                                            <p className='text-right'>
                                                {revenueText2}
                                            </p>
                                        )}
                                    </div>
                                    {/* <FormHelperText className='text-danger'>{errors.expectedFunding && errors.expectedFunding.message}</FormHelperText> */}
                                </div>
                            </div>

                            <div className='col-lg-6 col-12 mt-2'>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="pitchDeck" control={control} defaultValue="" rules={{ required: 'pitchDeck is required' }}
                                            render={({ field }) =>
                                                // <FileUploader children={
                                                //     <div className="fileupload-pdf-dpiit-bg" >
                                                //         <center>
                                                //             <p className="fileupload-pdf">
                                                //                 <span> <AttachFileIcon className="file-icon mr-2" /> Upload Pitch Deck </span>
                                                //             </p>
                                                //         </center>
                                                //     </div>}
                                                //     {...field} handleChange={(e) =>
                                                //         handleFileUpload(e, "pitchDeck", "pitchDeckName", setPitchDeckValue, setPitchDeckName, setPitchDeckURL, 'file')}
                                                //     value={pitchDeckValue}
                                                //     name="file" types={fileTypes} />
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleFileChange}
                                                    value={pitchDeckValue}
                                                    name="file"
                                                    types={fileTypes}
                                                    children={
                                                        <div className={isUploaded ? 'fileupload-pdf-dpiit-bg-active' : 'fileupload-pdf-dpiit-bg'}>
                                                            <center>
                                                                <p className={isUploaded ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {isUploaded ? 'Uploaded (Click to Change)' : 'Pitch Deck'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                        {/* <FormHelperText>Maximum file size: 5 MB</FormHelperText> */}
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.pitchDeck && errors.pitchDeck.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                                        // to={`${pitchDeckURL}`}
                                        // target="_blank"
                                        onClick={() => handleClickOpen(pitchDeckURL)}
                                        style={{ color: "#80809e" }}
                                    > Preview
                                    </Link>
                                    </span>
                                    {/* <span className="pre-btn-dpiit"> <DeleteIcon className='icon-size' /></span> */}
                                </div>
                                <div>
                                    <span className='c-blue c-pointer ml-2' onClick={onPdfClick} >Sample Pitch Deck Template <PictureAsPdfIcon className="c-red" /></span>
                                </div>
                                {/* {
                                    (checkMethod === 'save') ? (
                                        <div className="form-group mt-2">
                                            <Controller name="pitchDeck" control={control} defaultValue="" rules={{ required: 'Pitch Deck is required' }}
                                                render={({ field }) =>
                                                    <MuiFileInput
                                                        {...field}
                                                        onChange={(e) => handleFileUpload(e, "pitchDeck", "pitchDeckName", setPitchDeckValue, setPitchDeckName, setPitchDeckURL, 'file')}
                                                        value={pitchDeckValue}
                                                        variant='outlined'
                                                        // inputProps={}
                                                        // onReset={(e) => handleremove(e)}
                                                        // inputRef={inputRef}
                                                        // inputProps= {
                                                        //   ref=
                                                        // }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {pitchDeckValue && (
                                                                        <IconButton onClick={handleFileClear}>
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        fullWidth
                                                        label="Pitch Deck *"
                                                        placeholder='Upload your Pitch Deck' />
                                                } />
                                            <FormHelperText className='text-danger'>{errors.pitchDeck && errors.pitchDeck.message}</FormHelperText>
                                            <div>
                                                <span className='fn-12'>Maximum 5 mb allowed doc (Accepted Formet PDF) <span className='text-danger'>*</span></span>
                                            </div>
                                            <div>
                                                <span className='c-blue c-pointer' onClick={onPdfClick} >Sample Pitch Deck Template <PictureAsPdfIcon className="c-red" /></span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <p className=''>Pitch Deck <span className='c-blue c-pointer' onClick={onPdfClick} >Sample Pitch Deck Template <PictureAsPdfIcon className="c-red" /></span></p>
                                            <div className='row'>
                                                <div className='col-lg-7 col-12'>
                                                    <Controller name="pitchDeck" control={control} defaultValue="" rules={{ required: 'pitchDeck File is required' }}
                                                        render={({ field }) =>
                                                            <Button
                                                                component="label"
                                                                variant="contained"
                                                                {...field}
                                                                fullWidth
                                                                onChange={(e) => handleFileUpload(e, "pitchDeck", "pitchDeckName", setPitchDeckValue, setPitchDeckName, setPitchDeckURL, 'file')}
                                                                startIcon={<CloudUploadIcon />}
                                                                href="#file-upload"
                                                                disabled={readyOnlyValue}
                                                            >
                                                                Change
                                                                {
                                                                    (readyOnlyValue === false) ? (
                                                                        <VisuallyHiddenInput type="file" />
                                                                    ) : (<></>)
                                                                }

                                                            </Button>

                                                        } />
                                                    <div>
                                                        <span className='fn-12 d-flex  mt-1 '>Maximum 5 mb allowed doc (Accepted Formet PDF) <span className='text-danger'>*</span></span>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5 col-12'>
                                                    <Fab variant="extended">
                                                        <Link to={`${pitchDeckURL}`} target="_blank" color='black'>
                                                            <PreviewIcon sx={{ mr: 1 }} />
                                                            View Document
                                                        </Link>
                                                    </Fab>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } */}
                            </div>
                            {/* <div className='col-lg-6 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="utilizationDesc" control={control} defaultValue="" rules={{ required: 'Total Funds Raised till Date is required', validate: value => !isWhitespace(value) || 'Please enter a valid revenue' }}
                                        render={({ field }) =>

                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                options={utilizationDesc}
                                                onChange={(e, selectedOptions) => {
                                                    field.onChange(selectedOptions);
                                                    handleSelectUtilization(selectedOptions)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="External Funding*" />
                                                )}
                                                disabled={readyOnlyValue}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.utilizationDesc && errors.utilizationDesc.message}</FormHelperText>
                                </div>
                            </div> */}
                            {
                                showAddInfo === "true" && (
                                    <>
                                        <Accordion className='Addfundacc-bor'>
                                            <AccordionSummary
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                className='Addfundacc'
                                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            >
                                                <Typography sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    margin: 0
                                                }}>
                                                    <AddCircleIcon className='add-icon mr-3' /> Previous Fundraising History
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='col-12 mb-3'>
                                                        <div>
                                                            {
                                                                (fundFields.length >= 0) && (
                                                                    <TableContainer component={Paper} className='table-back'>
                                                                        <Table aria-label="caption table">
                                                                            <TableBody >
                                                                                {fundFields.map((field, index, type) => (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                                                                        <TableCell sx={{ width: '25%', borderBottom: 'none', padding: '5px' }}>
                                                                                            <FormControl sx={{ width: '100%' }} >
                                                                                                <Controller name={`fundHistory.${index}.fundRaised`} control={control} defaultValue="" rules={{ required: 'Fund Raised is required' }}
                                                                                                    render={({ field }) =>
                                                                                                        <TextField
                                                                                                            id="outlined-multiline-static"
                                                                                                            label="Fund Raised (₹)*"
                                                                                                            placeholder='Enter Fund Raised (₹)'
                                                                                                            fullwidth
                                                                                                            {...field}
                                                                                                            inputProps={
                                                                                                                { readOnly: readyOnlyValue, }
                                                                                                            }
                                                                                                        />} />
                                                                                                <FormHelperText className='text-danger'>
                                                                                                    {errors.fundHistory?.[index]?.fundRaised && errors.fundHistory?.[index]?.fundRaised.message}
                                                                                                </FormHelperText>
                                                                                            </FormControl>
                                                                                        </TableCell>
                                                                                        {/* <TableCell sx={{ width: '25%', borderBottom: 'none', padding: '5px' }}>
                                                                                            <FormControl sx={{ width: '100%' }} >
                                                                                                <div className="form-group mt-3">
                                                                                                    <FormControl fullWidth>
                                                                                                        <Controller name={`fundHistory.${index}.currencyValue`} control={control} defaultValue="" rules={{ required: 'Fund Value is required' }}
                                                                                                            render={({ field }) =>
                                                                                                                <Autocomplete
                                                                                                                    disableClearable
                                                                                                                    disablePortal
                                                                                                                    {...field}
                                                                                                                    options={currencyValue}
                                                                                                                    onChange={(e, selectedOptions) => {
                                                                                                                        field.onChange(selectedOptions);
                                                                                                                        handleSelectCurrencyFund(selectedOptions)
                                                                                                                    }}
                                                                                                                    renderInput={(params) => (
                                                                                                                        <TextField {...params} label="Fund Value *" />
                                                                                                                    )}
                                                                                                                    disabled={readyOnlyValue}
                                                                                                                />
                                                                                                            } />
                                                                                                        <FormHelperText className='text-danger'>
                                                                                                            {errors.fundHistory?.[index]?.currencyValue && errors.fundHistory?.[index]?.currencyValue.message}
                                                                                                        </FormHelperText>
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </FormControl>
                                                                                        </TableCell> */}
                                                                                        <TableCell sx={{ width: '25%', borderBottom: 'none', padding: '5px' }}>
                                                                                            <FormControl sx={{ width: '100%' }} >
                                                                                                <Controller name={`fundHistory.${index}.instrumentType`} control={control} defaultValue="" rules={{ required: 'Instrument Type is required' }}
                                                                                                    render={({ field }) =>
                                                                                                        <Autocomplete
                                                                                                            disableClearable
                                                                                                            disablePortal
                                                                                                            {...field}
                                                                                                            options={fundtype}
                                                                                                            onChange={(e, selectedOptions) => {
                                                                                                                field.onChange(selectedOptions);
                                                                                                                handleSelectRole(selectedOptions)
                                                                                                            }}
                                                                                                            renderInput={(params) => (
                                                                                                                <TextField {...params} label="Fund Type *" />
                                                                                                            )}
                                                                                                            disabled={readyOnlyValue}
                                                                                                        />
                                                                                                    } />
                                                                                                <FormHelperText className='text-danger'>
                                                                                                    {errors.fundHistory?.[index]?.instrumentType && errors.fundHistory?.[index]?.instrumentType.message}
                                                                                                </FormHelperText>
                                                                                            </FormControl>
                                                                                        </TableCell>
                                                                                        <TableCell sx={{ width: '25%', borderBottom: 'none', padding: '5px' }}>
                                                                                            {
                                                                                                getValues(`fundHistory.${index}.instrumentType`) === "External Funding" && (
                                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                                        <Controller name={`fundHistory.${index}.externalFunding`} control={control} defaultValue="" rules={{ required: 'External Funding is required' }}
                                                                                                            render={({ field }) =>
                                                                                                                <Autocomplete
                                                                                                                    disableClearable
                                                                                                                    disablePortal
                                                                                                                    {...field}
                                                                                                                    options={externalFund}
                                                                                                                    onChange={(e, selectedOptions) => {
                                                                                                                        field.onChange(selectedOptions);
                                                                                                                        handleSelectExternalFunding(selectedOptions)
                                                                                                                    }}
                                                                                                                    renderInput={(params) => (
                                                                                                                        <TextField {...params} label="External Funding*" />
                                                                                                                    )}
                                                                                                                    disabled={readyOnlyValue}
                                                                                                                />
                                                                                                            } />
                                                                                                        <FormHelperText className='text-danger'>
                                                                                                            {errors.fundHistory?.[index]?.externalFunding && errors.fundHistory?.[index]?.externalFunding.message}
                                                                                                        </FormHelperText>
                                                                                                    </FormControl>
                                                                                                )
                                                                                            }
                                                                                            {
                                                                                                getValues(`fundHistory.${index}.instrumentType`) === "Internal Funding" && (
                                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                                        <Controller name={`fundHistory.${index}.remarks`} control={control} defaultValue="" rules={{ required: 'Remarks is required' }}
                                                                                                            render={({ field }) =>
                                                                                                                <TextField
                                                                                                                    id="outlined-multiline-static"
                                                                                                                    label="Remarks*"
                                                                                                                    placeholder='Enter Remarks'
                                                                                                                    fullwidth
                                                                                                                    {...field}
                                                                                                                    inputProps={
                                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                                    }
                                                                                                                />
                                                                                                            } />
                                                                                                        <FormHelperText className='text-danger'>
                                                                                                            {errors.fundHistory?.[index]?.remarks && errors.fundHistory?.[index]?.remarks.message}
                                                                                                        </FormHelperText>
                                                                                                    </FormControl>
                                                                                                )
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                                                                            <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteFundingHistory(index) : <></> }} />
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                        <center>
                                                                            <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addFundDetailsBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                        </center>
                                                                    </TableContainer>
                                                                    //     ) : (
                                                                    // <div className='nodata_tag'>
                                                                    //     <p>Fundraising history details is empty</p>
                                                                    // </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion className='Addfundacc-bor' >
                                            <AccordionSummary
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                className='Addfundacc '
                                            >
                                                <Typography >
                                                    <p className='m-0'> <AddCircleIcon className='add-icon mr-3' /> Utilization of Funds</p>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='col-12 my-3'>
                                                        {
                                                            (utilizationFields.length >= 0) && (
                                                                <TableContainer component={Paper} className='table-back'>
                                                                    <Table aria-label="caption table">
                                                                        <TableBody >
                                                                            {utilizationFields.map((field, index, type) => (
                                                                                <TableRow key={index}>
                                                                                    <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center' >{index + 1}</TableCell>
                                                                                    <TableCell sx={{ width: '35%', borderBottom: 'none', padding: '5px' }}>
                                                                                        <FormControl sx={{ width: '100%' }} >
                                                                                            <Controller name={`startupfundutilization.${index}.expenses`} control={control} defaultValue="" rules={{ required: 'Utilization of Funds is required' }}
                                                                                                render={({ field }) =>
                                                                                                    <Autocomplete
                                                                                                        disableClearable
                                                                                                        disablePortal
                                                                                                        {...field}
                                                                                                        options={utilizationDesc}
                                                                                                        onChange={(e, selectedOptions) => {
                                                                                                            field.onChange(selectedOptions);
                                                                                                            handleSelectUtilization(selectedOptions)
                                                                                                        }}
                                                                                                        renderInput={(params) => (
                                                                                                            <TextField {...params} label="Utilization of Funds *" />
                                                                                                        )}
                                                                                                        disabled={readyOnlyValue}
                                                                                                    />} />
                                                                                            <FormHelperText className='text-danger'>
                                                                                                {errors.startupfundutilization?.[index]?.expenses && errors.startupfundutilization?.[index]?.expenses.message}
                                                                                            </FormHelperText>
                                                                                        </FormControl>
                                                                                    </TableCell>

                                                                                    <TableCell sx={{ width: '35%', borderBottom: 'none', padding: '5px' }}>
                                                                                        <FormControl sx={{ width: '100%' }} >
                                                                                            <Controller name={`startupfundutilization.${index}.amount`} control={control} defaultValue="" rules={{ required: 'Amount is required' }}
                                                                                                render={({ field }) =>
                                                                                                    <TextField
                                                                                                        id="outlined-multiline-static"
                                                                                                        label="Amount*"
                                                                                                        placeholder='Enter Amount'
                                                                                                        fullwidth
                                                                                                        {...field}
                                                                                                        inputProps={
                                                                                                            { readOnly: readyOnlyValue, }
                                                                                                        }
                                                                                                    />
                                                                                                } />
                                                                                            <FormHelperText className='text-danger'>
                                                                                                {errors.startupfundutilization?.[index]?.amount && errors.startupfundutilization?.[index]?.amount.message}
                                                                                            </FormHelperText>
                                                                                        </FormControl>
                                                                                    </TableCell>

                                                                                    {/* <TableCell sx={{ width: '35%', borderBottom: 'none', padding: '5px' }}>
                                                                                        <FormControl sx={{ width: '100%' }} >
                                                                                            <div className="form-group mt-3">
                                                                                                <FormControl fullWidth>
                                                                                                    <Controller name={`startupfundutilization.${index}.currencyValue`} control={control} defaultValue="" rules={{ required: 'Fund Value is required' }}
                                                                                                        render={({ field }) =>
                                                                                                            <Autocomplete
                                                                                                                disableClearable
                                                                                                                disablePortal
                                                                                                                {...field}
                                                                                                                options={currencyValue}
                                                                                                                onChange={(e, selectedOptions) => {
                                                                                                                    field.onChange(selectedOptions);
                                                                                                                    handleSelectCurrencyUtilization(selectedOptions)
                                                                                                                }}
                                                                                                                renderInput={(params) => (
                                                                                                                    <TextField {...params} label="Fund Value *" />
                                                                                                                )}
                                                                                                                disabled={readyOnlyValue}
                                                                                                            />
                                                                                                        } />
                                                                                                    <FormHelperText className='text-danger'>
                                                                                                        {errors.startupfundutilization?.[index]?.currencyValue && errors.startupfundutilization?.[index]?.currencyValue.message}
                                                                                                    </FormHelperText>
                                                                                                </FormControl>
                                                                                            </div>
                                                                                        </FormControl>
                                                                                    </TableCell> */}

                                                                                    <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                                                                        <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteUtilizationHistory(index) : <></> }} />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                    <center>
                                                                        <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addutilisationDetailsBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                    </center>
                                                                </TableContainer>
                                                            )
                                                        }
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        {/* <div className='Addfundacc-bor'>
                                            <Container className='p-0'>
                                                <div className='Addfundacc'>
                                                    <center><p className='m-0'> <AddCircleIcon className='add-icon' /> ADD FUNDRAISING HISTORY</p></center>
                                                </div>
                                            </Container>
                                        </div> */}

                                        {/* <div className='Addfundacc-bor'>
                                            <Container className='p-0'>
                                                <div className='Addfundacc'>
                                                    <center><p className='m-0'> <AddCircleIcon className='add-icon mr-4' /> ADD FUNDING PROGRAM</p></center>
                                                </div>
                                            </Container>
                                        </div> */}

                                        {/* <div className='col-12 my-3'>
                                            <div className='div_header'>
                                                <div className='d-flex'>
                                                    <h6>Utilization of Funds</h6>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    (utilizationFields.length > 0) ? (
                                                        <TableContainer component={Paper} className='table-back'>
                                                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                                <TableBody >
                                                                    {utilizationFields.map((field, index, type) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell sx={{ borderBottom: 'none' }} align='center' >{index + 1}</TableCell>
                                                                            <TableCell sx={{ width: '35%', borderBottom: 'none' }}>
                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                    <Controller name={`startupfundutilization.${index}.expenses`} control={control} defaultValue="" rules={{ required: 'Utilization of Funds is required' }}
                                                                                        render={({ field }) =>
                                                                                            <Autocomplete
                                                                                                disableClearable
                                                                                                disablePortal
                                                                                                {...field}
                                                                                                options={utilizationDesc}
                                                                                                onChange={(e, selectedOptions) => {
                                                                                                    field.onChange(selectedOptions);
                                                                                                    handleSelectUtilization(selectedOptions)
                                                                                                }}
                                                                                                renderInput={(params) => (
                                                                                                    <TextField {...params} label="Utilization of Funds *" />
                                                                                                )}
                                                                                                disabled={readyOnlyValue}
                                                                                            />} />
                                                                                    <FormHelperText className='text-danger'>
                                                                                        {errors.startupfundutilization?.[index]?.expenses && errors.startupfundutilization?.[index]?.expenses.message}
                                                                                    </FormHelperText>
                                                                                </FormControl>
                                                                            </TableCell>


                                                                            {
                                                                                getValues(`startupfundutilization.${index}.expenses`) === "Others" && (
                                                                                    <TableCell sx={{ width: '25%' }} >
                                                                                        <FormControl sx={{ width: '100%' }} >
                                                                                            <Controller name={`startupfundutilization.${index}.expenses`} control={control} defaultValue="" rules={{ required: 'Expenses is required' }}
                                                                                                render={({ field }) =>
                                                                                                    <TextField
                                                                                                        id="outlined-multiline-static"
                                                                                                        label="Expenses Name *"
                                                                                                        placeholder='Enter expenses name '
                                                                                                        fullwidth
                                                                                                        {...field}
                                                                                                        inputProps={
                                                                                                            { readOnly: readyOnlyValue, }
                                                                                                        }
                                                                                                    />
                                                                                                } />
                                                                                            <FormHelperText className='text-danger'>
                                                                                                {errors.startupfundutilization?.[index]?.expenses && errors.fundHistory?.[index]?.expenses.message}
                                                                                            </FormHelperText>
                                                                                        </FormControl>
                                                                                    </TableCell>
                                                                                )
                                                                            }


                                                                            <TableCell sx={{ width: '35%', borderBottom: 'none' }}>
                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                    <Controller name={`startupfundutilization.${index}.amount`} control={control} defaultValue="" rules={{ required: 'Amount is required' }}
                                                                                        render={({ field }) =>
                                                                                            <TextField
                                                                                                id="outlined-multiline-static"
                                                                                                label="Amount*"
                                                                                                placeholder='Enter Amount'
                                                                                                fullwidth
                                                                                                {...field}
                                                                                                inputProps={
                                                                                                    { readOnly: readyOnlyValue, }
                                                                                                }
                                                                                            />
                                                                                        } />
                                                                                    <FormHelperText className='text-danger'>
                                                                                        {errors.startupfundutilization?.[index]?.amount && errors.startupfundutilization?.[index]?.amount.message}
                                                                                    </FormHelperText>
                                                                                </FormControl>
                                                                            </TableCell>

                                                                            <TableCell sx={{ width: '35%', borderBottom: 'none' }}>
                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                    <div className="form-group mt-3">
                                                                                        <FormControl fullWidth>
                                                                                            <InputLabel id="demo-simple-select-filled-label">Fund Value</InputLabel>
                                                                                            <Controller name={`startupfundutilization.${index}.currencyValue`} control={control} defaultValue="" rules={{ required: 'Fund Value is required' }}
                                                                                                render={({ field }) =>
                                                                                                    <Select
                                                                                                        onChange={(e) => handleFundFiter(e.target.value)}
                                                                                                        value={fundName}
                                                                                                    >
                                                                                                        <MenuItem value={0}>Thousand</MenuItem>
                                                                                                        <MenuItem value={1}>Lakh</MenuItem>
                                                                                                        <MenuItem value={2}>Crore</MenuItem>
                                                                                                    </Select>
                                                                                                } />
                                                                                            <FormHelperText className='text-danger'>
                                                                                                {errors.startupfundutilization?.[index]?.currencyValue && errors.startupfundutilization?.[index]?.currencyValue.message}
                                                                                            </FormHelperText>
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </FormControl>
                                                                            </TableCell>

                                                                            <TableCell sx={{ borderBottom: 'none' }} align='center'>
                                                                                <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteUtilizationHistory(index) : <></> }} />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                            <center>
                                                                <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addutilisationDetailsBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                            </center>

                                                        </TableContainer>
                                                    ) : (
                                                        <div className='nodata_tag'>
                                                            <p>Utilization Fund details is empty</p>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                        </div> */}

                                        {/* <div className='col-12 mb-3'>
                                            <div className='div_header'>
                                                <div className='d-flex'>
                                                    <h6>Previous Fundraising History</h6>
                                                    <Button variant="outlined" disabled={readyOnlyValue} className='ml-auto' onClick={() => addFundDetailsBtn('')}>Add Fundraising History</Button>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    (fundFields.length > 0) ? (
                                                        <TableContainer component={Paper} className='table-back'>
                                                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                                <TableBody >
                                                                    {fundFields.map((field, index, type) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell sx={{ borderBottom: 'none' }}>{index + 1}</TableCell>
                                                                            <TableCell sx={{ width: '25%', borderBottom: 'none' }}>
                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                    <Controller name={`fundHistory.${index}.fundRaised`} control={control} defaultValue="" rules={{ required: 'Fund Raised is required' }}
                                                                                        render={({ field }) =>
                                                                                            <TextField
                                                                                                id="outlined-multiline-static"
                                                                                                label="Fund Raised (₹)*"
                                                                                                placeholder='Enter Fund Raised (₹)'
                                                                                                fullwidth
                                                                                                {...field}
                                                                                                inputProps={
                                                                                                    { readOnly: readyOnlyValue, }
                                                                                                }
                                                                                            />} />
                                                                                    <FormHelperText className='text-danger'>
                                                                                        {errors.fundHistory?.[index]?.fundRaised && errors.fundHistory?.[index]?.fundRaised.message}
                                                                                    </FormHelperText>
                                                                                </FormControl>
                                                                            </TableCell>
                                                                            <TableCell sx={{ width: '25%', borderBottom: 'none' }}>
                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                    <div className="form-group mt-3">
                                                                                        <FormControl fullWidth>
                                                                                            <InputLabel id="demo-simple-select-filled-label">Fund Value</InputLabel>
                                                                                            <Controller name={`fundHistory.${index}.currencyValue`} control={control} defaultValue="" rules={{ required: 'Fund Value is required' }}
                                                                                                render={({ field }) =>
                                                                                                    <Select
                                                                                                        onChange={(e) => handleFundFiter(e.target.value)}
                                                                                                        value={fundName}
                                                                                                    >
                                                                                                        <MenuItem value={0}>Thousand</MenuItem>
                                                                                                        <MenuItem value={1}>Lakh</MenuItem>
                                                                                                        <MenuItem value={2}>Crore</MenuItem>
                                                                                                    </Select>
                                                                                                } />
                                                                                            <FormHelperText className='text-danger'>
                                                                                                {errors.fundHistory?.[index]?.currencyValue && errors.fundHistory?.[index]?.currencyValue.message}
                                                                                            </FormHelperText>
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </FormControl>
                                                                            </TableCell>
                                                                            <TableCell sx={{ width: '25%', borderBottom: 'none' }}>
                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                    <Controller name={`fundHistory.${index}.instrumentType`} control={control} defaultValue="" rules={{ required: 'Instrument Type is required' }}
                                                                                        render={({ field }) =>
                                                                                            <Autocomplete
                                                                                                disableClearable
                                                                                                disablePortal
                                                                                                {...field}
                                                                                                options={fundtype}
                                                                                                onChange={(e, selectedOptions) => {
                                                                                                    field.onChange(selectedOptions);
                                                                                                    handleSelectRole(selectedOptions)
                                                                                                }}
                                                                                                renderInput={(params) => (
                                                                                                    <TextField {...params} label="Fund Type *" />
                                                                                                )}
                                                                                                disabled={readyOnlyValue}
                                                                                            />
                                                                                        } />
                                                                                    <FormHelperText className='text-danger'>
                                                                                        {errors.fundHistory?.[index]?.instrumentType && errors.fundHistory?.[index]?.instrumentType.message}
                                                                                    </FormHelperText>
                                                                                </FormControl>
                                                                            </TableCell>
                                                                            <TableCell sx={{ width: '25%', borderBottom: 'none' }}>
                                                                                {
                                                                                    getValues(`fundHistory.${index}.instrumentType`) === "External Funding" && (
                                                                                        <FormControl sx={{ width: '100%' }} >
                                                                                            <Controller name={`fundHistory.${index}.externalFunding`} control={control} defaultValue="" rules={{ required: 'External Funding is required' }}
                                                                                                render={({ field }) =>
                                                                                                    <Autocomplete
                                                                                                        disableClearable
                                                                                                        disablePortal
                                                                                                        {...field}
                                                                                                        options={externalFund}
                                                                                                        onChange={(e, selectedOptions) => {
                                                                                                            field.onChange(selectedOptions);
                                                                                                            handleSelectExternalFunding(selectedOptions)
                                                                                                        }}
                                                                                                        renderInput={(params) => (
                                                                                                            <TextField {...params} label="External Funding*" />
                                                                                                        )}
                                                                                                        disabled={readyOnlyValue}
                                                                                                    />
                                                                                                } />
                                                                                            <FormHelperText className='text-danger'>
                                                                                                {errors.fundHistory?.[index]?.externalFunding && errors.fundHistory?.[index]?.externalFunding.message}
                                                                                            </FormHelperText>
                                                                                        </FormControl>
                                                                                    )
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell sx={{ borderBottom: 'none' }} align='center'>
                                                                                <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteFundingHistory(index) : <></> }} />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                            <center>
                                                                <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addFundDetailsBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                            </center>
                                                        </TableContainer>
                                                    ) : (
                                                        <div className='nodata_tag'>
                                                            <p>Fundraising history details is empty</p>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div> */}

                                    </>
                                )
                            }



                        </div>
                        <div className="form-group d-flex justify-content-end mt-3 mr-2">
                            <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </LoadingButton>
                                ) : (<></>)
                            }

                        </div>
                    </form>
                    <Modal
                        open={open}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={modalstyle} className="form_tag_lg">
                            {/* <Fundingprograms handleClose={handleClose} fundingProgramDetails={fundingProgramDetails} setFundingProgramDetails={setFundingProgramDetails} fundingProgramIndex={fundingProgramIndex} fundingProgramsList={fundingProgramsList} /> */}
                        </Box>
                    </Modal>
                </div>
                <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
            </section>
        </>
    )
}

export default Fundingscst
