import React, { useState } from 'react';
import { Container, FormHelperText } from '@mui/material'
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import ReCAPTCHA from 'react-google-recaptcha';
// import apiService from '../../api/apiService';
// import notifyService from '../../api/notifySerivce';
// import cryptoJS from '../../hooks/cryptoJS';

const Resendmail = ({ handleClose }) => {
    const { handleSubmit, control, reset, formState: { errors } } = useForm({});
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showRePassword, setShowRePassword] = React.useState(false);
    const handleClickShowRePassword = () => setShowRePassword((show) => !show);
    const [loading, setLoading] = useState(false);
    const [checkSamePassword, setCheckSamePassword] = useState(false)
    const [capVal, setCapVal] = useState(null)
    const reCAPTCHA=process.env.REACT_APP_CAPTCHA
    const onSubmit = (data) => {
        setLoading(true);
        setCheckSamePassword(false)
        var getform = data
        getform.id = 0
        apiService(`resendregisterationmail`, getform, 'post').then((res) => {
            setLoading(false);
            if (res) {
                if (res.data.responseStatus === "Success") {
                    localStorage.setItem('forcePassword', 'false')
                    notifyService('success', 'Success', 'OTP Sent Successfully')
                    handleClose()
                }
            }
        })

    }

    return (
        <section className=''>
            <Container>
                <div className='header_tag text-center'>
                    <h5 className='c-black'>Re-send verification e-mail</h5>
                </div>
                <div className=''>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-3">
                            <Controller name="email" control={control} defaultValue="" rules={{ required: 'Email Id is required' }}
                                render={({ field }) =>
                                    <TextField
                                        variant='outlined'
                                        label="Email Id *"
                                        placeholder='Enter Email Id'
                                        fullWidth
                                        {...field}
                                    />
                                }
                            />
                            <FormHelperText className='text-danger'>{errors.email && errors.email.message}</FormHelperText>
                        </div>
                        <div className="form-group text-center">
                            <div className='d-flex justify-content-center mb-3'>
                                <ReCAPTCHA sitekey={reCAPTCHA} onChange={val => setCapVal(val)} />
                            </div>
                            <Button variant="contained" onClick={handleClose} className="btn btn-cancel submit px-3">Cancel</Button>
                            <LoadingButton
                                type="submit"
                                loading={loading}
                                disabled={!capVal}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                className="btn btn-primary submit px-3  ml-3"
                            >
                                <span>Send</span>
                            </LoadingButton>
                        </div>
                    </form>
                </div>
            </Container>
        </section>
    )
}

export default Resendmail