import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Box, FormHelperText, Modal, TextField } from "@mui/material";
import { useForm, useFormState } from "react-hook-form";
import apiService from "../../../api/apiService";
import Button from "@mui/material/Button";
import AccountCircle from "@mui/icons-material/AccountCircle";
import KeyIcon from "@mui/icons-material/Key";
import InputAdornment from "@mui/material/InputAdornment";
import cryptoJS from "../../../hooks/cryptoJS";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./signin.css";
import DataContext from "../../../context/DataContext";
import notifyService from "../../../api/notifySerivce";
import Signuproutemodal from "../signup-page/signupModal/Signuproutemodal";
import ReCAPTCHA from "react-google-recaptcha";
import { LoadingButton } from "@mui/lab";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import Signupscstmodal from "../signup-page/startup-signup/Signupscstmodal";
import Signinscstmodal from "../signup-page/startup-signup/Signinscstmodal";
import CustomLink from "../../common-page/CustomLink";
const SignIn = () => {
  localStorage.removeItem("StartupId");
  document.getElementById("theme").setAttribute("href", "/css/admin.css");
  const reCAPTCHA = process.env.REACT_APP_CAPTCHA;
  const {
    modalstyle,
    handleClose,
    open,
    handleOpen,
    navigator,
    loading,
    setLoading,
  } = useContext(DataContext);
  const { register, handleSubmit, control } = useForm();
  const { errors } = useFormState({ control });
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [capVal, setCapVal] = useState(null);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useDidMountEffect(() => {
    homeApi();
  }, []);

  const [homeData, setHomeData] = useState({});
  const homeApi = () => {
    setHomeData({});
    apiService(`home`, "", "get").then((res) => {
      if (res.data) {
        setHomeData(res.data);
      }
    });
  };

  const [openscst, setOpenscst] = useState(false);
  const onSubmit = async (data, event) => {
    var getform = data;
    getform.password = cryptoJS(data.password);
    setLoading(true);
    await apiService("login", data, "unauthpost").then((res) => {
      setLoading(false);
      if (res) {
        notifyService("success", "Success", "Welcome to TANFUND !!");
        localStorage.setItem("jwttoken", res.data.token);
        localStorage.setItem("userId", res.data.userId);
        localStorage.setItem("role", res.data.role);
        localStorage.setItem(
          "privileges",
          JSON.stringify(res.data.privileges)
        );
        localStorage.setItem("username", res.data.userName);
        localStorage.setItem("logo", res.data.organizationLogoName);
        localStorage.setItem("name", res.data.name);
        localStorage.setItem("forcePassword", res.data.forceChangePassword);
        event.target.reset();
        // setToken(res.data.token)
        const tanseed = res.data.tanseedInvestor;
        localStorage.setItem("tanseed", tanseed);
        if (res.data.role === "ADMIN") {
          localStorage.setItem("routePath", "/admin/dashboard");
          navigator("/admin/dashboard");
        } else if (res.data.role === "STARTUP") {
          startupGetApi();
          if (res.data.redirects === "TanseedCompleted") {
            localStorage.setItem("routePath", "/startup/dashboard/tanseed");
            localStorage.setItem("redirects", res.data.redirects);
            navigator("/startup/dashboard/tanseed");
          } else if (res.data.redirects === "Application") {
            localStorage.setItem("routePath", "/startup/info");
            localStorage.setItem("redirects", res.data.redirects);
            navigator("/startup/info");
          } else {
            navigator('/startup/info')
            localStorage.setItem("routePath", "/startup/info");
          }
        } else if (res.data.role === "PARTNER") {
          localStorage.setItem("routePath", "/partner/startup");
          if (res.data.profileUpdated === true) {
            navigator("/partner/startup");
          } else {
            navigator("/partner/profile");
          }
        } else if (res.data.role === "INVESTOR") {
          localStorage.setItem("routePath", "/investor/associated-startups");
          if (res.data.profileUpdated === true) {
            navigator("/investor/associated-startups");
          } else {
            navigator("/investor/profile");
          }
        } else if (res.data.role === "JURY") {
          localStorage.setItem("routePath", "/jury/tanseed");
          navigator("/jury/tanseed");
        } else if (res.data.role === "TANSEED") {
          localStorage.setItem("routePath", "/tanseed/partner");
          navigator("/tanseed/partner");
        } else if (res.data.role === "SCSTHUB") {
          localStorage.setItem("routePath", "/scst/startups");
          navigator("/scst/startups");
        } else if (res.data.role === "SCSTADMIN") {
          localStorage.setItem("routePath", "/scstadmin/startups");
          navigator("/scstadmin/startups");
        } else if (res.data.role === "SCSTHUBAVP") {
          localStorage.setItem("routePath", "/scsthubavp/startups/dashboard");
          navigator("/scsthubavp/startups");
        } else if (res.data.role === "ICADMIN") {
          localStorage.setItem("routePath", "/ic-admin/startup");
          navigator("/ic-admin/startup");
        } else if (res.data.role === "BDD") {
          localStorage.setItem("routePath", "/scstbdd/startups");
          navigator("/scstbdd/startups");
        } else if (res.data.role === "MENTORSHIP") {
          localStorage.setItem("routePath", "/scstmentorship/startups");
          navigator("/scstmentorship/startups");
        }
      }
    });
  };
  const handlescstClose = () => {
    setOpenscst(false);
  };
  const startupGetApi = async () => {
    await apiService(`startup/alldetails/get`, "", "get").then((res) => {
      if (res) {
        let response = res.data;
        localStorage.setItem("tanseed", response.enableTanseedButton);
      }
    });
  };
  const handleHomePage = () => {
    navigator("/");
  }
  return (
    <>
      <marquee>
        <h3 className="text-danger mt-5">{homeData.maintenanceMessage}</h3>
      </marquee>
      <section className="log-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="wrap d-md-flex">
                <div className="text-wrap p-4 p-lg-5 text-center d-flex align-items-center order-md-last">
                  <div className="text w-100">
                    <img
                      src="/images/Tanfundlogo-white-1.png"
                      className="signin_logo c-pointer"
                      alt=""
                      onClick={() => handleHomePage()}
                    />
                    {/* <p>Are you a startup ?</p> */}
                    <div>
                      <Button
                        onClick={handleOpen}
                        className="btn btn-white btn-outline-white"
                      >
                        Register
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="login-wrap p-4 p-lg-5 bg-white">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4">Sign In</h3>
                    </div>
                    {/* <div className="w-100">
                      <p className="social-media d-flex justify-content-end">
                        <Link className="social-icon d-flex align-items-center justify-content-center" target="_blank" to={'https://www.facebook.com/TheStartupTN/'}><span
                          className="fab fa-facebook-f"></span></Link>
                        <Link className="social-icon d-flex align-items-center justify-content-center" target="_blank" to={'https://twitter.com/TheStartupTN'}><span
                          className="fab fa-twitter"></span></Link>
                      </p>
                    </div> */}
                  </div>
                  <form
                    className="signin-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-group mb-3">
                      <TextField
                        variant="outlined"
                        label="Username / Email"
                        fullWidth
                        autoComplete="userName"
                        placeholder="Enter Username / Email"
                        autoFocus
                        {...register("userName", {
                          required: "Username / Email required",
                        })}
                        error={!!errors?.userName}
                        helperText={
                          errors?.userName ? errors.userName.message : null
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <TextField
                        variant="outlined"
                        label="Password"
                        placeholder="Enter Password"
                        fullWidth
                        autoComplete="password"
                        {...register("password", {
                          required: "Password id required",
                        })}
                        error={!!errors?.password}
                        helperText={
                          errors?.password ? errors.password.message : null
                        }
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <KeyIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                      <FormHelperText>
                        {" "}
                        <CustomLink className="c-blue" to={"/forgot"}>
                          Forgot Password
                        </CustomLink>
                      </FormHelperText>
                    </div>
                    <div className="form-group">
                      <div className="d-flex justify-content-center mb-3">
                        {/* dev -> 6LdEc4YoAAAAAD7exCN9xw41WW20_Aa3wpxsgTvj */}
                        {/* local -> 6LcaFYIoAAAAAP93phV9qkG0EcGDMyw_SJJAB_O9 */}
                        {/* live -> 6Lee24YoAAAAAATfkCzwSRTv7Zu7ASyb0ogFlprH */}
                        {/* qa -> 6LdTX-QoAAAAADwSrpOUT4XwUZo9TB0lZptVyG28 */}
                        <ReCAPTCHA sitekey={reCAPTCHA} onChange={(val) => setCapVal(val)} />
                      </div>
                      <LoadingButton
                        loading={loading}
                        disabled={!capVal}
                        loadingPosition="start"
                        type="submit"
                        variant="contained"
                        className="form-control btn btn-primary submit px-3"
                      >
                        Sign In
                      </LoadingButton>
                    </div>
                    <div className="text-center">
                      {/* <p className='mb-0 font-lg'>First Time Login ? <Link to='/login' className='c-blue'>Click Here</Link></p> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle} className="form_tag_lg">
          <Signuproutemodal handleClose={handleClose} />
        </Box>
      </Modal>
      <Modal
        open={openscst}
        onClose={handlescstClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle} className="form_tag_lg">
          {/* <Signupscstmodal /> */}
          <Signinscstmodal />
        </Box>
      </Modal>
    </>
  );
};

export default SignIn;
