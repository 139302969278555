import React, { useContext, useState } from 'react'
import DataContext from '../../../context/DataContext'
import { useParams } from 'react-router-dom'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import apiService from '../../../api/apiService'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Scsthubavpvalidation from './Scsthubavpvalidation'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Scsthubavpevaluation from './Scsthubavpevaluation'
import Applicationscst from '../../scst-page/Applicationscst'
import Fundingscstview from '../../scst-page/Fundingscstview'
import Founderscstview from '../../scst-page/Founderscstview'
import Moreinfosave from '../../investor-module/associated-startups-page/associated-details-page/pages/Moreinfosave'
import { FaCheckCircle } from 'react-icons/fa';
import Viewimage from '../../../api/Viewimage'
import CommonViewPitchDeck from '../../common-page/CommonViewPitchDeck'
import IncubatorAccelerationScst from '../../scst-page/IncubatorAccelerationScst'
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Scsthubavpdetails = ({ role }) => {
  const { navigator } = useContext(DataContext)
  const { startupId } = useParams()
  const { id } = useParams()
  let idNumber = atob(startupId)
  let partnerOrInvestorId = atob(id)
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  useDidMountEffect(() => {
    listApi()
    viewStartupDetails(idNumber, partnerOrInvestorId)
  }, [])
  const [listData, setListData] = useState([])
  const listApi = () => {
    var req = {
      "partnerOrInvestorId": partnerOrInvestorId,
      "startupId": idNumber
    }
    apiService('partner/scst/admin/startup/get', req, 'post').then((result) => {
      if (result) {
        if (result.data.startupRounds) {
          setListData(result.data.startupRounds)
        }
      }
    }).catch((err) => {

    });
  }
  const [startupGetData, setStartupGetData] = useState({})
  const [startupValue, setStartupValue] = useState({})
  const viewStartupDetails = async (id, partnerOrInvestorId) => {
    // setNavTabValue('1')
    setStartupGetData({})
    setStartupValue({})
    apiService(`partner/scstadmin/startups/get?id=${id}&partnerOrInvestorId=${partnerOrInvestorId}`, '', 'get').then((res) => {
      if (res) {
        if (res.data) {
          if (res.data.applicationForm) {
            setStartupGetData(res.data.applicationForm)

          }
          setStartupValue(res.data)
          // debugger
          // if (res.data.showRounds === true) {
          //     roundPartnerList()
          // }
        } else {
          setStartupGetData({})
        }
      }
    })
  }

  const isRegistered = startupGetData.dpiitRegistered;

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section>
      <div className="container-fluid">
        <div className="form_div chart">
          {/* <div className='d-flex justify-content-between'>
            <p className='dflex ml-3'><b>
              {startupValue.startupName}</b>
              <div
                className={`dpiit-button ${isRegistered ? 'registered' : 'not-registered'}`}
              >
                {isRegistered && <FaCheckCircle className="icon" />}
                {isRegistered ? 'DPIIT REGISTERED' : 'Non DPIIT REGISTERED'}
              </div>
            </p>
            {details.financialProjectionsName && (
            <div className="dflex">
              <p className="ml-auto">
                <span
                  className="viewdetails"
                  onClick={() => {
                    handleClickOpen(startupValue.pitchdeck);
                  }}
                >
                  View PitchDeck
                </span>
              </p>
            </div>
            )}
          </div> */}

          <div className='dflex'>
            <div className='top-banner'>
              <img src={startupGetData.startupLogoName} className='img-fluid user_logos' alt='' />
              <div className='content'>
                <h3 className='startupname-sur'>{startupValue.startupName}</h3>
                <span className='black-clr-sur'><img src='/images/phone.png' alt='' className='mr-2' /> {startupValue.startupPhone ? (startupValue.startupPhone) : ('-')}</span>
                <span className='black-clr-sur ml-3'><img src='/images/email.png' alt='' className='mr-2' /> {startupValue.startupEmail ? (startupValue.startupEmail) : ('-')}</span>
                <span className='black-clr-sur ml-3'>Member Since: {startupValue.date}</span>
              </div>
            </div>

            <div className='ml-auto'>
              <p className='dflex'>
                {/* <b>{startupValue.startupName}</b> */}
                <div
                  className={`dpiit-button ${isRegistered ? 'registered' : 'not-registered'}`}
                >
                  {isRegistered && <FaCheckCircle className="icon" />}
                  {isRegistered ? 'DPIIT REGISTERED' : 'Non DPIIT REGISTERED'}
                </div>
              </p>
              {/* {details.financialProjectionsName && ( */}
              <div className="dflex">
                <p className="ml-auto">
                  <span
                    className="viewdetails-sur"
                    onClick={() => {
                      handleClickOpen(startupGetData.pitchDeck);
                    }}
                  >
                    View PitchDeck
                  </span>
                </p>
              </div>
              {/* )} */}
            </div>
          </div>

          {/* <div>
            <div className='header_tag'>
              <span className='d-flex'><img src={startupGetData.startupLogoName} className='img-fluid user_logos' alt='' /><h3 className='startupname'>{startupValue.startupName}</h3></span>
              <span className='black-clr'><img src='/images/phone.png' alt='' className='mr-2' /> {startupValue.startupPhone ? (startupValue.startupPhone) : ('-')}</span>
              <span className='black-clr'><img src='/images/email.png' alt='' className='mr-2' /> {startupValue.startupEmail ? (startupValue.startupEmail) : ('-')}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <p className='dflex'>
                <div
                  className={`dpiit-button ${isRegistered ? 'registered' : 'not-registered'}`}
                >
                  {isRegistered && <FaCheckCircle className="icon" />}
                  {isRegistered ? 'DPIIT REGISTERED' : 'Non DPIIT REGISTERED'}
                </div>
              </p>
              <div className="dflex">
                <p className="ml-auto">
                  <span
                    className="viewdetails"
                    onClick={() => {
                      handleClickOpen(startupValue.pitchdeck);
                    }}
                  >
                    View PitchDeck
                  </span>
                </p>
              </div>
            </div>
          </div> */}
          <hr />

          <div className='overall'>
            <Box>
              <Tabs
                className='verticalTab'
                orientation="horizontal"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                {
                  partnerOrInvestorId !== "0" && (<Tab className='verticallbl' label="Validation" {...a11yProps(0)} />)
                }
                {
                  partnerOrInvestorId !== "0" && (<Tab className='verticallbl' label="Evaluation" {...a11yProps(1)} />)
                }
                {
                  role === 'scstadmin' && (<Tab className='verticallbl' label="Application Overview" {...a11yProps(partnerOrInvestorId === "0" ? 0 : 2)} />)
                }
                {
                  role === 'scstadmin' && (<Tab className='verticallbl' label="Funding & Financials" {...a11yProps(partnerOrInvestorId === "0" ? 1 : 3)} />)
                }
                {
                  role === 'scstadmin' && (<Tab className='verticallbl' label="Founder Info" {...a11yProps(partnerOrInvestorId === "0" ? 2 : 4)} />)
                }
                {
                  role === 'scstadmin' && (<Tab className='verticallbl' label="Incubation & Accelaration" {...a11yProps(5)} />)
                }
              </Tabs>
              {
                partnerOrInvestorId !== "0" && (<TabPanel value={value} index={0}><Scsthubavpvalidation listData={listData[0]} /></TabPanel>)
              }
              {
                partnerOrInvestorId !== "0" && (<TabPanel value={value} index={1}>
                  <div className='table_tag'>
                    <Scsthubavpevaluation listData={listData[1]} />
                  </div>
                </TabPanel>)
              }


              <TabPanel value={value} index={partnerOrInvestorId === "0" ? 0 : 2}><Applicationscst details={startupGetData} detail={startupValue} /></TabPanel>
              <TabPanel value={value} index={partnerOrInvestorId === "0" ? 1 : 3}><Fundingscstview details={startupGetData} detail={startupValue} /></TabPanel>
              <TabPanel value={value} index={partnerOrInvestorId === "0" ? 2 : 4}><Founderscstview details={startupGetData} /></TabPanel>
              <TabPanel value={value} index={5}><IncubatorAccelerationScst details={startupGetData} detail={startupValue} /></TabPanel>
              {/* <TabPanel value={value} index={5}><Moreinfosave idNumber={idNumber} /></TabPanel> */}
              {/* <TabPanel value={value} index={1}><Fundingscstview details={startupGetData} detail={startupValue} /></TabPanel> */}
              {/* <TabPanel value={value} index={2}><Founderscstview details={startupGetData} /></TabPanel> */}
              {/* <TabPanel value={value} index={3}><Moreinfosave idNumber={idNumber} /></TabPanel> */}
            </Box>
          </div>
        </div>
      </div>
      <CommonViewPitchDeck viewImage={viewImage} open={open} handleClose={handleClose} />
    </section >
  )
}

export default Scsthubavpdetails