import React, { useContext, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Button, Grid } from '@mui/material';
import Viewimage from '../../api/Viewimage';
import DataContext from '../../context/DataContext';

const Founderscstview = ({ details }) => {
    const { Accordion, AccordionSummary, AccordionDetails } = useContext(DataContext);
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value) => {
        setViewImage(value)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [expanded, setExpanded] = useState('');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <main>
            {
                (details.startupFounders) ? (
                    <div>
                        <div className='div_header'>
                            <h6>Founder Info</h6>
                        </div>
                        {/* <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 1000 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>Community</TableCell>
                                        <TableCell>Caste</TableCell>
                                        <TableCell>Aadhar File</TableCell>
                                        <TableCell>Community File</TableCell>
                                        <TableCell>PAN File</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {details.startupFounders.map((list, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                {list.name}
                                            </TableCell>
                                            <TableCell>
                                                {list.email}
                                            </TableCell>
                                            <TableCell>
                                                {list.phone}
                                            </TableCell>
                                            <TableCell>
                                                {list.community}
                                            </TableCell>
                                            <TableCell>
                                                {list.caste ? (list.caste) : (`-`)}
                                            </TableCell>
                                            <TableCell >
                                                {
                                                    list.aadharCertificateName ? (
                                                        <img src='/images/driving.png' alt='' className='c-pointer' onClick={() => handleClickOpen(list.aadharCertificateName)} />
                                                    ) : ('-')
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    list.communityCertificateName ? (
                                                        <img src='/images/document.png' alt='' className='c-pointer' onClick={() => handleClickOpen(list.communityCertificateName)} />
                                                    ) : ('-')
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    list.panDocName ? (
                                                        <img src='/images/document.png' alt='' className='c-pointer' onClick={() => handleClickOpen(list.panDocName)} />
                                                    ) : ('-')
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </TableContainer> */}
                        {details.startupFounders.map((list, index) => (
                            <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                                <AccordionSummary aria-controls="panel1d-content" id={`panel${index}d-header`}>
                                    <div className="d-flex align-items-center w-100">
                                        <h5>{list.name} - Shares ({list.cumulativePercent ? (list.cumulativePercent) : ('NA')})</h5>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid
                                        className="mt-2"
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={2}
                                        key={index}
                                    >
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <p><b>Email :</b></p>
                                            <p>{list.email ? (list.email) : ('NA')}</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <p><b>Phone :</b></p>
                                            <p>{list.phone ? (list.phone) : ('NA')}</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <p><b>Gender :</b></p>
                                            <p>{list.gender ? (list.gender) : ('NA')}</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <p><b>Community :</b></p>
                                            <p>{list.community === 'Others' ? (list.casteOthers) : (list.community)}</p>
                                            {
                                                list.community === 'ST' ? (
                                                    <>
                                                        <p><b>Caste :</b></p>
                                                        <p>{list.caste ? (list.caste) : (`-`)}</p>
                                                    </>
                                                ) : ('NA')
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <div className='mb-3'>
                                                <p><b>Community Certificate No & Certificate:</b></p>
                                                <div>
                                                    <p className='doc-flex'>{list.communityCertificateNo ? (list.communityCertificateNo) : ('NA')}
                                                        {
                                                            list.communityCertificateName && (
                                                                <div className='ml-3'>
                                                                    <span className='viewdoc1' onClick={() => handleClickOpen(list.communityCertificateName)}>View Document</span>
                                                                </div>
                                                            )
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div className='mb-3'>
                                                <p><b>Community Certificate :</b></p>
                                                {
                                                    list.communityCertificateName ? (
                                                        <div className='ml-auto'>
                                                            <span className='viewdoc' onClick={() => handleClickOpen(list.communityCertificateName)}>View Document</span>
                                                        </div>
                                                    ) : ('NA')
                                                }
                                            </div> */}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <div className='mb-3'>
                                                <p><b>PAN No & Card :</b></p>
                                                <p className='doc-flex'>{list.panNumber ? (list.panNumber) : ('NA')}
                                                    {
                                                        list.panDocName && (
                                                            <div className='ml-3'>
                                                                <span className='viewdoc1' onClick={() => handleClickOpen(list.panDocName)}>View Document</span>
                                                            </div>
                                                        )
                                                    }
                                                </p>
                                            </div>
                                            {/* <div className='mb-3'>
                                                <p><b>PAN Document :</b></p>
                                                {
                                                    list.panDocName ? (
                                                        <div className='ml-auto'>
                                                            <span className='viewdoc' onClick={() => handleClickOpen(list.panDocName)}>View Document</span>
                                                        </div>
                                                    ) : ('NA')
                                                }
                                            </div> */}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            {/* <div className='mb-3'>
                                                <p><b>Aadhar Number :</b></p>
                                                <p>{list.aadhaarNumber ? (list.aadhaarNumber): ('NA')}</p>
                                            </div> */}
                                            {/* <div className='mb-3'>
                                                <p><b>Aadhar Certificate :</b></p>
                                                {
                                                    list.aadharCertificateName ? (
                                                        <div className='ml-auto'>
                                                            <span className='viewdoc' onClick={() => handleClickOpen(list.aadharCertificateName)}>View Document</span>
                                                        </div>
                                                    ) : (`-`)
                                                }
                                            </div> */}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            {/* <div className='mb-3'>
                                                <p><b>Share Holding Percentage :</b></p>
                                                <p>{list.cumulativePercent ? (list.cumulativePercent): ('NA')}</p>
                                            </div> */}
                                            {/* <div className='mb-3'>
                                                <p><b>Aadhar Certificate :</b></p>
                                                {
                                                    list.aadharCertificateName ? (
                                                        <div className='ml-auto'>
                                                            <span className='viewdoc' onClick={() => handleClickOpen(list.aadharCertificateName)}>View Document</span>
                                                        </div>
                                                    ) : (`-`)
                                                }
                                            </div> */}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))
                        }
                    </div>
                ) : (
                    <div className='nodata_tag'>
                        <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                        <p>Founder details not available</p>
                    </div>
                )
            }
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
        </main>
    )
}

export default Founderscstview
