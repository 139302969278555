import React, { useContext, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DataContext from '../../../context/DataContext';
// import useDidMountEffect from '../../hooks/useDidMountEffect';
// import apiService from '../../api/apiService';
import { useParams } from 'react-router-dom'
import Scsthubavpremarks from './Scsthubavpremarks';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { Button } from '@mui/material';
// import Scsthubavpremarks from '../scsthubavp-module/scsthubavp-details/Scsthubavpremarks';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const Scsthubavpevaluation = ({ listData }) => {
  const { navigator, Accordion, AccordionSummary, AccordionDetails } = useContext(DataContext)
  // const [viewQuestionData, setViewQuestionData] = useState([])
  // const [startupData, setStartupData] = useState({})
  const [juryData, setJuryData] = useState([])
  useDidMountEffect(() => {
    if (listData) {
      // setListData(listData.startupRounds[1])
      setJuryData(listData.jury)
    }
  }, [listData])
  const [expanded, setExpanded] = useState('');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  let statusText;

  if (listData.status === 0) {
    statusText = 'N/A';
  } else if (listData.status === 1) {
    statusText = 'Recommend to BDD';
  } else if (listData.status === 2) {
    statusText = 'Recommend to Mentorship';
  } else {
    statusText = '';
  }
  const handleBack = ()=>{
    navigator('scst/startups/evaluation')
  }
  return (

    <>

      {
        listData && (
          <div className=''>
            <Button onClick={handleBack}><ArrowBackIcon /></Button>
            <div className='table_header_tag d-flex justify-content-between'>
              <div>Status : <b className='c-green'>{statusText}</b></div>
              {
                listData && juryData && (
                  <div className='ml-auto'>Overall Average Score : <b>{listData.avgScore}</b></div>
                )
              }

            </div>
            {juryData ? juryData.map((data, index) => (
              <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                <AccordionSummary aria-controls="panel1d-content" id={`panel${index}d-header`}>
                  <div className='d-flex w-100'>
                    <div><b>{data.name}</b></div>
                    <div className='ml-auto'>Average Score : <b>{data.score}</b>
                    </div>
                    <div className='ml-auto'>

                      Status :
                      <b>
                        {data.status === 0 && <span> Pending</span>}
                        {data.status === 1 && <span className='c-green'> Recommend to BDD</span>}
                        {data.status === 2 && <span className='c-green'> Recommend to MentorShip</span>}
                        {data.status === 3 && <span className='c-red'> Rejected</span>}
                      </b>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className='table_tag'>
                    {
                      (data.startupQuestions) ? (
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead>
                              <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Question</TableCell>
                                <TableCell align='center'>Score</TableCell>
                                <TableCell align='center'>Remarks</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.startupQuestions.map((list, index) => (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>
                                    {list.question}
                                  </TableCell>
                                  <TableCell align='center'>
                                    {
                                      list.questionType === 'yesno' ? (
                                        list.answer
                                      ) : (
                                        list.score
                                      )
                                    }
                                  </TableCell>
                                  <TableCell align='center'>
                                    <Scsthubavpremarks remarks={list.remarks} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>

                        </TableContainer>
                      ) : (
                        <div className='nodata_tag'>
                          <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                          <p>No Data Available</p>
                        </div>
                      )
                    }
                  </div>
                </AccordionDetails>
              </Accordion>
            )) : (
              <div className='nodata_tag'>
                <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                <p>No Data Available</p>
              </div>
            )
            }
          </div>
        )
      }
    </>

  )
}

export default Scsthubavpevaluation