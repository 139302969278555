import React, { useContext, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Autocomplete, Button, FormControl, FormControlLabel, FormHelperText, TextField, Grid, FormLabel, RadioGroup, Radio, Switch, Stack, styled, } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { MuiFileInput } from "mui-file-input";
import DataContext from "../../../context/DataContext";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PreviewIcon from "@mui/icons-material/Preview";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import apiService from "../../../api/apiService";
import notifyService from "../../../api/notifySerivce";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typography from '@mui/material/Typography';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from "../../../api/Viewimage";



const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));


const fileTypes = ["pdf"];
const fileTypesimg = ["jpg, png, jpeg"];


const Founderscst = ({ index }) => {
  const showAddInfo = localStorage.getItem("showInfo")
  const [formData, setFormData] = useState({
    startupFounders: [{}], startupTeams: [{}],
  });
  const { setPageLoading, navigator, validateEmail, handleBackapp, validatePhoneNumber, VisuallyHiddenInput, validatePANNumber, checkAlphanumeric, Accordion, AccordionSummary, AccordionDetails, validatePercentage, validateNumberonly } = useContext(DataContext);
  const { handleSubmit, control, getValues, setValue, clearErrors, watch, reset, formState: { errors }, } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    fields: foundersFields,
    append: founderAppend,
    remove: foundersRemove,
  } = useFieldArray({
    control,
    name: "startupFounders",
  });

  const {
    fields: membersFields,
    append: membersAppend,
    remove: membersRemove,
  } = useFieldArray({
    control,
    name: "startupTeams",
  });

  // const [expanded, setExpanded] = useState('');
  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };
  const [cumulativePercent, setCumulativePercent] = useState('');
  useDidMountEffect(() => {
    getApi();
    getCommityApi()
    // getRolesApi()
  }, []);
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/team/get`, "", "get").then((res) => {
      if (res) {
        setPageLoading(false)
        setFormData({});
        let response = res.data;
        if (response.id !== 0) {
          if (response.startupFounders) {
            response.startupFounders.forEach((element) => {
              if (element.communityCertificateName) {
                element.communityStatus = "update";
                element.communityCertificateURL = element.communityCertificateName
                setIsPanUploaded(true)
              }
              if (element.aadharCertificateName) {
                element.aadharCertificateStatus = "update";
              }
              if (element.panDocName) {
                element.panStatus = "update";
                element.panURL = element.panDocName
                setIsUploaded(true)
              }
            });
            response.founderCount = response.startupFounders.length
          }
          if (response.editForm === false) {
            setReadyOnlyValue(true);
          }
          setFormData(response);
        } else {
          setFormData(response);
        }
      }
    });
  };
  const addFounderDetailsBtn = () => {
    founderAppend({
      name: "",
      email: "",
      phone: "",
      community: "",
      caste: "",
      communityCertificate: "",
      aadharCertificate: "",
      holdingSharegt50: "",
      panConcern: "",
      panDoc: "",
      panStatus: "",
      panDocName: "",
      panNumber: "",
      communityCertificateName: "",
      communityStatus: "",
      communityCertificateNo: "",
      cumulativePercent: "",
      Otherscaste: "",
      gender: "",
      differentlyAbled: "",
      education: "",
      experience: "",
      companiesOwned: "",
    });
  };

  const addTeamMemberBtn = () => {
    membersAppend({
      name: '',
      role: '',
      responsibility: '',
      education: '',
      professionalExperience: '',
      relevantExperience: ''
    });
  }

  const removeTeammemberBtn = (index) => {
    const id = getValues(`startupTeams.${index}.id`);
    if (id !== undefined) {
      apiService(`startup/teamdetails/remove?id=${id}`, '', 'delete').then((res) => {
        if (res) {
          if (res.data.responseStatus === 'Success') {
            membersRemove(index)
            getApi()
          }
        }
      })
    } else {
      membersRemove(index)
    }
    if (index === 0) {
      addTeamMemberBtn()
    }
  }


  const removeFounderBtn = (index) => {
    const id = getValues(`startupFounders.${index}.id`);
    if (id !== undefined) {
      apiService(`startup/founders/remove?id=${id}`, '', 'get').then((res) => {
        if (res) {
          if (res.data.responseStatus === 'Success') {
            foundersRemove(index)
            getApi()
          }
        }
      })
    } else {
      foundersRemove(index)
    }
    if (index === 0) {
      addFounderDetailsBtn()
    }
  };
  const allowedTypes = ["jpeg", "jpg", "png", "pdf"];
  const [clicktoView, setClicktoView] = useState(false)
  const handleFileUpload = (event, filekey, fileName, fileURL, index, status) => {
    debugger
    let logoselectedFile;
    if (event.target === undefined) {
      logoselectedFile = event;
    } else {
      logoselectedFile = event.target.files[0];
    }

    if (logoselectedFile) {
      if (logoselectedFile.size > 5242880) {
        notifyService("danger", 'File Size Exceeded', "File size exceeds 5MB limit");
        return;
      }
      // Check file type

      const fileType = logoselectedFile.type.split("/")[1];
      if (!allowedTypes.includes(fileType)) {
        // notifyService("Unsupported file type");
        notifyService("danger", "Unsupported file type");
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(logoselectedFile);
      reader.onload = () => {
        const logoUrl = reader.result;
        const imageData = logoUrl.split(",")[1];
        const imageName = logoselectedFile.name;
        setClicktoView(true)
        setValue(`startupFounders.${index}.${filekey}`, imageData);
        setValue(`startupFounders.${index}.${fileName}`, imageName);
        setValue(`startupFounders.${index}.${fileURL}`, logoUrl)
        setValue(`startupFounders.${index}.${status}`, "save");
        clearErrors(`startupFounders.${index}.${filekey}`);
        clearErrors(`startupFounders.${index}.${fileName}`);
        clearErrors(`startupFounders.${index}.${fileURL}`);
      };
    }
  };

  const handlePanCheck = (e, index) => {
    setValue(`startupFounders.${index}.panConcern`, e.target.checked);
    clearErrors(`startupFounders.${index}.panConcern`);
  };
  // let communityList = [
  //   "SC", "ST", "BC", "OBC", "General", "Others"
  // ]
  const [communityList, setCommunityList] = useState([])
  const getCommityApi = () => {
    apiService(`admin/dropdownvalues/get?key=community`, '', 'get').then((result) => {
      if (result && result.data && result.data.dropDownValues) {
        setCommunityList(result.data.dropDownValues.sectors)
      }
    }).catch((err) => {

    });
  }

  // const [genderList, setGenderList] = useState([])
  let genderList = [
    "Male", "Female", "Transgender"
  ]
  let roleList = [
    "Product/ Service Management", "Operation Management", "Market Management", "Sales Management",
    "Finance (CA)", "Accounts", "Legal (CS)"
  ]

  const handleSelectGender = (value, index) => {
    setValue(`startupFounders.${index}.gender`, value);
    clearErrors(`startupFounders.${index}.gender`);
  }
  // const [roleval, setRoleVal] = useState('')
  const handleSelectTeamRole = (value) => {
    setValue(`startupMembers.${index}.role`, value);
    clearErrors(`startupMembers.${index}.role`);
    // setRoleVal(value)
  }
  const handleSelectCommunity = (value, index) => {
    setValue(`startupFounders.${index}.community`, value);
    clearErrors(`startupFounders.${index}.community`);
    if (value === 'SC') {
      setValue(`startupFounders.${index}.caste`, '');
    }
  }

  // const [rolesList, setRolesList] = useState([])
  // const getRolesApi = () => {
  //   apiService(`admin/dropdownvalues/get?key=roles`, '', 'get').then((result) => {
  //     if (result && result.data && result.data.dropDownValues) {
  //       setRolesList(result.data.dropDownValues.sectors)
  //     }
  //   }).catch((err) => {

  //   });
  // }




  const handleSelectRole = (value, index) => {
    setValue(`employeeRoles`, value);
    clearErrors(`employeeRoles`);
  }

  const currentEmployeePresent = watch("currentEmployeePresent", false);
  const handleFilter = (isCurrentEmployeePresent) => {
    setValue("currentEmployeePresent", isCurrentEmployeePresent);
    if (!isCurrentEmployeePresent) {
      setValue("currentEmployeePresent", "");
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    if (readyOnlyValue === false) {

      // if (data.founderCount <= data.startupFounders.length) {
      setPageLoading(true);
      var getform = data;

      apiService("startup/team/save", getform, "post").then((res) => {
        setPageLoading(false);
        if (res) {
          if (res.data.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            navigator("/startup/incubation-accelaration/SCST");
          }
        }
      });
    } else {
      notifyService("danger", "Error", "Please add founder");
    }

  }
  // };
  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };


  // const [expanded, setExpanded] = useState('panel0');
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  const [expanded, setExpanded] = useState(['panel0', 'panel1']);

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded(prev => [...prev, panel]);
    } else {
      setExpanded(prev => prev.filter(p => p !== panel));
    }
  };


  const [pitchDeckValue, setPitchDeckValue] = useState(null)
  const [pitchDeckName, setPitchDeckName] = useState(null)
  const [pitchDeckURL, setPitchDeckURL] = useState(null)

  const [isUploaded, setIsUploaded] = useState([]);
  const handleFileChange = (index) => (e) => {
    handleFileUpload(e,
      "communityCertificate",
      "communityCertificateName",
      "communityCertificateURL",
      index,
      "communityStatus", 'file');

    setIsUploaded(prevState => ({
      ...prevState,
      [index]: true
    }));
  };

  const [isPanUploaded, setIsPanUploaded] = useState({});
  // const handleFileChangepan = (index) => (e) => {
  //   handleFileUpload(e,
  //     "panDoc",
  //     "panDocName",
  //     index,
  //     "panStatus")
  //   setIsPanUploaded(true)
  // }
  const handleFileChangepan = (index) => (e) => {
    handleFileUpload(e,
      "panDoc",
      "panDocName",
      "panURL",
      index,
      "panStatus"
    );

    setIsPanUploaded(prevState => ({
      ...prevState,
      [index]: true
    }));
  };

  const handleGender = (value, index) => {
    setValue(`startupFounders.${index}.gender`, value)
    clearErrors(`startupFounders.${index}.gender`)
  }

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section className="container-fluid">
      <div className="form_div">
        <div className="d-flex align-items-center">
          <span className="back_span" onClick={handleBackapp}>
            <img src='/images/Fig-back.png' className='img-fluid' alt='' />
          </span>
          <h6 className="ml-2 mb-0">Founder Info</h6>
        </div>
        <hr />
        <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="row">
            <div className="d-none">
              <div className="form-group">
                <Controller
                  name="id"
                  control={control}
                  defaultValue="0"
                  render={({ field }) => <TextField {...field} />}
                />
              </div>
            </div>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <div className='d-none'>
                <div className="form-group">
                  <Controller name="id" control={control} defaultValue="0"
                    render={({ field }) =>
                      <TextField
                        {...field}
                      />} />
                </div>
              </div>


              {/* <Grid item xs={12} sm={12} md={4} lg={4} className="mb-4 ml-3">
                <div className="d-flex align-items-center founder-names">
                  <p className='mt-3 mr-3 number-founder'>Number of Founders</p>
                  <Controller name="founderCount" control={control} defaultValue="" sx={{ borderRadius: '15px' }} rules={{ required: 'Founder Count is required' }}
                    render={({ field }) =>
                      <TextField
                        sx={{ width: '52px', height: '5px', borderRadius: '18px', marginTop: '-27px' }}
                        id="outlined-multiline-static"
                        size="small"
                        variant='outlined'
                        fullWidth
                        {...field}
                        type="text"
                        onKeyDown={(e) => validateNumberonly(e)}
                        inputProps={{ readOnly: readyOnlyValue }}
                      />} />
                </div>
                <FormHelperText className='text-danger'>{errors.founderCount && errors.founderCount.message}</FormHelperText>
              </Grid> */}
            </Grid>

            <div className="col-12 p-0">
              {/* <div className="div_header">
                <div className="d-flex">
                  <h6>Founder Details</h6>
                  <Button
                    variant="outlined"
                    disabled={readyOnlyValue}
                    className="ml-auto"
                    onClick={() => addFounderDetailsBtn("")}
                  >
                    Add Founder Details
                  </Button>
                </div>
              </div> */}
              <div className="founder-box-div">

                <Accordion className='Addfundacc-bor-acc m-0' expanded={expanded.includes('panel0')} onChange={handleChange('panel0')}>
                  <AccordionSummary aria-controls="panel0-content" id="panel0-header" className='Addfundacc' >
                    <div className="d-flex align-items-center w-100">
                      <h5>Founder Details</h5>
                      {/* {index + 1} */}
                      {/* <div className="ml-auto">
                              <Button
                                variant="outlined"
                                disabled={readyOnlyValue}
                                color="error"
                                onClick={() => {
                                  readyOnlyValue === false ? (
                                    removeFounderBtn(index)
                                  ) : (
                                    <></>
                                  );
                                }}
                              >
                                Remove Founder Details
                              </Button>
                            </div> */}
                    </div>
                  </AccordionSummary>
                  {foundersFields && foundersFields.length && (
                    <>
                      {foundersFields.map((field, index) => (
                        <AccordionDetails key={field.id}>
                          <Grid item xs={12} sm={12} md={1} lg={1} className="text-right">
                            <div className="ml-4 mt-4">
                              <span disabled={readyOnlyValue} onClick={() => {
                                readyOnlyValue === false ? (removeFounderBtn(index)) :
                                  (
                                    <></>
                                  );
                              }}
                              >
                                <DeleteIcon style={{ fill: '#253b80', cursor: 'pointer' }} />
                              </span>
                            </div>
                          </Grid>
                          <Grid
                            className="mt-2"
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                            key={index}
                          >
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                              <FormControl sx={{ width: "100%" }}>
                                <Controller
                                  name={`startupFounders.${index}.name`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "Name is required", pattern: {
                                      value: /^[a-zA-Z0-9\s]*$/,
                                      message: 'Only alphabets and numbers are allowed'
                                    }
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      id={`name-${index}`}
                                      label="Name *"
                                      placeholder="Enter Name"
                                      fullWidth
                                      {...field}
                                      error={!!errors.startupFounders?.[index]?.name}
                                      helperText={errors.startupFounders?.[index]?.name ? errors.startupFounders?.[index]?.name.message : ''}
                                      // inputProps={{ readOnly: readyOnlyValue }}
                                      inputProps={{
                                        readOnly: readyOnlyValue,
                                      }}
                                    />
                                  )}
                                />
                                {/* <FormHelperText className="text-danger">
                                  {errors.startupFounders?.[index]?.name &&
                                    errors.startupFounders?.[index]?.name.message}
                                </FormHelperText> */}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                              <FormControl sx={{ width: "100%" }}>
                                <Controller
                                  name={`startupFounders.${index}.email`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "Email is required",
                                    validate: validateEmail,
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      // id={`email-${index}`}
                                      variant="outlined"
                                      label="Email *"
                                      placeholder="Enter Email"
                                      fullWidth
                                      {...field}
                                      inputProps={{ readOnly: readyOnlyValue }}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.startupFounders?.[index]?.email &&
                                    errors.startupFounders?.[index]?.email
                                      .message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={2}>
                              <FormControl sx={{ width: "100%" }}>
                                <Controller
                                  name={`startupFounders.${index}.phone`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "Phone number is required",
                                    validate: validatePhoneNumber,
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      id={`phone-${index}`}
                                      variant="outlined"
                                      label="Phone Number *"
                                      placeholder="Enter Phone Number"
                                      fullWidth
                                      {...field}
                                      type="number"
                                      inputProps={{ readOnly: readyOnlyValue, maxLength: 10 }}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.startupFounders?.[index]?.phone && errors.startupFounders?.[index]?.phone
                                    .message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2} className="text-center">
                              {/* <Controller name={`startupFounders.${index}.gender`} control={control} defaultValue="" rules={{ required: 'Gender is required' }}
                                render={({ field }) =>
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    {...field}
                                    options={genderList}
                                    onChange={(e, selectedOptions) => handleSelectGender(selectedOptions, index)}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Gender *" />
                                    )}
                                    disabled={readyOnlyValue}
                                  />
                                } />
                              <FormHelperText className="text-danger">
                                {errors.startupFounders?.[index]?.gender && errors.startupFounders?.[index]?.gender.message}
                              </FormHelperText> */}
                              <Controller name={`startupFounders.${index}.gender`} control={control} defaultValue="" rules={{ required: 'Gender is required' }}
                                render={({ field }) =>
                                  <div className='p-relative gender_input'>
                                    <span className={getValues(`startupFounders.${index}.gender`) === 'Male' ? 'genderspan genderAct' : 'genderspan'} onClick={() => { handleGender('Male', index) }}>
                                      <img src='/images/male.png' className='sml_img' alt='' />
                                    </span>
                                    <span className={getValues(`startupFounders.${index}.gender`) === 'Female' ? 'genderspan genderAct' : 'genderspan'} onClick={() => { handleGender('Female', index) }}>
                                      <img src='/images/female.png' className='sml_img' alt='' />
                                    </span>
                                    <span className={getValues(`startupFounders.${index}.gender`) === 'Trans' ? 'genderspan genderAct' : 'genderspan'} onClick={() => { handleGender('Trans', index) }}>
                                      <img src='/images/trang.png' className='sml_img' alt='' />
                                    </span>
                                  </div>
                                } />
                              <FormHelperText className="text-danger">
                                {errors.startupFounders?.[index]?.gender && `Gender is required`}
                              </FormHelperText>
                            </Grid>

                            {/* <Grid item xs={12} sm={12} md={2} lg={2} className="text-center"></Grid> */}
                            {/* <Grid item xs={12} sm={12} md={1} lg={1}>
                              <div className="ml-4 mt-4">
                                <span disabled={readyOnlyValue} onClick={() => {
                                  readyOnlyValue === false ? (removeFounderBtn(index)) :
                                    (
                                      <></>
                                    );
                                }}
                                >
                                  <DeleteIcon style={{ fill: '#253b80', cursor: 'pointer' }} />
                                </span>
                              </div>
                            </Grid> */}

                            <Grid item xs={12} sm={12} md={2} lg={2} className='text-center'>
                              <div className="checkbox founder-check">
                                {/* <Controller
                                  name={`startupFounders.${index}.differentlyAbled`}
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: false }}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      {...field}
                                      label={<span style={{ fontSize: '16px', marginLeft: '10px', color: 'rgb(110 102 102)' }}>Differently abled</span>}
                                      control={
                                        <Checkbox disabled={readyOnlyValue} value={field.value} checked={field.value} style={{ marginLeft: '55px' }} />
                                      }
                                    />
                                  )}
                                /> */}
                                <Controller
                                  name={`startupFounders.${index}.differentlyAbled`}
                                  control={control}
                                  defaultValue={false}
                                  rules={{ required: false }}
                                  render={({ field }) => (
                                    <Button
                                      onClick={() => field.onChange(!field.value)}
                                      disabled={readyOnlyValue}
                                      style={{
                                        backgroundColor: field.value ? 'rgb(38 58 128)' : '#d9d9d9',
                                        color: field.value ? 'white' : 'white',
                                        border: '1px solid',
                                        borderColor: field.value ? 'rgb(38 58 128)' : '#d9d9d9',
                                        borderRadius: '32px',
                                        padding: '8px 16px',
                                        textAlign: 'center',
                                        fontSize: '11px',
                                        cursor: readyOnlyValue ? 'not-allowed' : 'pointer'
                                      }}
                                    >
                                      Differently Abled
                                    </Button>
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.startupFounders?.[index]?.differentlyAbled && errors.startupFounders?.[index]?.differentlyAbled.message}
                                </FormHelperText>
                              </div>
                            </Grid>
                            {
                              showAddInfo === "true" && (
                                <>
                                  <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`startupFounders.${index}.education`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: 'Education is required',
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            variant="outlined"
                                            label="Education *"
                                            placeholder="Enter Education"
                                            fullWidth
                                            {...field}
                                            type="text"
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.startupFounders?.[index]?.education && errors.startupFounders?.[index]?.education.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`startupFounders.${index}.experience`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: 'Experience is required',
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            variant="outlined"
                                            label="Experience *"
                                            placeholder="Enter Experience"
                                            fullWidth
                                            {...field}
                                            type="text"
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.startupFounders?.[index]?.experience && errors.startupFounders?.[index]?.experience.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                </>
                              )
                            }
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                              <FormControl sx={{ width: "100%" }}>
                                <Controller
                                  name={`startupFounders.${index}.panNumber`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: 'PAN Number is required',
                                    validate: validatePANNumber
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      variant="outlined"
                                      label="PAN Number *"
                                      placeholder="Enter PAN Number"
                                      fullWidth
                                      {...field}
                                      type="text"
                                      inputProps={{ readOnly: readyOnlyValue, maxLength: 10 }}
                                      onKeyDown={(e) => checkAlphanumeric(e)}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.startupFounders?.[index]?.panNumber && errors.startupFounders?.[index]?.panNumber.message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3} >
                              <div className="btn-position">
                                <div className="d-flex align-items-center ">
                                  <div className='form-group mb-1'>
                                    <Controller name={`startupFounders.${index}.panDoc`} control={control} defaultValue="" rules={{ required: 'File is required' }}
                                      render={({ field }) =>
                                        <FileUploader
                                          {...field}
                                          handleChange={handleFileChangepan(index)}
                                          value={getValues(`startupFounders.${index}.panDocName`)}
                                          name="file"
                                          // types={allowedTypes}
                                          types={fileTypes}
                                          children={
                                            <div className={isPanUploaded[index] ? 'fileupload-pdf-dpiit-bg2-active' : 'fileupload-pdf-dpiit-bg2'}>
                                              <center>
                                                <p className={isPanUploaded[index] ? 'fileupload-pdf2-active' : 'fileupload-pdf2-pan'}>
                                                  <span>
                                                    <AttachFileIcon className="file-icon mr-1" />
                                                    {isPanUploaded[index] ? 'Uploaded (Click to change)' : 'Upload Pan'}
                                                  </span>
                                                </p>
                                              </center>

                                            </div>
                                          }
                                        />
                                      } />
                                    <FormHelperText className="text-danger">{errors.startupFounders?.[index]?.panDoc && errors.startupFounders?.[index]?.panDoc.message} </FormHelperText>
                                  </div>
                                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                                    // to={getValues(`startupFounders.${index}.panDocName`)}
                                    // target="_blank"
                                    onClick={() => handleClickOpen(getValues(`startupFounders.${index}.panURL`))}
                                    style={{ color: "#80809e" }}
                                  > <span className="ml-2">Preview</span>
                                  </Link><br />
                                  </span>
                                </div>
                                <FormHelperText className="ml-2 ">Maximum 5 mb allowed (png, jpg, jpeg,pdf)</FormHelperText>
                              </div>
                            </Grid>
                            {
                              showAddInfo === "true" && (
                                <>
                                  <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`startupFounders.${index}.companiesOwned`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: 'No of companies owned is required',
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            variant="outlined"
                                            label="No of companies owned ? *"
                                            placeholder="Enter no of companies owned"
                                            fullWidth
                                            {...field}
                                            type="text"
                                            onKeyDown={(e) => validateNumberonly(e)}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.startupFounders?.[index]?.companiesOwned && errors.startupFounders?.[index]?.companiesOwned.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                </>
                              )
                            }
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                              <FormControl sx={{ width: "100%" }}>
                                <Controller
                                  name={`startupFounders.${index}.community`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "Community is required",
                                  }}
                                  render={({ field }) => (
                                    <Autocomplete
                                      disableClearable
                                      disablePortal
                                      {...field}
                                      options={communityList}
                                      onChange={(e, selectedOptions) => handleSelectCommunity(selectedOptions, index)}
                                      renderInput={(params) => (
                                        <TextField {...params} label="Community *" />
                                      )}
                                      disabled={readyOnlyValue}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.startupFounders?.[index]?.community &&
                                    errors.startupFounders?.[index]?.community
                                      .message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            {getValues(`startupFounders.${index}.community`) === 'ST' && (
                              <Grid item xs={12} sm={12} md={4} lg={3}>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupFounders.${index}.caste`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: 'Sub caste is required',
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Sub Caste *"
                                        placeholder="Enter your sub caste"
                                        fullWidth
                                        {...field}
                                        inputProps={{ readOnly: readyOnlyValue }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupFounders?.[index]?.caste && errors.startupFounders?.[index]?.caste.message}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                            )}
                            {
                              getValues(`startupFounders.${index}.community`) === 'Others' && (
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                  <FormControl sx={{ width: "100%" }}>
                                    <Controller
                                      name={`startupFounders.${index}.casteOthers`}
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                        required: 'Community is required',
                                      }}
                                      render={({ field }) => (
                                        <TextField
                                          variant="outlined"
                                          label="Other Community *"
                                          placeholder="Enter Other Community"
                                          fullWidth
                                          {...field}
                                          inputProps={{ readOnly: readyOnlyValue }}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.startupFounders?.[index]?.casteOthers &&
                                        errors.startupFounders?.[index]?.casteOthers
                                          .message}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              )
                            }
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                              <FormControl sx={{ width: "100%" }}>
                                <Controller
                                  name={`startupFounders.${index}.communityCertificateNo`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: 'Community Certificate Number is required'
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      variant="outlined"
                                      label="Community Certificate Number *"
                                      placeholder="Enter Community Certificate Number"
                                      fullWidth
                                      {...field}
                                      type="text"
                                      inputProps={{ readOnly: readyOnlyValue, maxLength: 12 }}
                                      onKeyDown={(e) => checkAlphanumeric(e)}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.startupFounders?.[index]?.communityCertificateNo &&
                                    errors.startupFounders?.[index]?.communityCertificateNo
                                      .message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3} >
                              {/* <div >
                                {getValues(
                                  `startupFounders.${index}.communityStatus`
                                ) === "update" ? (
                                  <>
                                    <div className="row align-item-center">
                                      <div className="col-lg-7 col-12">
                                        <p className='mb-0'>Community Certificate <span className="text-danger">*</span></p>
                                        <Controller
                                          name={`startupFounders.${index}.communityCertificateName`}
                                          control={control}
                                          defaultValue=""
                                          rules={{ required: "File is required" }}
                                          render={({ field }) => (
                                            <Button
                                              component="label"
                                              variant="contained"
                                              {...field}
                                              fullWidth
                                              onChange={(e) =>
                                                handleFileUpload(
                                                  e,
                                                  "communityCertificate",
                                                  "communityCertificateName",
                                                  index,
                                                  "communityStatus"
                                                )
                                              }
                                              startIcon={<CloudUploadIcon />}
                                              href="#file-upload"
                                              disabled={readyOnlyValue}
                                            >
                                              View
                                              {readyOnlyValue === false ? (
                                                <VisuallyHiddenInput type="file" />
                                              ) : (
                                                <></>
                                              )}
                                            </Button>
                                          )}
                                        />

                                      </div>
                                      <div className="col-lg-5 col-12">
                                        <Fab variant="extended">
                                          <Link
                                            to={getValues(
                                              `startupFounders.${index}.communityCertificateName`
                                            )}
                                            target="_blank"
                                            color="black"
                                          >
                                            <PreviewIcon sx={{ mr: 1 }} />
                                            Document
                                          </Link>
                                        </Fab>
                                      </div>
                                      <div className="px-3">
                                        <span className="fn-12">
                                          Maximum 5 mb allowed  (png, jpg,
                                          jpeg, pdf){" "}
                                          <span className="text-danger">*</span>
                                        </span>
                                        <FormHelperText className="text-danger">
                                          {errors.startupFounders?.[index]
                                            ?.communityCertificate &&
                                            errors.startupFounders?.[index]
                                              ?.communityCertificate.message}
                                        </FormHelperText>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <FormControl sx={{ width: "100%" }}>

                                      {getValues(
                                        `startupFounders.${index}.communityStatus`
                                      ) === "save" ? (

                                        <>
                                          <label>Community Certificate <span className="text-danger">*</span></label>
                                          <Controller
                                            name={`startupFounders.${index}.communityCertificateName`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "File is required" }}
                                            render={({ field }) => (
                                              <Button
                                                component="label"
                                                variant="contained"
                                                color="success"
                                                {...field}
                                                fullWidth
                                                onChange={(e) =>
                                                  handleFileUpload(
                                                    e,
                                                    "communityCertificate",
                                                    "communityCertificateName",
                                                    index,
                                                    "communityStatus"
                                                  )
                                                }
                                                startIcon={<CheckCircleOutlineIcon />}
                                                href="#file-upload"
                                                disabled={readyOnlyValue}
                                              >
                                                {" "}
                                                Uploaded
                                                {readyOnlyValue === false ? (
                                                  <VisuallyHiddenInput type="file" />
                                                ) : (
                                                  <></>
                                                )}
                                              </Button>
                                            )}
                                          />
                                        </>

                                      ) : (
                                        <Controller
                                          name={`startupFounders.${index}.communityCertificate`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required: " File is required",
                                          }}
                                          render={({ field }) => (
                                            <MuiFileInput
                                              size="small"
                                              {...field}
                                              onChange={(e) =>
                                                handleFileUpload(
                                                  e,
                                                  "communityCertificate",
                                                  "communityCertificateName",
                                                  index,
                                                  "communityStatus"
                                                )
                                              }
                                              // value={getValues(`startupFounders.${index}.communityCertificateName`)}
                                              variant="outlined"
                                              fullWidth
                                              label="Upload Community  *"
                                              placeholder="Choose File"
                                              disabled={readyOnlyValue}
                                            />
                                          )}
                                        />
                                      )}
                                      <FormHelperText className="text-danger">
                                        {errors.startupFounders?.[index]
                                          ?.communityCertificate &&
                                          errors.startupFounders?.[index]
                                            ?.communityCertificate.message}
                                      </FormHelperText>
                                      <span className="font-sm">
                                        Maximum 5 mb allowed (png, jpg, jpeg,
                                        pdf) <span className="text-danger">*</span>
                                        
                                      </span>
                                    </FormControl>
                                  </>
                                )}
                              </div> */}
                              <div className="btn-position">
                                <div className="d-flex align-items-center ">
                                  <div className='form-group mb-1'>
                                    <Controller name={`startupFounders.${index}.communityCertificate`} control={control} defaultValue="" rules={{ required: 'File is required' }}
                                      render={({ field }) =>
                                        <FileUploader
                                          {...field}
                                          handleChange={handleFileChange(index)}
                                          // value={pitchDeckValue}
                                          value={getValues(`startupFounders.${index}.communityCertificateName`)}
                                          name="file"
                                          // types={allowedTypes}
                                          types={fileTypes}
                                          children={
                                            <div className={isUploaded[index] ? 'fileupload-pdf-dpiit-bg2-active' : 'fileupload-pdf-dpiit-bg2'}>
                                              <center>
                                                <p className={isUploaded[index] ? 'fileupload-pdf2-active' : 'fileupload-pdf2'}>
                                                  <span>
                                                    <AttachFileIcon className="file-icon mr-1" />
                                                    {isUploaded[index] ? 'Uploaded (Click to change)' : 'Upload Community'}
                                                  </span>
                                                </p>
                                              </center>
                                            </div>
                                          }
                                        />
                                      } />
                                    <FormHelperText className="text-danger">{errors.startupFounders?.[index]?.communityCertificate && errors.startupFounders?.[index]?.communityCertificate.message} </FormHelperText>
                                  </div>
                                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                                    // to={getValues(`startupFounders.${index}.communityCertificateName`)}
                                    // target="_blank"
                                    onClick={() => handleClickOpen(getValues(`startupFounders.${index}.communityCertificateURL`))}
                                    style={{ color: "#80809e" }}
                                  > <span className="ml-2">Preview</span>
                                  </Link><br />
                                  </span>
                                </div>
                                <FormHelperText className="ml-2 ">Maximum 5 mb allowed (png, jpg, jpeg,pdf)</FormHelperText>
                              </div>
                            </Grid>

                            {/* <div >
                                {getValues(`startupFounders.${index}.panStatus`) === "update" ? (
                                  <>
                                    <div className="row align-item-center">
                                      <div className="col-lg-7 col-12">
                                        <p className='mb-0'>PAN Document <span className="text-danger">*</span></p>
                                        <Controller
                                          name={`startupFounders.${index}.panDoc`}
                                          control={control}
                                          defaultValue=""
                                          rules={{ required: "File is required" }}
                                          render={({ field }) => (
                                            <Button
                                              component="label"
                                              variant="contained"
                                              {...field}
                                              fullWidth
                                              onChange={(e) =>
                                                handleFileUpload(
                                                  e,
                                                  "panDoc",
                                                  "panDocName",
                                                  index,
                                                  "panStatus"
                                                )
                                              }
                                              startIcon={<CloudUploadIcon />}
                                              href="#file-upload"
                                              disabled={readyOnlyValue}
                                            >
                                              View
                                              {readyOnlyValue === false ? (
                                                <VisuallyHiddenInput type="file" />
                                              ) : (
                                                <></>
                                              )}
                                            </Button>
                                          )}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-12">
                                        <Fab variant="extended">
                                          <Link
                                            to={getValues(
                                              `startupFounders.${index}.panDocName`
                                            )}
                                            target="_blank"
                                            color="black"
                                          >
                                            <PreviewIcon sx={{ mr: 1 }} />
                                            Document
                                          </Link>
                                        </Fab>
                                      </div>
                                      <div className="px-3">
                                        <span className="fn-12">
                                          Maximum 5 mb allowed (png, jpg,
                                          jpeg, pdf){" "}
                                          <span className="text-danger">*</span>
                                        </span>
                                        <FormHelperText className="text-danger">
                                          {errors.startupFounders?.[index]
                                            ?.panDoc &&
                                            errors.startupFounders?.[index]
                                              ?.panDoc.message}
                                        </FormHelperText>
                                      </div>
                                      <div className="d-flex align-items-center btn-align  mt-2 ">
                                        <Controller
                                          name={`startupFounders.${index}.panConcern`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required: "Concern Required",
                                          }}
                                          render={({ field: props }) => (
                                            <Checkbox
                                              {...props}
                                              checked={props.value}
                                              onChange={(e) =>
                                                handlePanCheck(e, index)
                                              }
                                              disabled={readyOnlyValue}
                                            />
                                          )}
                                        />
                                        <p
                                          className="format-details  mt-3 "
                                          style={{ fontSize: "11px" }}
                                        >
                                          I provide my consent to StartupTN to share
                                          my details with the issuers for the
                                          purpose of fetching my documents.
                                        </p>
                                      </div>
                                      <FormHelperText className="text-danger">
                                        {errors.startupFounders?.[index]
                                          ?.panConcern &&
                                          errors.startupFounders?.[index]
                                            ?.panConcern.message}
                                      </FormHelperText>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <FormControl sx={{ width: "100%" }}>
                                      {getValues(`startupFounders.${index}.panStatus`) === "save" ? (
                                        <>
                                          <label>PAN Document <span className="text-danger">*</span></label>
                                          <Controller
                                            name={`startupFounders.${index}.panDoc`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "File is required" }}
                                            render={({ field }) => (
                                              <Button
                                                component="label"
                                                variant="contained"
                                                color="success"
                                                {...field}
                                                fullWidth
                                                onChange={(e) =>
                                                  handleFileUpload(
                                                    e,
                                                    "panDoc",
                                                    "panDocName",
                                                    index,
                                                    "panStatus"
                                                  )
                                                }
                                                startIcon={<CheckCircleOutlineIcon />}
                                                href="#file-upload"
                                                disabled={readyOnlyValue}
                                              >
                                                {" "}
                                                Uploaded
                                                {readyOnlyValue === false ? (
                                                  <VisuallyHiddenInput type="file" />
                                                ) : (
                                                  <></>
                                                )}
                                              </Button>
                                            )}
                                          />
                                        </>
                                      ) : (
                                        <Controller
                                          name={`startupFounders.${index}.panDoc`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required: " File is required",
                                          }}
                                          render={({ field }) => (
                                            <MuiFileInput
                                              size="small"
                                              {...field}
                                              onChange={(e) =>
                                                handleFileUpload(
                                                  e,
                                                  "panDoc",
                                                  "panDocName",
                                                  index,
                                                  "panStatus"
                                                )
                                              }
                                              variant="outlined"
                                              fullWidth
                                              label="Upload PAN Document *"
                                              placeholder="Choose File"
                                              disabled={readyOnlyValue}
                                            />
                                          )}
                                        />
                                      )}
                                      <FormHelperText className="text-danger">
                                        {errors.startupFounders?.[index]
                                          ?.panDoc &&
                                          errors.startupFounders?.[index]
                                            ?.panDoc.message}
                                      </FormHelperText>
                                      <span className="font-sm">
                                        Maximum 5 mb allowed (png, jpg, jpeg,
                                        pdf) <span className="text-danger">*</span>
                                        
                                      </span>

                                    </FormControl>
                                  </>
                                )}
                              </div> */}


                            {/* <div className="btn-position">
                                <div className="d-flex align-items-center ">
                                  <div className='form-group mb-1'>
                                    <Controller name={`startupFounders.${index}.panDoc`} control={control} defaultValue="" rules={{ required: 'File is required' }}
                                      render={({ field }) =>
                                        <FileUploader
                                          {...field}
                                          handleChange={handleFileChangepan(index)}
                                          value={getValues(`startupFounders.${index}.panDoc`)}
                                          name="file"
                                          types={allowedTypes}
                                          children={
                                            <div className={isPanUploaded[index] ? 'fileupload-pdf-dpiit-bg2-active' : 'fileupload-pdf-dpiit-bg2'}>
                                              <center>
                                                <p className={isPanUploaded[index] ? 'fileupload-pdf2-active' : 'fileupload-pdf2-pan'}>
                                                  <span>
                                                    <AttachFileIcon className="file-icon mr-1" />
                                                    {isPanUploaded[index] ? 'Uploaded (Click to change)' : 'Upload Pan'}
                                                  </span>
                                                </p>
                                              </center>

                                            </div>
                                          }
                                        />
                                      } />
                                    <FormHelperText className="text-danger">{errors.startupFounders?.[index]?.communityCertificate && errors.startupFounders?.[index]?.communityCertificate.message} </FormHelperText>
                                  </div>
                                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                                    to={`${pitchDeckURL}`}
                                    target="_blank"
                                    style={{ color: "#80809e" }}
                                  > <span className="ml-2">Preview</span>
                                  </Link><br />
                                  </span>
                                </div>
                                <FormHelperText className="ml-2 ">Maximum 5 mb allowed (png, jpg, jpeg,pdf)</FormHelperText>
                              </div>
                            </Grid> */}
                            {
                              getValues(`startupFounders.${index}.community`) === 'ST' && (
                                <>
                                  <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`startupFounders.${index}.cumulativePercent`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Share Holding Percentage is required", validate: validatePercentage
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            variant="outlined"
                                            label="Share Holding Percentage *"
                                            placeholder="Enter Share Holding Percentage"
                                            type="number"
                                            fullWidth
                                            {...field}
                                            inputProps={{ readOnly: readyOnlyValue }}
                                            onWheel={(event) => event.currentTarget.querySelector("input")?.blur()}
                                            onChange={(e) => {
                                              setCumulativePercent(e.target.value);
                                              field.onChange(e);
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.startupFounders?.[index]?.cumulativePercent &&
                                          errors.startupFounders?.[index]?.cumulativePercent
                                            .message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                </>
                              )
                            }

                            {
                              getValues(`startupFounders.${index}.community`) === 'SC' && (
                                <>
                                  <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`startupFounders.${index}.cumulativePercent`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Share Holding Percentage is required", validate: validatePercentage
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            variant="outlined"
                                            label="Share Holding Percentage *"
                                            placeholder="Enter Share Holding Percentage"
                                            type="number"
                                            fullWidth
                                            {...field}
                                            inputProps={{ readOnly: readyOnlyValue }}
                                            onWheel={(event) => event.currentTarget.querySelector("input")?.blur()}
                                            onChange={(e) => {
                                              setCumulativePercent(e.target.value);
                                              field.onChange(e);
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.startupFounders?.[index]?.cumulativePercent &&
                                          errors.startupFounders?.[index]?.cumulativePercent
                                            .message}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                </>
                              )
                            }



                            {
                              getValues(`startupFounders.${index}.community`) !== 'SC' &&
                                getValues(`startupFounders.${index}.community`) !== 'ST' ? (
                                <>
                                  {/* <Grid item xs={12} sm={12} md={3} lg={3}></Grid> */}
                                  <Grid item xs={12} sm={12} md={5} lg={5}>
                                    <div className="d-flex align-items-center ">
                                      <Controller
                                        name={`startupFounders.${index}.panConcern`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Concern Required",
                                        }}
                                        render={({ field: props }) => (
                                          <Checkbox
                                            {...props}
                                            checked={props.value}
                                            onChange={(e) =>
                                              handlePanCheck(e, index)
                                            }
                                            disabled={readyOnlyValue}
                                          />
                                        )}
                                      />
                                      <p
                                        className="format-details mt-2"
                                        style={{ fontSize: "11px" }}
                                      >
                                        I provide my consent to StartupTN to share
                                        my details with the issuers for the
                                        purpose of fetching my documents.
                                      </p>
                                    </div>
                                    <FormHelperText className="text-danger">
                                      {errors.startupFounders?.[index]
                                        ?.panConcern &&
                                        errors.startupFounders?.[index]
                                          ?.panConcern.message}
                                    </FormHelperText>
                                  </Grid>
                                </>
                              ) : (
                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                  <div className="d-flex align-items-center ">
                                    <Controller
                                      name={`startupFounders.${index}.panConcern`}
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                        required: "Concern Required",
                                      }}
                                      render={({ field: props }) => (
                                        <Checkbox
                                          {...props}
                                          checked={props.value}
                                          onChange={(e) =>
                                            handlePanCheck(e, index)
                                          }
                                          disabled={readyOnlyValue}
                                        />
                                      )}
                                    />
                                    <p
                                      className="format-details"
                                      style={{ fontSize: "11px" }}
                                    >
                                      I provide my consent to StartupTN to share
                                      my details with the issuers for the
                                      purpose of fetching my documents.
                                    </p>
                                  </div>
                                  <FormHelperText className="text-danger">
                                    {errors.startupFounders?.[index]
                                      ?.panConcern &&
                                      errors.startupFounders?.[index]
                                        ?.panConcern.message}
                                  </FormHelperText>
                                </Grid>
                              )
                            }
                            {
                              Number(cumulativePercent) > 50
                              && (getValues(`startupFounders.${index}.community`) === 'SC' ||
                                getValues(`startupFounders.${index}.community`) === 'ST') && (
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                  <div className="checkbox">
                                    <Controller
                                      name={`startupFounders.${index}.holdingSharegt50`}
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: 'Cumulative Holding of SC/ST is required' }}
                                      render={({ field }) => (
                                        <FormControlLabel
                                          {...field}
                                          label='Cumulative Holding of SC/ST holders above 51%'
                                          control={
                                            <Checkbox disabled={readyOnlyValue} value={field.value} checked={field.value} />
                                          }
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.startupFounders?.[index]?.holdingSharegt50 && errors.startupFounders?.[index]?.holdingSharegt50.message}
                                    </FormHelperText>
                                  </div>
                                </Grid>
                              )
                            }

                          </Grid>
                        </AccordionDetails>
                      ))}
                      <center>
                        <Button className='add-more-btn mb-5' disabled={readyOnlyValue} onClick={() => addFounderDetailsBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                      </center>
                    </>
                    // ) : (
                    // <div className="nodata_tag">
                    //   <p>Founder details is empty</p>
                    // </div>
                  )}
                </Accordion>
              </div>
            </div>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              className="m-0"
            >
              <div className='d-none'>
                <div className="form-group">
                  <Controller name="id" control={control} defaultValue="0"
                    render={({ field }) =>
                      <TextField
                        {...field}
                      />} />
                </div>
              </div>

              {
                showAddInfo === "true" && (
                  <>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="form-group mt-2">
                        <div className="d-flex">
                          <span className="">Are there any current employees?</span>
                          <span className="ml-3 d-flex justify-content-center align-items-center">
                            <Stack direction="row" spacing={1} alignItems="center">
                              No{" "}
                              {/* <Switch
                              checked={currentEmployeePresent}
                              disabled={readyOnlyValue}
                              onChange={() => handleFilter(!currentEmployeePresent)}
                              color="primary"
                            /> */}

                              {/* <Typography>Off</Typography> */}
                              <AntSwitch className="mx-2"
                                checked={currentEmployeePresent}
                                disabled={readyOnlyValue}
                                onChange={() => handleFilter(!currentEmployeePresent)}
                                inputProps={{ 'aria-label': 'ant design' }} />
                              {/* <Typography>On</Typography> */}

                              Yes{" "}
                            </Stack>{" "}
                          </span>
                        </div>
                      </div>
                    </Grid>
                    {
                      currentEmployeePresent === true && (
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div className="form-group mt-2">
                            <span className="mt-1 mx-2" style={{ color: '#263A80' }}>No of Team members</span>
                            <Controller name="teamMemebers" control={control} defaultValue="" rules={{ required: 'Team Members is required' }}
                              render={({ field }) =>
                                <TextField
                                  sx={{ width: '52px', height: '5px', borderRadius: '18px', marginTop: '-7px' }}
                                  id="outlined-multiline-static"
                                  variant='outlined'
                                  size="small"
                                  // label="Team Members? *"
                                  placeholder='Enter team members'
                                  fullWidth
                                  inputProps={
                                    { readOnly: readyOnlyValue, }
                                  }
                                  {...field}
                                />} />
                            <FormHelperText className='text-danger'>{errors.teamMemebers && errors.teamMemebers.message}</FormHelperText>
                          </div>
                        </Grid>
                      )
                    }
                    {currentEmployeePresent === true ? (
                      <>
                        {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                          <div className="form-group mt-2">
                            <Controller
                              name="employeeRoles"
                              control={control}
                              defaultValue={[]}
                              rules={{
                                required: "Roles are required",
                              }}
                              render={({ field }) => (
                                <Autocomplete
                                  multiple
                                  disableClearable
                                  disablePortal
                                  {...field}
                                  limitTags={1}
                                  options={rolesList}
                                  onChange={(e, selectedOptions) => {
                                    field.onChange(selectedOptions);
                                    handleSelectRole(selectedOptions)
                                    // setShowTextarea(selectedOptions.includes("Others"))
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="If YES, what are their roles? *" />
                                  )}
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.employeeRoles && errors.employeeRoles.message}
                            </FormHelperText>
                          </div>
                        </Grid> */}
                      </>
                      // <></>
                    ) : (
                      <>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <div className="form-group">
                            <Controller
                              name="employeeRoles"
                              control={control}
                              defaultValue={[]}
                              rules={{
                                required: "Roles are required",
                              }}
                              render={({ field }) => (
                                <Autocomplete
                                  sx={{ width: '317px' }}
                                  disableClearable
                                  disablePortal
                                  multiple
                                  {...field}
                                  limitTags={1}
                                  options={roleList}
                                  onChange={(e, selectedOptions) => {
                                    field.onChange(selectedOptions);
                                    handleSelectRole(selectedOptions)
                                    // setShowTextarea(selectedOptions.includes("Others"))
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="What are the roles would you like to hire? *" />
                                  )}
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.employeeRoles && errors.employeeRoles.message}
                            </FormHelperText>
                          </div>
                        </Grid>
                      </>
                    )}
                  </>
                )
              }
            </Grid>

            {
              currentEmployeePresent === true ? (
                <Accordion className='Addfundacc-bor-acc' expanded={expanded.includes('panel1')} onChange={handleChange('panel1')}>
                  <AccordionSummary aria-controls="panel1-content" id="panel1-header" className='Addfundacc' >
                    <div className="d-flex align-items-center w-100">
                      <h5>Team Details</h5>
                      {/* <Button className='add-more-btn mb-5' disabled={readyOnlyValue} onClick={() => addTeamMemberBtn('')}><AddCircleIcon className='mr-2' />Add More</Button> */}
                    </div>
                  </AccordionSummary>
                  {membersFields && membersFields.length && (
                    <>
                      {membersFields.map((field, index) => (
                        <AccordionDetails key={index} >
                          <Grid item xs={12} sm={12} md={1} lg={1} className="text-right">
                            <div className="ml-4 my-2">
                              <span disabled={readyOnlyValue} onClick={() => {
                                readyOnlyValue === false ? (removeTeammemberBtn(index)) :
                                  (
                                    <></>
                                  );
                              }}
                              >
                                <DeleteIcon style={{ fill: '#253b80', cursor: 'pointer' }} />
                              </span>
                            </div>
                          </Grid>
                          <Typography>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={2}
                            >
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupTeams.${index}.name`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Name is required",
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Name *"
                                        placeholder="Enter Name"
                                        fullWidth
                                        {...field}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupTeams?.[index]?.name && errors.startupTeams?.[index]?.name.message}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupTeams.${index}.role`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Role is required",
                                    }}
                                    render={({ field }) => (
                                      <Autocomplete
                                        disableClearable
                                        disablePortal
                                        {...field}
                                        options={roleList}
                                        // value={roleval}
                                        onChange={(e, selectedOptions) => {
                                          field.onChange(selectedOptions);
                                          handleSelectTeamRole(selectedOptions)
                                        }}
                                        renderInput={(params) => (
                                          <TextField {...params} label="Role *" />
                                        )}
                                        disabled={readyOnlyValue}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupTeams?.[index]?.role && errors.startupTeams?.[index]?.role.message}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupTeams.${index}.responsibility`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Responsibility is required",
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Responsibility *"
                                        placeholder="Enter Responsibility"
                                        fullWidth
                                        {...field}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupTeams?.[index]?.responsibility && errors.startupTeams?.[index]?.responsibility.message}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupTeams.${index}.education`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Education is required",
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Education *"
                                        placeholder="Enter Education"
                                        fullWidth
                                        {...field}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupTeams?.[index]?.education && errors.startupTeams?.[index]?.education.message}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupTeams.${index}.professionalExperience`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Professional Exp is required",
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Professional Exp *"
                                        placeholder="Enter Professional Exp"
                                        fullWidth
                                        {...field}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupTeams?.[index]?.professionalExperience && errors.startupTeams?.[index]?.professionalExperience.message}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupTeams.${index}.relevantExperience`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Relevant Experience is required",
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Relevant Experience *"
                                        placeholder="Enter Relevant Experience"
                                        fullWidth
                                        {...field}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupTeams?.[index]?.relevantExperience && errors.startupTeams?.[index]?.relevantExperience.message}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Typography>
                        </AccordionDetails>
                      ))}
                      <center>
                        <Button className='add-more-btn mb-5' disabled={readyOnlyValue} onClick={() => addTeamMemberBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                      </center>
                    </>
                  )}
                </Accordion>
              ) : (
                <></>
              )
            }


          </div>
          <div className="form-group text-right">
            <Button
              variant="contained"
              onClick={handleBackapp}
              className="btn btn-cancel submit px-3"
            >
              Cancel
            </Button>
            {readyOnlyValue === false ? (
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                className="btn btn-primary submit px-3 ml-3"
              >
                <span>Save & Preview</span>
              </LoadingButton>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
      <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
    </section>
  );
};

export default Founderscst;
