import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Autocomplete, Button, FormControl, FormHelperText, Grid, styled, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import DataContext from '../../../context/DataContext';


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255,255,255,.35)"
                : "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },
}));

const MarketCompetitorsScst = () => {
    const showAddInfo = localStorage.getItem("showInfo")
    const [formData, setFormData] = useState({
        startupFounders: [{}],
        startupAdvisories: [{}]
    });
    const { navigator, handleBackapp, Paper, setPageLoading } = useContext(DataContext)
    const { handleSubmit, control, setValue, watch, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [loading, setLoading] = useState(false);

    useDidMountEffect(() => {
        // eslint-disable-next-line
        (async () => await getApi())()
    }, [])
    const getApi = async () => {
        setPageLoading(true)
        await apiService(`startup/marketcompetitors/get`, '', 'get').then((res) => {
            if (res) {
                setPageLoading(false)
                setFormData({})
                let response = res.data
                if (response.id !== 0) {
                    setFormData(response)
                    if (response.editForm === false) {
                        setReadyOnlyValue(true)
                    }
                } else {
                    setFormData(response)
                }
            }
        })
    }

    const onSubmit = (data) => {
        if (readyOnlyValue === false) {
            setPageLoading(true);
            var getform = data
            apiService('startup/marketcompetitors/save', getform, 'post').then((res) => {
                setPageLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        navigator('/startup/dashboard/SCST')
                    }
                }
            })
        }
    };

    const surveysConducted = watch("surveysConducted", false);
    const handleFilter = (isSurveysConducted) => {
        setValue("surveysConducted", isSurveysConducted);
        if (!isSurveysConducted) {
            setValue("surveysConducted", "");
        }
    };


    const {
        fields: competitorsFields,
        append: competitorsAppend,
        remove: competitorsRemove
    } = useFieldArray({
        control,
        name: "betterCompetitors"
    });

    const addCompetitorDetailsBtn = () => {
        competitorsAppend({
            id: 0,
            name: "",
            advantage: "",
        })
    }

    const {
        fields: awardsFields,
        append: awardsAppend,
        remove: awardsRemove
    } = useFieldArray({
        control,
        name: "awardsGovernment"
    });

    const addawardsDetailsBtn = () => {
        awardsAppend({
            id: 0,
            awardsorProjects: "",
        })
    }

    const removeAwardsBtn = (index) => {
        awardsRemove(index)
    }

    const deleteCompetitorsHistory = async (index) => {
        competitorsRemove(index)
        const id = getValues(`betterCompetitors.${index}.id`);
        if (id !== 0 && id !== undefined) {
            apiService(`startup/competitors/remove?id=${id}`, '', 'delete').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        competitorsRemove(index)
                        getApi()
                    }
                }
            })
        } else {
            competitorsRemove(index)
        }
    }

    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className='d-flex align-items-center'>
                        <span className='back_span' onClick={handleBackapp}><img src='/images/Fig-back.png' className='img-fluid' alt='' /></span> <h6 className='ml-2 mb-0'>Market & Competitors</h6>
                    </div>
                    <hr />
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        {/* <div className='row'> */}
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={3}
                        >
                            <div className='d-none'>
                                <div className="form-group">
                                    <Controller name="id" control={control} defaultValue="0"
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <div className="form-group mt-2">
                                    <div className="d-flex">
                                        <span className="mt-1">Have you conducted any market studies or surveys?</span>
                                        <span className="ml-3 d-flex justify-content-center align-items-center">
                                            No{" "}
                                            {/* <Switch
                                                checked={surveysConducted}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter(!surveysConducted)}
                                                color="primary"
                                            /> */}
                                            <AntSwitch className="mx-2"
                                                checked={surveysConducted}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter(!surveysConducted)}
                                                inputProps={{ 'aria-label': 'ant design' }} />
                                            {" "}
                                            Yes{" "}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                            {/* <div className="row"> */}
                            {surveysConducted === true ? (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <div className="form-group mt-2">
                                            <Controller
                                                name="surveyDetail"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: "Study/survey are required",
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        defaultValue="Default Value"
                                                        variant='outlined'
                                                        label="Provide details of the study/survey? *"
                                                        placeholder='Enter details of study / survey.'
                                                        fullWidth
                                                        multiline
                                                        rows={3}
                                                        inputProps={
                                                            { readOnly: readyOnlyValue, }
                                                        }
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.surveyDetail && errors.surveyDetail.message}
                                            </FormHelperText>
                                        </div>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <div className="form-group mt-2">
                                            <Controller
                                                name="surveyDetail"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: "Identify target customers are required",
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        defaultValue="Default Value"
                                                        variant='outlined'
                                                        label="How did you identify your target customers? *"
                                                        placeholder='Enter identify your target customers.'
                                                        fullWidth
                                                        multiline
                                                        rows={3}
                                                        inputProps={
                                                            { readOnly: readyOnlyValue, }
                                                        }
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.surveyDetail && errors.surveyDetail.message}
                                            </FormHelperText>
                                        </div>
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <div className="form-group mt-2">
                                    <Controller name="targetCustomers" control={control} defaultValue="" rules={{ required: 'Target customers is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                id="outlined-multiline-static"
                                                defaultValue="Default Value"
                                                variant='outlined'
                                                label="Who are your target customers? *"
                                                placeholder='Enter target customers.'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.targetCustomers && errors.targetCustomers.message}</FormHelperText>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Controller name="marketSize" control={control} defaultValue="" rules={{ required: 'Market size is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            id="outlined-multiline-static"
                                            defaultValue="Default Value"
                                            variant='outlined'
                                            label="What is the market size? *"
                                            placeholder='Enter market size.'
                                            fullWidth
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.marketSize && errors.marketSize.message}</FormHelperText>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Controller name="marketSizeSource" control={control} defaultValue="" rules={{ required: 'Give reference links is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            id="outlined-multiline-static"
                                            defaultValue="Default Value"
                                            variant='outlined'
                                            label="Please mention the source(s) of your market size data, give reference links *"
                                            placeholder='Give reference links.'
                                            fullWidth
                                            multiline
                                            rows={3}
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.marketSizeSource && errors.marketSizeSource.message}</FormHelperText>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Controller name="acquireCustomers" control={control} defaultValue="" rules={{ required: 'Acquire customers is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            id="outlined-multiline-static"
                                            defaultValue="Default Value"
                                            variant='outlined'
                                            label="How will you acquire customers? *"
                                            placeholder='Enter how you acquire customers.'
                                            fullWidth
                                            multiline
                                            rows={3}
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.acquireCustomers && errors.acquireCustomers.message}</FormHelperText>
                            </Grid>

                            {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Controller name="competitors" control={control} defaultValue="" rules={{ required: 'Competitors is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            id="outlined-multiline-static"
                                            defaultValue="Default Value"
                                            variant='outlined'
                                            label="Who are your competitors? *"
                                            placeholder='Enter Your Competitors.'
                                            fullWidth
                                            multiline
                                            rows={3}
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.competitors && errors.competitors.message}</FormHelperText>
                            </Grid> */}

                            {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Controller name="awardsorProjects" control={control} defaultValue="" rules={{ required: 'Idea is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            id="outlined-multiline-static"
                                            defaultValue="Default Value"
                                            variant='outlined'
                                            label="Any awards or government projects related to this idea? *"
                                            placeholder='Enter awards or government projects related to this idea.'
                                            fullWidth
                                            multiline
                                            rows={3}
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.awardsorProjects && errors.awardsorProjects.message}</FormHelperText>
                            </Grid> */}


                            <div className='col-12 my-3'>
                                <div className='div_header'>
                                    <div className='d-flex'>
                                        <h6>Competitors</h6>
                                        <Button variant="outlined" disabled={readyOnlyValue} className='ml-auto' onClick={() => addCompetitorDetailsBtn('')}>Add</Button>
                                    </div>
                                </div>
                                <div>
                                    {
                                        (competitorsFields.length > 0) ? (
                                            <TableContainer component={Paper} className='table-back'>
                                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                    <TableBody >
                                                        {competitorsFields.map((field, index, type) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>
                                                                    <FormControl sx={{ width: '100%' }}>
                                                                        <Controller name={`betterCompetitors.${index}.name`} control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                                                            render={({ field }) =>
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    label="Name*"
                                                                                    placeholder='Enter name'
                                                                                    fullwidth
                                                                                    {...field}
                                                                                    inputProps={
                                                                                        { readOnly: readyOnlyValue, }
                                                                                    }
                                                                                />} />
                                                                        <FormHelperText className='text-danger'>
                                                                            {errors.betterCompetitors?.[index]?.name && errors.betterCompetitors?.[index]?.name.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <FormControl sx={{ width: '100%' }} >
                                                                        <Controller name={`betterCompetitors.${index}.advantage`} control={control} defaultValue="" rules={{ required: 'Advantage is required' }}
                                                                            render={({ field }) =>
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    label="Advantage *"
                                                                                    placeholder='Enter advantage'
                                                                                    fullwidth
                                                                                    {...field}
                                                                                    inputProps={
                                                                                        { readOnly: readyOnlyValue, }
                                                                                    }
                                                                                />} />
                                                                        <FormHelperText className='text-danger'>
                                                                            {errors.betterCompetitors?.[index]?.advantage && errors.betterCompetitors?.[index]?.advantage.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteCompetitorsHistory(index) : <></> }} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>

                                            </TableContainer>
                                        ) : (
                                            <div className='nodata_tag'>
                                                <p>How are your better than competitors details is empty</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>


                            <div className='col-12 my-3'>
                                <div className='div_header'>
                                    <div className='d-flex'>
                                        <h6>Awards</h6>
                                        <Button variant="outlined" disabled={readyOnlyValue} className='ml-auto' onClick={() => addawardsDetailsBtn('')}>Add</Button>
                                    </div>
                                </div>
                                <div>
                                    {
                                        (awardsFields.length > 0) ? (
                                            <TableContainer component={Paper} className='table-back'>
                                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                    <TableBody >
                                                        {awardsFields.map((field, index, type) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>
                                                                    <FormControl sx={{ width: '100%' }}>
                                                                        <Controller name={`awardsGovernment.${index}.awardsorProjects`} control={control} defaultValue="" rules={{ required: 'Idea is required' }}
                                                                            render={({ field }) =>
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    defaultValue="Default Value"
                                                                                    variant='outlined'
                                                                                    label="Any awards or government projects related to this idea? *"
                                                                                    placeholder='Enter awards or government projects related to this idea.'
                                                                                    fullWidth
                                                                                    multiline
                                                                                    rows={3}
                                                                                    inputProps={
                                                                                        { readOnly: readyOnlyValue, }
                                                                                    }
                                                                                    {...field}
                                                                                />} />
                                                                        <FormHelperText className='text-danger'>
                                                                            {errors.awardsGovernment?.[index]?.awardsorProjects && errors.awardsGovernment?.[index]?.awardsorProjects.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? removeAwardsBtn(index) : <></> }} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>

                                            </TableContainer>
                                        ) : (
                                            <div className='nodata_tag'>
                                                <p>Awards or government projects related to this idea is empty</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>



                        </Grid>

                        <div className="form-group text-right mt-3 ">
                            <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save</span>
                                    </LoadingButton>
                                ) : (<></>)
                            }

                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default MarketCompetitorsScst