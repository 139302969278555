import { Autocomplete, Button, Divider, FormControl, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Navigate } from "react-router-dom";
import DataContext from "../../context/DataContext";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import apiService from "../../api/apiService";

const ScsthubDashboard = () => {

    const { navigator, setPageLoading, yearListApi, yearListValue, setSelectedYear } = useContext(DataContext)
    const [chartData, setChartData] = useState([]);
    const [investorsListValue, setInvestorListValue] = useState([]);
    const [selectedItem, setSelectedItem] = useState('All');
    const [yearName, setYearName] = useState(2024);
    const [monthName, setMonthName] = useState(0);
    const [districtName, setDistrictName] = useState("All");

    useDidMountEffect(() => {
        yearListApi()
        districtGet()
        handleSelectYear(yearName)
    }, []);
    useDidMountEffect(() => {

        if (selectedItem) {
            chartDatas();
        } else {
            setChartData([]);
        }

        districtHubGet()
    }, [selectedItem, districtName, yearName]);


    // useDidMountEffect(() => {
    //   if (!selectedItem && investorsListValue.length > 0) {
    //     setSelectedItem(investorsListValue[0].id);
    //   }
    // }, [investorsListValue]);


    const districtGet = () => {
        apiService('admin/hub/get', "", "get").then(
            (res) => {
                setPageLoading(false)
                if (res) {
                    if (res && res.data.hubs) {
                        var hub = res.data.hubs
                        hub.unshift('All')
                        setInvestorListValue(hub)
                    }
                }
            }
        );
    }

    const [cityList, setCityList] = useState([])
    const districtHubGet = () => { 
        apiService(`admin/hub/district/get?hub=${selectedItem}`, "", "get").then(
            (res) => {
                setPageLoading(false)
                if (res) {
                    if (res && res.data && res.data.districts) {
                        var dis = res.data.districts
                        dis.unshift('All')
                        setCityList(dis)
                    }
                }
            }
        );
    }


    const chartDatas = () => {
        setChartData([])
        setPageLoading(true)
        var req = {
            hub: selectedItem,
            district: districtName,
            year: yearName,
        }
        apiService('admin/scst/dashboard', req, "post")
            .then((result) => {
                setPageLoading(false)
                if (result) {
                    setChartData(result.data);
                }

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const options = {
        animationEnabled: true,
        // title: {
        //   text: "StartUps",
        // },
        subtitles: [
            {
                text: "Startup",
                verticalAlign: "center",
                fontSize: 34,
                dockInsidePlotArea: true,
            },
        ],
        data: [
            {
                type: "doughnut",
                showInLegend: false,
                indexLabel: "{name}: {y}",
                yValueFormatString: "#,###''",
                dataPoints: chartData,
                // click: (e) => {
                //     partnerId();
                // }
            },

        ],

    };

    const handleChartClick = (item) => {
        setSelectedItem(item);
        setDistrictName('All')
    };

    // const partnerId = () => {
    //     navigator(`tanseed/partner/${btoa(selectedItem)}`)
    // }

    let month = [
        'All', 'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]
    const [monthList, setMonthList] = useState(month)

    const [selectedMonth, setSelectedMonth] = useState(monthList[0])
    const handleSelectMonth = (e, value) => {
        const index = monthList.indexOf(value);
        setSelectedMonth(value)
        setMonthName(index)

    }


    const handleSelectYear = (value) => {
        let year = new Date().getFullYear()
        const currentMonthIndex = new Date().getMonth()
        setYearName(value)
        setSelectedYear(value)
        let yearValue
        if (typeof value === 'string') {
            setMonthName(0)
            setSelectedMonth(monthList[0])
            yearValue = Number(value)
        } else {
            yearValue = value
        }
        if (year === yearValue) {
            let valPush = []
            for (let i = 0; i <= currentMonthIndex + 1; i++) {
                var element = monthList[i]
                valPush.push(element)
            }
            setMonthList(valPush)
        } else {
            setMonthList(month)
        }
    }

    const handleSelectCity = (value) => {
        setDistrictName(value);
    };


    return (
        <section className="">
            <div className="container-fluid">
                <div className="form_div chart">

                    <div className="collapse show mt-3 mb-3" id="collapseWidthExample">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disableClearable
                                            disablePortal
                                            value={districtName}
                                            options={cityList}
                                            onChange={(e, selectedOptions) =>
                                                handleSelectCity(selectedOptions)
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} label="Startup District" fullWidth />
                                            )}
                                        />
                                    </FormControl>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className="form-group">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disableClearable
                                            disablePortal
                                            value={yearName}
                                            options={yearListValue}
                                            onChange={(e, selectedOptions) =>
                                                handleSelectYear(selectedOptions)
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} label="Year" fullWidth />
                                            )}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <h2>Scst Hub</h2> */}
                    <Grid
                        container
                        direction="row"
                        // justifyContent="space-evenly"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                        >

                            <div className="form_divs charts-filter">

                                <List>
                                    {investorsListValue &&
                                        investorsListValue.map((item, index) => (
                                            <div>
                                                <ListItem
                                                    key={index}
                                                    className={
                                                        selectedItem === item ? "active-chart" : ""
                                                    }
                                                >
                                                    <ListItemButton sx={{ padding: '0px 5px' }}>
                                                        <ListItemText
                                                            onClick={() => handleChartClick(item)}
                                                        >
                                                            {item}
                                                        </ListItemText>
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider variant="middle" />
                                            </div>
                                        ))}
                                </List>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={8}
                            lg={8}
                        // sx={{ paddingTop: '60px !important' }}
                        >

                            <div
                                className="canvas"
                                style={{ position: "relative", width: '100%' }}
                            >
                                {/* <CanvasJSChart options={options} /> */}
                                <div className="row">
                                    <div className='col-lg-3'></div>
                                    <div className='col-lg-6 col-12 px-5' >
                                        <div className='total_star c-pointer'>
                                            <div className='d-flex align-items-center'>
                                                <img src='/images/handshake.png' alt='' className='star_logo' />
                                                <h6 className='font-weight-bold ml-lg-3 ml-md-3'>Total Startup</h6>
                                                <div className='star_details ml-auto text-center'>
                                                    <h5 className='font-weight-bold'>{chartData.totalStartups}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'></div>
                                    <div className='col-lg-4' >
                                        <div className='total_star c-pointer'>
                                            <div className=''>
                                                <h5 className='font-weight-bold'>Validation</h5>
                                                <Divider />
                                                <div className="my-3">
                                                    <h6 className="d-flex align-items-center mb-3">Pending <span className="ml-auto"><b>{chartData.validationPending}</b></span></h6>
                                                    <h6 className="d-flex align-items-center mb-3">Approved<span className="ml-auto"><b>{chartData.validationApproved}</b></span></h6>
                                                    <h6 className="d-flex align-items-center mb-3">Rejected<span className="ml-auto"><b>{chartData.validationRejected}</b></span></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12' >
                                        <div className='total_star c-pointer'>
                                            <div className=''>
                                                <h5 className='font-weight-bold'>Evaluation</h5>
                                                <Divider />
                                                <div className="my-3">
                                                    <h6 className="d-flex align-items-center mb-3">Pending <span className="ml-auto"><b>{chartData.evaluationPending}</b></span></h6>
                                                    <h6 className="d-flex align-items-center mb-3">BDD <span className="ml-auto"><b>{chartData.evaluationBDD}</b></span></h6>
                                                    <h6 className="d-flex align-items-center mb-3">MentorShip <span className="ml-auto"><b>{chartData.evaluationMentoring}</b></span></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12' >
                                        <div className='total_star c-pointer'>
                                            <div className=''>
                                                <h5 className='font-weight-bold'>BDD</h5>
                                                <Divider />
                                                <div className="my-3">
                                                    <h6 className="d-flex align-items-center mb-3">Level 1 <span className="ml-auto"><b>{chartData.bddLevel1}</b></span></h6>
                                                    <h6 className="d-flex align-items-center mb-3">Level 2 <span className="ml-auto"><b>{chartData.bddLevel2}</b></span></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="text-center">
                                <Button variant="contained"
                                    className="btn btn-primary submit px-3" onClick={() => { partnerId() }}>More Details
                                </Button>
                            </div> */}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </section>
    )
}

export default ScsthubDashboard