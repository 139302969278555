import { Button, Divider, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import CanvasJSReact from "@canvasjs/react-charts";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Navigate } from "react-router-dom";
import DataContext from "../../../context/DataContext";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Tanfundjurydashboard = () => {
    const { navigator, setPageLoading } = useContext(DataContext)
    const [chartData, setChartData] = useState([]);
    const [investorsListValue, setInvestorListValue] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
  
    useDidMountEffect(() => {
      investerListApi();
  
    }, []);
    useDidMountEffect(() => {
  
      if (selectedItem) {
        chartDatas();
      } else {
  
        setChartData([]);
      }
    }, [selectedItem]);
  
  
    // useDidMountEffect(() => {
    //   if (!selectedItem && investorsListValue.length > 0) {
    //     setSelectedItem(investorsListValue[0].id);
    //   }
    // }, [investorsListValue]);
  
  
  
    const investerListApi = () => {
      setPageLoading(true)
      apiService(`admin/tanseed/associated/juryname/list`, "", "get").then(
        (res) => {
          setPageLoading(false)
          if (res) {
  
            if (res && res.data && res.data.tanseedAppliedDeatils) {
              setInvestorListValue(res.data.tanseedAppliedDeatils);
              if (!selectedItem && res.data.tanseedAppliedDeatils.length > 0) {
                setSelectedItem(res.data.tanseedAppliedDeatils[0].id);
              }
            }
          }
        }
      );
    };
  
    const chartDatas = () => {
      setChartData([])
      setPageLoading(true)
      apiService(`admin/tanseed/jury/dashboard?id=${selectedItem}`, "", "get")
        .then((result) => {
          setPageLoading(false)
          if (result) {
            setChartData(result.data);
          }
  
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    const options = {
      animationEnabled: true,
      // title: {
      //   text: "StartUps",
      // },
      subtitles: [
        {
          text: "Startup",
          verticalAlign: "center",
          fontSize: 34,
          dockInsidePlotArea: true,
        },
      ],
      data: [
        {
          type: "doughnut",
          showInLegend: false,
          indexLabel: "{name}: {y}",
          yValueFormatString: "#,###''",
          dataPoints: chartData,
          click: (e) => {
            partnerId();
          }
        },
  
      ],
  
    };
  
    const handleChartClick = (id) => {
      setSelectedItem(id);
    };
    const partnerId = () => {
      navigator(`tanseed/juries/${btoa(selectedItem)}`)
    }
  return (
    <div>
       
        <section className="">
        <div className="container-fluid">
          <div className="form_div chart">
            <h2 >Juries</h2>
            <Grid
              container
              direction="row"
              // justifyContent="space-evenly"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
              >

                <div className="form_divs charts-filter">

                  <List>
                    {investorsListValue &&
                      investorsListValue.map((item, index) => (
                        <div>
                          <ListItem
                            key={index}
                            className={
                              selectedItem === item.id ? "active-chart" : ""
                            }
                          >
                            <ListItemButton sx={{ padding: '0px 5px' }}>
                              <ListItemText
                                onClick={() => handleChartClick(item.id)}
                              >
                                {item.name}
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                          <Divider variant="middle" />
                        </div>
                      ))}
                  </List>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
              // sx={{ paddingTop: '60px !important' }}
              >
                <div
                  className="canvas"
                  style={{ position: "relative", width: '100%' }}
                >
                  {/* <CanvasJSChart options={options} /> */}
                  <div className="row">
                    <div className='col-lg-3'></div>
                    <div className='col-lg-6 col-12 px-5' >
                      <div className='total_star c-pointer'>
                        <div className='d-flex align-items-center'>
                          <img src='/images/handshake.png' alt='' className='star_logo' />
                          <h6 className='font-weight-bold ml-lg-3 ml-md-3'>Total Startup Assigned</h6>
                          <div className='star_details ml-auto text-center'>
                            <h5 className='font-weight-bold'>{chartData.tsa}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-3'></div>
                    <div className='col-lg-4' >
                      <div className='total_star c-pointer'>
                        <div className=''>
                          <h5 className='font-weight-bold'>Round 1</h5>
                          <Divider />
                          <div className="my-3">
                            <h6 className="d-flex align-items-center mb-3">Evaluated <span className="ml-auto"><b>{chartData.r1c}</b></span></h6>
                            <h6 className="d-flex align-items-center mb-3">Not Evaluated <span className="ml-auto"><b>{chartData.r1np}</b></span></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-12' >
                      <div className='total_star c-pointer'>
                        <div className=''>
                          <h5 className='font-weight-bold'>Round 2</h5>
                          <Divider />
                          <div className="my-3">
                            <h6 className="d-flex align-items-center mb-3">Evaluated <span className="ml-auto"><b>{chartData.r2c}</b></span></h6>
                            <h6 className="d-flex align-items-center mb-3">Not Evaluated <span className="ml-auto"><b>{chartData.r2np}</b></span></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-12' >
                      <div className='total_star c-pointer'>
                        <div className=''>
                          <h5 className='font-weight-bold'>Round 3</h5>
                          <Divider />
                          <div className="my-3">
                            <h6 className="d-flex align-items-center mb-3">Evaluated <span className="ml-auto"><b>{chartData.r3c}</b></span></h6>
                            <h6 className="d-flex align-items-center mb-3">Not Evaluated <span className="ml-auto"><b>{chartData.r3np}</b></span></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <Button variant="contained"
                    className="btn btn-primary submit px-3" onClick={() => { partnerId() }}>More Details</Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Tanfundjurydashboard
