import React, { useContext, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete, Button, Fab, FormHelperText, styled, Switch, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import notifyService from '../../../api/notifySerivce';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { MuiFileInput } from 'mui-file-input';
import { Link } from 'react-router-dom';
import PreviewIcon from "@mui/icons-material/Preview";



const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255,255,255,.35)"
                : "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },
}));

const BusinessStrategyScst = () => {
    const showAddInfo = localStorage.getItem("showInfo")
    const [formData, setFormData] = useState({});
    const { navigator, handleBackapp, setPageLoading } = useContext(DataContext)
    const { handleSubmit, setValue, watch, clearErrors, control, formState: { errors } } = useForm({ values: formData });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [loading, setLoading] = useState(false);

    useDidMountEffect(() => {
        // eslint-disable-next-line
        (async () => await getApi())()
        dropdownValueApi()
    }, [])
    const [businessModelsData, setBusinessModelsData] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=startup`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            setBusinessModelsData(val.businessModels)
        })
    }
    const getApi = async () => {
        setPageLoading(true)
        await apiService(`startup/businessstrategy/get`, '', 'get').then((res) => {
            if (res) {
                setPageLoading(false)
                setFormData({})
                let response = res.data
                if (response.id !== 0) {

                    setFormData(response)
                    if (response.editForm === false) {
                        setReadyOnlyValue(true)
                    }
                    if (response.overviweDocument) {
                        setDocumentValue(response.overviweDocument);
                        setDocumentName(response.overviweDocument);
                        setDocumentURL(response.overviweDocumentName);
                        setCheckMethod("update");
                    }
                } else {
                    setFormData(response)
                }
            }
        })
    }
    const [inCorporationValue, setInCorporationValue] = useState(null);
    const [inCorporationName, setInCorporationName] = useState(null);
    const [inCorporationURL, setInCorporationURL] = useState(null);

    const [documentValue, setDocumentValue] = useState(null);
    const [documentName, setDocumentName] = useState(null);
    const [documentURL, setDocumentURL] = useState(null);

    const [checkMethod, setCheckMethod] = useState("save");
    const inputRef = useRef(null);
    let logoselecetdFile = "";
    const handleFileUpload = (
        event,
        filekey,
        fileName,
        setdata,
        setName,
        setUrl,
        format
    ) => {
        if (event !== null) {
            let logoselectedFile;
            if (event.target === undefined) {
                logoselectedFile = event;
            } else {
                logoselectedFile = event.target.files[0];
            }
            if (logoselectedFile) {
                const fileSizeLimit = 5 * 1024 * 1024; // 5 MB limit
                if (logoselectedFile.size > fileSizeLimit) {
                    // File size exceeds limit, show error message
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        "Please upload a file smaller than 5 MB."
                    );
                    return; // Exit function
                }
                var reader = new FileReader();
                var imagetype = logoselectedFile.type;
                var imagedatatype = imagetype.split("/");
                var img_crt_type = imagedatatype[1];
                if (
                    (format === "file" &&
                        (img_crt_type === "jpeg" ||
                            img_crt_type === "jpg" ||
                            img_crt_type === "png" ||
                            img_crt_type === "pdf")) ||
                    (format === "image" &&
                        (img_crt_type === "jpeg" ||
                            img_crt_type === "jpg" ||
                            img_crt_type === "png"))
                ) {
                    setValue(filekey, "");
                    setValue(fileName, "");
                    setName("");
                    setUrl("");
                    if (event.target === undefined) {
                        setdata(event);
                    } else {
                        setdata(event.target.files[0]);
                    }
                    var fileValue = logoselectedFile;
                    reader.readAsDataURL(logoselectedFile);
                    reader.onload = () => {
                        var logourl1 = reader.result;
                        var spl = logourl1.split(",");
                        var ImageValue = spl[1];
                        var img_name = fileValue.name;
                        setUrl(logourl1);
                        setValue(filekey, ImageValue);
                        setValue(fileName, img_name);
                        setName(img_name);
                        clearErrors(filekey);
                        clearErrors(fileName);
                        // if (filekey === "logo") {
                        //     setLogoInvalid(false);
                        // }
                    };
                } else {
                    notifyService(
                        "danger",
                        "File Format Invalid",
                        "Please check your file format."
                    );
                }
            }
        } else {
            // Handle case when event is null (optional)
        }
    };


    // const [businessModelsValue, setBusinessModelsValue] = useState('');
    // const handleSelectBusiness = (value) => {
    //     // setBusinessModelsValue(value);
    //     setValue('businessModel', value)
    //     clearErrors('businessModel');
    // }
    const onSubmit = (data) => {
        if (readyOnlyValue === false) {
            setPageLoading(true);
            var getform = data
            debugger
            apiService('startup/businessstrategy/save', getform, 'post').then((res) => {
                setPageLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        // navigator('/startup/traction-achievements')
                        if (showAddInfo === "true") {
                            navigator("/startup/market&competitors/SCST")
                        }
                    }
                }
            })
        }
    };
    const existingCustomerPresent = watch("existingCustomerPresent", false);
    const handleFilter = (isExistingCustomerPresent) => {
        setValue("existingCustomerPresent", isExistingCustomerPresent);
        if (!isExistingCustomerPresent) {
            setValue("existingCustomerPresent", "");
        }
    };

    const deliveryOrOneTimeSalespart = watch("deliveryOrOneTimeSalespart", false);
    const handleFilter1 = (isDeliveryOrOneTimeSalespart) => {
        setValue("deliveryOrOneTimeSalespart", isDeliveryOrOneTimeSalespart);
        if (!isDeliveryOrOneTimeSalespart) {
            setValue("deliveryOrOneTimeSalespart", "");
        }
    };

    const manufactureComponents = watch("manufactureComponents", false);
    const handleFilter2 = (isManufactureComponents) => {
        setValue("manufactureComponents", isManufactureComponents);
        if (!isManufactureComponents) {
            setValue("manufactureComponents", "");
        }
    };

    const lettersFromPotentialCustomers = watch("lettersFromPotentialCustomers", false);
    const handleFilter3 = (isLettersFromPotentialCustomers) => {
        setValue("lettersFromPotentialCustomers", isLettersFromPotentialCustomers);
        if (!isLettersFromPotentialCustomers) {
            setValue("lettersFromPotentialCustomers", "");
        }
    };

    const handleSelectBusiness = (value) => {
        setValue(`businessModel`, value);
        clearErrors(`businessModel`);
    }

    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className='d-flex align-items-center'>
                        <span className='back_span' onClick={handleBackapp}><img src='/images/Fig-back.png' className='img-fluid' alt='' /></span> <h6 className='ml-2 mb-0'>Business Strategy</h6>
                    </div>
                    <hr />
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='d-none'>
                                <div className="form-group">
                                    <Controller name="id" control={control} defaultValue="0"
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="businessModel" control={control} defaultValue="" rules={{ required: 'Business Model is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                options={businessModelsData}
                                                onChange={(e, selectedOptions) => handleSelectBusiness(selectedOptions)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="What is your business Model? *" />
                                                )}
                                                disabled={readyOnlyValue}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.businessModel && errors.businessModel.message}</FormHelperText>
                                </div>
                            </div>

                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="briefProposedSolution" control={control} defaultValue="" rules={{ required: 'Proposed Solution is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="What is the proposed Solution? *"
                                                placeholder='Enter Proposed Solution'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.briefProposedSolution && errors.briefProposedSolution.message}</FormHelperText>
                                </div>
                            </div>

                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="problemAffectCustomers" control={control} defaultValue="" rules={{ required: 'Problem affect customers is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="How does this problem affect customers? *"
                                                placeholder='Enter Problem affect customers'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.problemAffectCustomers && errors.problemAffectCustomers.message}</FormHelperText>
                                </div>
                            </div>

                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="briefProblemStatement" control={control} defaultValue="" rules={{ required: 'Problem statement is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="What is the problem statement being addressed? *"
                                                placeholder='Enter problem statement'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.briefProblemStatement && errors.briefProblemStatement.message}</FormHelperText>
                                </div>
                            </div>

                            {/* <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="problemIdentified" control={control} defaultValue="" rules={{ required: 'Problem identified is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="How was this problem identified? *"
                                                placeholder='Enter Problem identified'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.problemIdentified && errors.problemIdentified.message}</FormHelperText>
                                </div>
                            </div> */}

                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="validationPurposes" control={control} defaultValue="" rules={{ required: 'Customer contact details is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Can you provide customer contact details for validation purposes? *"
                                                placeholder='Enter customer contact details'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.validationPurposes && errors.validationPurposes.message}</FormHelperText>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-12 mb-3 ">
                                {checkMethod === "save" ? (
                                    <div className="form-group ">
                                        <Controller
                                            name="overviweDocument"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required:
                                                    false,
                                            }}
                                            render={({ field }) => (
                                                <MuiFileInput
                                                    {...field}
                                                    onChange={(e) =>
                                                        handleFileUpload(
                                                            e,
                                                            "overviweDocument",
                                                            "overviweDocumentName",
                                                            setDocumentValue,
                                                            setDocumentName,
                                                            setDocumentURL,
                                                            "file"
                                                        )
                                                    }
                                                    value={documentValue}
                                                    variant="outlined"
                                                    // inputProps={}
                                                    // onReset={(e) => handleremove(e)}
                                                    inputRef={inputRef}
                                                    // inputProps= {
                                                    //   ref=
                                                    // }

                                                    fullWidth
                                                    label="Can you provide a solution overview document?"
                                                    placeholder="Choose File"
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.overviweDocument && errors.overviweDocument.message}
                                        </FormHelperText>
                                        <div>
                                            <span className="fn-12">
                                                Maximum 5 mb allowed doc (png, jpg, jpeg, pdf){" "}
                                                <span className="text-danger">*</span>
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <p className="mb-0">
                                            Can you provide a solution overview document?
                                        </p>
                                        <div className="row">
                                            <div className="col-lg-7 col-12 my-3">
                                                <Controller
                                                    name="overviweDocument"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required:
                                                            "Solution overview document is required",
                                                    }}
                                                    render={({ field }) => (
                                                        <Button
                                                            component="label"
                                                            variant="contained"
                                                            {...field}
                                                            fullWidth
                                                            onChange={(e) =>
                                                                handleFileUpload(
                                                                    e,
                                                                    "overviweDocument",
                                                                    "overviweDocumentName",
                                                                    setDocumentValue,
                                                                    setDocumentName,
                                                                    setDocumentURL,
                                                                    "file"
                                                                )
                                                            }
                                                            startIcon={<CloudUploadIcon />}
                                                            href="#file-upload"
                                                            disabled={readyOnlyValue}
                                                        >
                                                            Change
                                                            {readyOnlyValue === false ? (
                                                                <VisuallyHiddenInput type="file" />
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Button>
                                                    )}
                                                />
                                                <div>
                                                    <span className="fn-12">
                                                        Maximum 5 mb allowed doc (png, jpg, jpeg, pdf){" "}
                                                        <span className="text-danger">*</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-12">
                                                <Fab variant="extended">
                                                    <Link
                                                        to={`${documentURL}`}
                                                        target="_blank"
                                                        color="black"
                                                    >
                                                        <PreviewIcon sx={{ mr: 1 }} />
                                                        View Document
                                                    </Link>
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div> */}

                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="customerAcquisitionTimeline" control={control} defaultValue="" rules={{ required: 'Customer acquisition timeline is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="What's your estimated customer acquisition timeline? *"
                                                placeholder='Enter Customer acquisition timeline'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.customerAcquisitionTimeline && errors.customerAcquisitionTimeline.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="unitCosts" control={control} defaultValue="" rules={{ required: 'Unit costs is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="What are the unit costs for your product/service? *"
                                                placeholder='Enter unit costs'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.unitCosts && errors.unitCosts.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    {/* <Controller name="serviceProduct" control={control} defaultValue="" rules={{ required: 'Delivery Model is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Are contracts for service/product delivery or one-time sales part of your model? *"
                                                placeholder='Enter delivery Model'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.serviceProduct && errors.serviceProduct.message}</FormHelperText> */}
                                    <div className="form-group mt-2">
                                        <div className="d-flex">
                                            <span className="mt-1">Are contracts for service/product delivery or one-time sales part of your model?</span>
                                            <span className="ml-2 d-flex justify-content-center align-items-center">
                                                No{" "}
                                                {/* <Switch
                                                    checked={deliveryOrOneTimeSalespart}
                                                    disabled={readyOnlyValue}
                                                    onChange={() => handleFilter1(!deliveryOrOneTimeSalespart)}
                                                    color="primary"
                                                /> */}
                                                <AntSwitch className="mx-2"
                                                    checked={deliveryOrOneTimeSalespart}
                                                    disabled={readyOnlyValue}
                                                    onChange={() => handleFilter1(!deliveryOrOneTimeSalespart)}
                                                    inputProps={{ 'aria-label': 'ant design' }} />
                                                {" "}
                                                Yes{" "}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                {/* <div className="form-group mt-2">
                                    <Controller name="contractManufacturing" control={control} defaultValue="" rules={{ required: 'Contract manufacturing is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Do you manufacture components in-house or use contract manufacturing? *"
                                                placeholder='Enter contract manufacturing'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.contractManufacturing && errors.contractManufacturing.message}</FormHelperText>
                                </div> */}
                                <div className="form-group mt-2">
                                    <div className="d-flex">
                                        <span className="mt-1">Do you manufacture components in-house or use contract manufacturing?</span>
                                        <span className="ml-2 d-flex justify-content-center align-items-center">
                                            No{" "}
                                            {/* <Switch
                                                checked={manufactureComponents}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter2(!manufactureComponents)}
                                                color="primary"
                                            /> */}
                                            <AntSwitch className="mx-2"
                                                checked={manufactureComponents}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter2(!manufactureComponents)}
                                                inputProps={{ 'aria-label': 'ant design' }} />
                                            {" "}
                                            Yes{" "}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                {/* <div className="form-group mt-2">
                                    <Controller name="lettersOfIntent" control={control} defaultValue="" rules={{ required: 'Letters of Intent is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Have you secured any Letters of Intent from potential customers? *"
                                                placeholder='Enter Letters of Intent'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.lettersOfIntent && errors.lettersOfIntent.message}</FormHelperText>
                                </div> */}
                                <div className="form-group mt-2">
                                    <div className="d-flex">
                                        <span className="mt-1">Have you secured any Letters of Intent from potential customers?</span>
                                        <span className="ml-2 d-flex justify-content-center align-items-center">
                                            No{" "}
                                            {/* <Switch
                                                checked={lettersFromPotentialCustomers}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter3(!lettersFromPotentialCustomers)}
                                                color="primary"
                                            /> */}
                                            <AntSwitch className="mx-2"
                                                checked={lettersFromPotentialCustomers}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter3(!lettersFromPotentialCustomers)}
                                                inputProps={{ 'aria-label': 'ant design' }} />
                                            {" "}
                                            Yes{" "}
                                        </span>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-12 mb-3 pl-3">
                                <div className="form-group mt-2">
                                    <div className="d-flex">
                                        <span className="mt-1">Do you have existing customers?</span>
                                        <span className="ml-4 d-flex justify-content-center align-items-center">
                                            No{" "}
                                            {/* <Switch
                                                checked={existingCustomerPresent}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter(!existingCustomerPresent)}
                                                color="primary"
                                            /> */}
                                            <AntSwitch className="mx-2"
                                                checked={existingCustomerPresent}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter(!existingCustomerPresent)}
                                                inputProps={{ 'aria-label': 'ant design' }} />
                                            {" "}
                                            Yes{" "}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {existingCustomerPresent === true && (
                                <>
                                    <div className="col-lg-4 col-12">
                                        <div className="form-group mt-2">
                                            <Controller
                                                name="customerDetails"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: "Name & Location is required",
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        variant="outlined"
                                                        label="Name & Location *"
                                                        placeholder="Enter Name & Location"
                                                        fullWidth
                                                        multiline
                                                        rows={3}
                                                        inputProps={{ readOnly: readyOnlyValue }}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.customerDetails && errors.customerDetails.message}
                                            </FormHelperText>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-4 col-12">
                                    <div className="form-group mt-2">
                                        <Controller
                                            name="location"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Location is required",
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="Location *"
                                                    placeholder="Enter location"
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    inputProps={{ readOnly: readyOnlyValue }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.location && errors.location.message}
                                        </FormHelperText>
                                    </div>
                                </div> */}
                                </>
                            )}

                        </div>


                        <div className="form-group text-right">
                            <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </LoadingButton>
                                ) : (<></>)
                            }

                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default BusinessStrategyScst