import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Modal from '@mui/material/Modal';
import Drawer from "@mui/material/Drawer";
import {
  Autocomplete,
  Checkbox,
  Container,
  FormControl,
  List,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import apiService from "../../api/apiService";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
// import DataContext from "../../context/DataContext";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import notifyService from "../../api/notifySerivce";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Remarksmodal from "./Remarksmodal";
// import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { useTheme } from '@mui/material/styles';
import { useEffect } from "react";



const getMaxMarks = (question) => {
  const match = question.match(/\((\d+) Marks\)/);
  return match ? parseInt(match[1], 10) : null;
};



const getModalStyle = (isMdScreen) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMdScreen ? '40%' : '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
});

const Scoremodal = ({ idNumber, path, partnerOrInvestorId, viewStartupDetails }) => {
  const [open, setOpen] = React.useState(false);
  // const { modalstyle } = useContext(DataContext)
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const [formData, setFormData] = useState([]);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = useForm({ values: formData });
  const { fields: questionsFields } = useFieldArray({
    control,
    name: "startupQuestions",
  });
  const [lblData, setLblData] = useState({});
  // const [index, setIndex] = useState();
  // useDidMountEffect(() => {}, []) 
  let index
  const getApi = (paths) => {

    if (paths === "validation") {
      index = 0
    } else if (paths === "jury/scst") {
      index = 1
    }
    setOpen(true);
    if (path === 'jury/scst') {
      var req = {
        "partnerOrInvestorId": partnerOrInvestorId,
        "startupId": idNumber
      }
      apiService(`jury/startup/rounds/get`, req, "post")
        .then((result) => {
          getResp(result, index)
        })
        .catch((err) => { });
    } else {
      apiService(`partner/startup/rounds/get?startupid=${idNumber}`, "", "get")
        .then((result) => {
          getResp(result, index)
        })
        .catch((err) => { });
    }

  };
  const getResp = (result, index) => {
    if (result) {
      if (result.data) {
        result.data.startupRounds[index].startupQuestions.forEach(
          (element) => {
            if (element.score === 0) {
              element.score = "";
            }
          }
        );
        for (
          let i = 0;
          i < result.data.startupRounds[index].startupQuestions.length;
          i++
        ) {
          var element =
            result.data.startupRounds[index].startupQuestions[i];
          if (element.answer === "Yes") {
            setApproveStatus(false);
            break;
          }
        }
        for (
          let i = 0;
          i < result.data.startupRounds[index].startupQuestions.length;
          i++
        ) {
          var element =
            result.data.startupRounds[index].startupQuestions[i];
          // if (element.answer === "No") {
          //   setRemarksStatus(true);
          //   break;
          // }
        }
        setFormData(result.data.startupRounds[index]);
        if (result.data.startupRounds[index].remarks) {
          setComment(result.data.startupRounds[index].remarks);
        }
      }
    }
  }
  const [approveStatus, setApproveStatus] = useState(false);
  // const [remarksStatus, setRemarksStatus] = useState(false);

  const checkApproveStatus = () => {
    const answers = [
      getValues(`startupQuestions.1.answer`),
      getValues(`startupQuestions.2.answer`),
      getValues(`startupQuestions.3.answer`),
      getValues(`startupQuestions.4.answer`)
    ];
    const showApproveButton = !answers.some(answer => answer !== "Yes");
    setApproveStatus(showApproveButton);
  };


  const handleCheck = (index, value) => {
    setValue(`startupQuestions.${index}.answer`, value);
    clearErrors(`startupQuestions.${index}.answer`);

    checkApproveStatus();

    // if (value === "Yes") {
    //   setApproveStatus(false);
    // }

    // if (value === "No") {
    //   setRemarksStatus(true);
    // }
  };

  useEffect(() => {
    checkApproveStatus();
  }, [getValues()]);


  const [openRemark, setOpenRemark] = useState(false)
  const [remarkIndex, setRemarkIndex] = useState()
  const [remarkValue, setRemarkValue] = useState('')
  const handleOpenRemark = (index) => {
    setOpenRemark(true)
    setRemarkIndex(index)
    var data = getValues(`startupQuestions.${index}.remarks`)
    setRemarkValue(data)
  }
  const handleCloseRemark = (index, value) => {
    setOpenRemark(false)
    setValue(`startupQuestions.${index}.remarks`, value)
    clearErrors(`startupQuestions.${index}.remarks`)

  }
  const [comment, setComment] = useState("");
  const handleComment = (value) => {
    setValue("remarks", value);
    clearErrors("remarks");
    setComment(value);
  };
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    // setLoading(true);
    // console.log(data);    
    var val = {
      startupRounds: [data],
    };

    let url
    if (path === 'jury/scst') {
      url = 'jury/startup/round/answers/save'
    } else {
      url = 'partner/startup/round/answers/save'
    }
    let remarkStatus
    if (data.status === 2) {
      remarkStatus = true
    } else {
      remarkStatus = false
      clearErrors('remarks')
    }
    if (remarkStatus === true && data.remarks !== '') {
      saveApi(url, val)
    } else if (remarkStatus === false) {
      saveApi(url, val)
    } else if (remarkStatus === true) {
      setError('remarks', { message: 'Remark is required' })
    }
    // apiService(url, val, "post")
    //   .then((res) => {
    //     setLoading(false);
    //     if (res) {
    //       if (res.data.responseStatus === "Success") {
    //         notifyService("success", "Success", "Saved successfully !!");
    //         toggleClose();
    //       }
    //     }
    //   })
    //   .catch((err) => { });
  };
  const saveApi = (url, data) => {
    debugger
    setLoading(true);
    apiService(url, data, "post")
      .then((res) => {
        setLoading(false);
        if (res) {
          if (res.data.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            toggleClose();
          }
        }
      })
      .catch((err) => { });
  }
  const toggleClose = () => {
    reset();
    setFormData([])
    setOpen(false);
    viewStartupDetails(idNumber, partnerOrInvestorId)
  };


  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up('md'));

  const modalStyle = getModalStyle(isMdScreen);


  const DrawerList = (
    <Box sx={{ width: 350 }} role="presentation">
      <div>
        <div className="full-div">
          {/* <div></div> */}
          {/* <h4 className="mb-4">
            <b>Rate your Score here</b>
          </h4> */}
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            {questionsFields.length > 0 ?
              (
                <>
                  <div className="qus_div">
                    {
                      questionsFields.map((value, index) => {
                        const maxMarks = getMaxMarks(value.question);
                        return (
                          <>
                            <div key={value.id} className="form-group mb-5">
                              <p>
                                <b className="remark-font">
                                  {index + 1}. {value.question}
                                </b>
                              </p>
                              {value.questionType === "yesno" ? (
                                <div className="d-flex align-items-center justify-content-center w-100">
                                  <Controller
                                    name={`startupQuestions.${index}.answer`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Answer is required" }}
                                    render={({ field }) => (
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-form-control-label-placement"
                                        name="position"
                                        {...field}
                                      >
                                        <div className="d-flex align-items-center justify-content-center w-100 main_toggle">
                                          <div
                                            className={
                                              getValues(
                                                `startupQuestions.${index}.answer`
                                              ) === "Yes"
                                                ? "customs_toggle toggleActive"
                                                : "customs_toggle"
                                            }
                                            onClick={() => {
                                              formData.status === 0 &&
                                                handleCheck(index, "Yes");
                                            }}
                                          >
                                            Yes
                                          </div>
                                          <div
                                            className={
                                              getValues(
                                                `startupQuestions.${index}.answer`
                                              ) === "No"
                                                ? "customs_toggle toggleActive"
                                                : "customs_toggle"
                                            }
                                            onClick={() => {
                                              formData.status === 0 &&
                                                handleCheck(index, "No");
                                            }}
                                          >
                                            No
                                          </div>
                                        </div>
                                      </RadioGroup>
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupQuestions?.[index]?.answer &&
                                      errors.startupQuestions?.[index]?.answer.message}
                                  </FormHelperText>
                                </div>
                              ) : (
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>
                                    <Controller
                                      name={`startupQuestions.${index}.score`}
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                        required: "Score is required",
                                        validate: {
                                          max: (value) => {
                                            const numValue = parseInt(value, 10);
                                            return maxMarks ? numValue <= maxMarks || `Score cannot exceed ${maxMarks}` : true;
                                          },
                                        },
                                      }}
                                      render={({ field }) => (
                                        <TextField
                                          variant="outlined"
                                          label="Score *"
                                          placeholder="Enter Score"
                                          fullWidth
                                          {...field}
                                          type="number"
                                          inputProps={{
                                            readOnly: formData.status !== 0 && true,
                                          }}
                                          onWheel={(event) =>
                                            event.currentTarget
                                              .querySelector("input")
                                              ?.blur()
                                          }
                                          onChange={(event) => {
                                            const value = event.target.value;
                                            if (value === '') {
                                              setValue(`startupQuestions.${index}.score`, ''); 
                                            } else {
                                              setValue(`startupQuestions.${index}.score`, Math.min(maxMarks, value));
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.startupQuestions?.[index]?.score &&
                                        errors.startupQuestions?.[index]?.score.message}
                                    </FormHelperText>
                                  </div>
                                  {
                                    path === 'jury/scst' && (
                                      <div>
                                        <Controller
                                          name={`startupQuestions.${index}.remarks`}
                                          control={control}
                                          defaultValue=""
                                          rules={{ required: "Remarks is required" }}
                                          render={({ field }) => (
                                            <Button variant="outlined" onClick={() => handleOpenRemark(index)}>Remarks</Button>
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.startupQuestions?.[index]?.remarks &&
                                            errors.startupQuestions?.[index]?.remarks.message}
                                        </FormHelperText>

                                      </div>
                                    )
                                  }
                                </div>
                              )}
                            </div>
                          </>
                        )
                      })
                    }
                  </div>
                  {
                    path !== 'jury/scst' && (
                      <>
                        {
                          formData.status === 0 ? (
                            <div>
                              <Controller
                                name={`remarks`}
                                control={control}
                                defaultValue=""
                                rules={{ required: false }}
                                render={({ field }) => (
                                  <TextField
                                    id="filled-textarea"
                                    label="Comment"
                                    placeholder="Add a comment"
                                    multiline
                                    variant="filled"
                                    {...field}
                                    fullWidth
                                    rows={4}
                                    value={comment}
                                    onInput={(e) => handleComment(e.target.value)}
                                    inputProps={{ readOnly: formData.status !== 0 && true }}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.remarks &&
                                  "Remark is required"}
                              </FormHelperText>
                            </div>
                          ) : (
                            <div>
                              <h6 className="my-4">
                                Remarks : <b>{formData.remarks}</b>
                              </h6>
                            </div>
                          )
                        }
                      </>
                    )
                  }
                </>) : (
                <>
                  <div className="text-center py-4">
                    <h5>Rounds Completed</h5>
                  </div>

                </>
              )}
            {formData.status === 0 ? (
              <>
                {
                  path === 'jury/scst' ? (
                    <div className="form-group text-center my-3">
                      <div className="score-actions">
                        <LoadingButton
                          type="submit"
                          loading={loading}
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          onClick={() => setValue("status", 1)}
                          sx={{
                            padding: "10px 20px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                              backgroundColor: "green",
                            }
                          }}
                        >
                          <span>Recommend to BDD</span>
                        </LoadingButton>
                        <LoadingButton
                          type="submit"
                          loading={loading}
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          onClick={() => setValue("status", 2)}
                          sx={{
                            padding: "10px 20px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                              backgroundColor: "green",
                            }
                          }}
                        >
                          <span>Recommend to Mentorship / Other Schemes</span>
                        </LoadingButton>
                        {/* <LoadingButton
                          type="submit"
                          loading={loading}
                          loadingPosition="start"
                          startIcon={<CloseIcon />}
                          variant="contained"
                          sx={{
                            padding: "10px 20px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                              backgroundColor: "red",
                            }
                          }}

                          onClick={() => setValue("status", 3)}
                        >
                          <span>Reject</span>
                        </LoadingButton> */}
                        <Button
                          variant="contained"
                          onClick={toggleClose}
                          sx={{
                            padding: "10px 23px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                              backgroundColor: "#253b80",
                            }
                          }}
                        >
                          {
                            questionsFields.length > 0 ? ('Cancel') : ('OK')
                          }

                        </Button>
                        {/* <Button
                          variant="contained"
                          sx={{
                            padding: "10px 70px !important", borderRadius: "25px", "&:hover": {
                              backgroundColor: "green",
                            }
                          }}
                        >
                          Recommend to BDD
                        </Button> */}
                        {/* <Button
                          variant="contained"
                          sx={{
                            padding: "10px 40px !important",
                            borderRadius: "25px",
                            marginTop: "20px",
                            "&:hover": {
                              backgroundColor: "green",
                            }
                          }}
                        >
                          Recommend to MentorShip
                        </Button> */}
                      </div>
                    </div>
                  ) : (
                    <div className="form-group text-center my-3">
                      {approveStatus && (
                        <LoadingButton
                          type="submit"
                          loading={loading}
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          onClick={() => setValue("status", 1)}
                          sx={{
                            padding: "10px 20px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                              backgroundColor: "green",
                            }
                          }}
                        >
                          <span>Approve</span>
                        </LoadingButton>
                      )}
                      <LoadingButton
                        type="submit"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<CloseIcon />}
                        variant="contained"
                        sx={{
                          padding: "10px 20px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                            backgroundColor: "red",
                          }
                        }}

                        onClick={() => setValue("status", 2)}
                      >
                        <span>Reject</span>
                      </LoadingButton>

                      {/* <Button variant="contained" onClick={toggleClose} className="btn btn-cancel submit px-3 ml-3">Reject</Button> */}
                      <Button
                        variant="contained"
                        onClick={toggleClose}
                        sx={{
                          padding: "10px 23px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                            backgroundColor: "#253b80 ",
                          }
                        }}
                      >
                        {
                          questionsFields.length > 0 ? ('Cancel') : ('OK')
                        }
                      </Button>
                    </div>
                  )
                }


              </>
            ) : (
              <>
                {

                  path === 'jury/scst' ? (<>
                    {formData.status === 1 && (
                      <h3 className="text-center my-4">
                        <span className="approved_span c-green">
                          <b>Recommend to BDD</b>
                        </span>
                      </h3>
                    )}
                    {formData.status === 2 && (
                      <h3 className="text-center my-4">
                        <span className="rejected_span c-green">
                          <b>Recommend to Mentorship / Other Schemes</b>
                        </span>
                      </h3>
                    )}
                    {/* {formData.status === 3 && (
                      <h3 className="text-center my-4">
                        <span className="rejected_span c-red">
                          <b>Rejected</b>
                        </span>
                      </h3>
                    )} */}
                  </>) : (
                    <>
                      {formData.status === 1 && (
                        <h3 className="text-center my-4">
                          <span className="approved_span c-green">
                            <b>Approved</b>
                          </span>
                        </h3>
                      )}
                      {formData.status === 2 && (
                        <h3 className="text-center my-4">
                          <span className="rejected_span c-red">
                            <b>Rejected</b>
                          </span>
                        </h3>
                      )}
                    </>
                  )
                }

                <div className="text-center mt-3">
                  <Button
                    variant="contained"
                    onClick={toggleClose}
                    sx={{
                      padding: "10px 23px !important", marginRight: '3px', borderRadius: "25px", "&:hover": {
                        backgroundColor: "#253b80 ",
                      }
                    }}
                  >
                    {
                      questionsFields.length > 0 ? ('Cancel') : ('OK')
                    }
                  </Button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
      <Modal
        open={openRemark}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} className="form_tag">
          <Remarksmodal index={remarkIndex} remarkValue={remarkValue} handleCloseRemark={handleCloseRemark} status={formData.status} />
        </Box>
      </Modal>
    </Box >
  );

  return (
    <>
      <div className="button_side">
        <button onClick={() => getApi(path)} className="viewdetails-sur">
          <img src="./images/sml_arrow.png" alt="" className="sml-right" /> Rate
          your Score
          <img src="./images/sml_arrow.png" alt="" className="sml-right" />
        </button>
      </div>

      <Drawer anchor="right" open={open} className="scroe_module">
        {DrawerList}
      </Drawer>
    </>
  );
};

export default Scoremodal;