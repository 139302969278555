import React, { useContext } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './jurynavbar.css'
import { NavLink } from 'react-router-dom';
import DataContext from '../../../context/DataContext';

const Jurynavbar = () => {
  const { scstRemoveFiters } = useContext(DataContext)
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
    }
  };
  return (
    <nav className='box_shadow'>
      <div className='submenu_div'>
        <div className='container-fluid'>
          <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center', marginLeft: '25px', marginRight: '25px' }}>
            {/* <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link"  to="/jury/tanfund" onClick={handleClick}>Tanfund</NavLink>
            </Typography> */}
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/jury/tanseed" onClick={(e) => { handleClick(e); scstRemoveFiters() }}>TANSEED</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/jury/scst" onClick={handleClick}>SC/ST</NavLink>
            </Typography>

            {/* <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link"  to="/jury/zoom" onClick={handleClick}>Zoom Scheduler</NavLink>
            </Typography> */}
          </Box>
        </div>
      </div>
    </nav>
  )
}

export default Jurynavbar