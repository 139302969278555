import React from 'react'
import { Outlet } from 'react-router-dom'
import Scsthubavpnavbar from './Scsthubavpnavbar'
import useDidMountEffect from '../../hooks/useDidMountEffect'

const Scsthubavplayout = ({role}) => {
  localStorage.setItem('regRoleId',8)
  useDidMountEffect(() => {
    document.getElementById('theme').setAttribute('href', '/css/admin.css');
}, [])
  return (
    <div className='admin_main_div'>
      {/* <Scsthubavpnavbar role={role}/> */}
      <Outlet></Outlet>
    </div>
  )
}

export default Scsthubavplayout