import React, { useContext, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { FaCheckCircle } from 'react-icons/fa';
import DataContext from '../../../context/DataContext';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import CommonViewPitchDeck from '../../common-page/CommonViewPitchDeck';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, useMediaQuery } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { Controller, useForm } from 'react-hook-form';
import { useRef } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Viewimage from '../../../api/Viewimage';
import Paper from "@mui/material/Paper";
const ScstBdddetails = () => {
    const { setPageLoading } = useContext(DataContext);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { control, reset, handleSubmit } = useForm({});
    const { navigator } = useContext(DataContext)
    const { startupId } = useParams()
    const { id } = useParams()
    let idNumber = atob(startupId)
    let partnerOrInvestorId = atob(id)

    useDidMountEffect(() => {
        listApi()
        viewStartupDetails(idNumber, partnerOrInvestorId)
    }, [])

    const [listData, setListData] = useState([])
    const listApi = () => {
        setPageLoading(true)
        var req = {
            "startupId": idNumber
        }
        apiService('partner/clarification/list', req, 'post').then((result) => {
            setPageLoading(false)
            if (result) {
                if (result.data) {
                    if (result.data.clarificationDetails) {
                        setListData(result.data.clarificationDetails)
                        var value = result.data.clarificationDetails[0]
                        handleClarification(value.id, 0)
                    }
                }
            }
        }).catch((err) => { });
    }

    const [startupGetData, setStartupGetData] = useState({})
    const [startupValue, setStartupValue] = useState({})
    const viewStartupDetails = async (id, partnerOrInvestorId) => {
        setStartupGetData({})
        setStartupValue({})
        apiService(`partner/scstadmin/startups/get?id=${id}&partnerOrInvestorId=${partnerOrInvestorId}`, '', 'get').then((res) => {
            if (res) {
                if (res.data) {
                    if (res.data.applicationForm) {
                        setStartupGetData(res.data.applicationForm)
                    }
                    setStartupValue(res.data)
                } else {
                    setStartupGetData({})
                }
            }
        })
    }

    const [activeIndex, setActiveIndex] = useState(0);
    const [getData, setGetData] = useState([])
    const [getDataId, setGetDataId] = useState()
    const handleClarification = (id, i) => {
        setGetDataId(id)
        setActiveIndex(i);
        var req = {
            "startupId": idNumber,
            "partnerId": partnerOrInvestorId,
            "sacId": id
        }
        apiService('partner/clarification/get', req, 'post').then((result) => {
            if (result) {
                if (result.data) {
                    if (result.data.clarificationDetails) {
                        setGetData(result.data.clarificationDetails)
                        setHeight()
                    } else {
                        setGetData([]);
                    }
                }
            }
        }).catch((err) => {

        });
    }
    const div1Ref = useRef(null);
    const div2Ref = useRef(null);
    const div3Ref = useRef(null);
    const setHeight = () => {
        const div1Height = div1Ref.current.offsetHeight;
        div2Ref.current.style.height = `${div1Height}px`;
        div3Ref.current.style.height = `${div1Height - 70}px`;
    }
    const onSubmit = (data) => {
        setPageLoading(true)
        var getform = data;
        getform.startupId = idNumber;
        getform.id = getDataId
        apiService("jury/startup/clarification/save", getform, "post")
            .then((result) => {
                setPageLoading(false)
                if (result.data.responseStatus === "Saved Successfully") {
                    reset()
                    handleClarification(getDataId, activeIndex)
                }
            })
            .catch((err) => { });
    }

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const handleClickOpen = (value) => {
        setViewImage(value);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };   
    const [openFounder, setOpenFounder] = useState(false)
    const [founderList, setFounderList] = useState([])
    const handleFounderOpen = (value) => {
        setOpenFounder(true)
        setFounderList(value)
    }
    const handleFounderClose = () => {
        setOpenFounder(false)
        setFounderList([])
    }
    const [openFundRaised, setOpenFundRaised] = useState(false)
    const [fundRaisedList, setFundRaisedList] = useState([])
    const handleFundRaisedOpen = (value) => {
        setOpenFundRaised(true)
        setFundRaisedList(value)
    }
    const handleFundRaisedClose = () => {
        setOpenFundRaised(false)
        setFundRaisedList([])
    }
    const handleBack = () => {
        navigator(`scstbdd/startups/list`)
    }
    return (
        <section>
            <div className="container-fluid">
                <div className="form_div chart">
                    <div className='dflex'>
                        <Button onClick={handleBack}><ArrowBackIcon /></Button>
                        <div className='top-banner'>
                            <img src={startupGetData.startupLogoName} className='img-fluid user_logos' alt='' />
                            <div className='content'>
                                <h3 className='startupname-sur'>{startupValue.startupName}</h3>
                                <span className='black-clr-sur'><img src='/images/phone.png' alt='' className='mr-2' /> {startupValue.startupPhone ? (startupValue.startupPhone) : ('-')}</span>
                                <span className='black-clr-sur ml-3'><img src='/images/email.png' alt='' className='mr-2' /> {startupValue.startupEmail ? (startupValue.startupEmail) : ('-')}</span>
                                <span className='black-clr-sur ml-3'>Member Since: {startupValue.date}</span>
                            </div>
                        </div>
                    </div>
                    <Divider className='my-5' />
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        className='message-box-bg'
                    >
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <div ref={div1Ref}>
                                {
                                    (listData.length) ? (
                                        <>
                                            <div
                                                style={{
                                                    borderRadius: "12px",
                                                    border: '0.5px solid #d9d9d9',
                                                    background: 'white',
                                                    boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px",
                                                    margin: '20px',
                                                    overflowY: 'hide',
                                                    position: 'relative',
                                                    height: '80vh',
                                                    overflowX: 'auto',
                                                }}
                                                ref={div2Ref}
                                            >
                                                {
                                                    listData.map((list, i) => (
                                                        <p className={`api-res m-3 ${activeIndex === i ? 'active' : ''}`} key={i} onClick={() => handleClarification(list.id, i)} >
                                                            <div>
                                                                <div className="team">
                                                                    <p>{list.attribute}</p>
                                                                </div>
                                                                <div>
                                                                    <p className=""><b>{list.value}</b></p>
                                                                </div>
                                                                <div className="text-center">
                                                                    {
                                                                        list.fileUrl && (
                                                                            <span
                                                                                className="viewdetailings"
                                                                                onClick={() => {
                                                                                    handleClickOpen(list.fileUrl);
                                                                                }}
                                                                            >
                                                                                View
                                                                            </span>
                                                                        )
                                                                    }
                                                                    {
                                                                        list.founders && (
                                                                            <span
                                                                                className="viewdetailings"
                                                                                onClick={() => {
                                                                                    handleFounderOpen(list.founders);
                                                                                }}
                                                                            >
                                                                                Founder
                                                                            </span>
                                                                        )
                                                                    }
                                                                    {
                                                                        list.fundraised && (
                                                                            <span
                                                                                className="viewdetailings"
                                                                                onClick={() => {
                                                                                    handleFundRaisedOpen(list.fundraised);
                                                                                }}
                                                                            >
                                                                                Fund Raised
                                                                            </span>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </p>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <p className='listnotfound'>List not found</p>
                                        </>
                                    )
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <div
                                style={{
                                    borderRadius: "12px",
                                    border: '0.5px solid #d9d9d9',
                                    background: 'white',
                                    boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px",
                                    margin: '20px',
                                    height: '80vh',
                                    overflowX: 'auto',
                                    position: 'relative'
                                }}
                                ref={div2Ref}
                            >
                                <div style={{ padding: "24px", overflowY: 'auto' }} ref={div3Ref}>
                                    {
                                        getData.length > 0 ?
                                            getData.map((list, index) => (
                                                <div key={index}>
                                                    {
                                                        (list.role === "Startup") ? (
                                                            <div className="left-mess mt-3">
                                                                <div className="message">
                                                                    <p className="text3">
                                                                        <div>
                                                                            {list.message}
                                                                        </div>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="right-mess">
                                                                    <div className="message1" style={{ marginTop: "25px", display: 'flex', justifyContent: 'flex-end', width: '90%' }}>
                                                                        <div className="text3">
                                                                            {list.message}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            )) : (
                                                <>
                                                    <center><p className='chatnotfound'>No Chat Found</p></center>
                                                </>
                                            )
                                    }

                                </div>
                                <div className="msg_box mt-3">
                                    {
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Box className="message-box">
                                                <Controller
                                                    name="remarks"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            placeholder="Ask Clarification"
                                                            id="fullWidth"
                                                            style={{ background: 'white' }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Button type="submit"><SendIcon /></Button>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        </form>
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
            <Dialog fullWidth={fullScreen} open={openFounder} maxWidth="lg">
                <DialogTitle>Founder Details</DialogTitle>
                <DialogContent>
                    <div className="table_tag">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Education</TableCell>
                                        <TableCell>Experience</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        founderList.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.education ? row.education : `-`}</TableCell>
                                                <TableCell>{row.experience ? row.experience : `-`}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='text-center mb-3 w-100'>
                        <Button onClick={handleFounderClose} variant="outlined" className='form-btn'>Close</Button>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={fullScreen} open={openFundRaised} maxWidth="lg">
                <DialogTitle>Fund Raised Details</DialogTitle>
                <DialogContent>
                    <div className="table_tag">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>Organisation Name</TableCell>
                                        <TableCell>Instrument Type</TableCell>
                                        <TableCell>Fund Raised</TableCell>
                                        <TableCell>External Funding</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        fundRaisedList.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.organisationName ? row.organisationName : `-`}</TableCell>
                                                <TableCell>{row.instrumentType ? row.instrumentType : `-`}</TableCell>
                                                <TableCell>{row.fundRaised ? row.fundRaised : `-`}</TableCell>
                                                <TableCell>{row.externalFunding ? row.externalFunding : `-`}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='text-center mb-3 w-100'>
                        <Button onClick={handleFundRaisedClose} variant="outlined" className='form-btn'>Close</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </section>
    )
}

export default ScstBdddetails