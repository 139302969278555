import React, { useContext, useEffect, useState } from 'react'
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Autocomplete, FormControl, Button, TextField, Select, MenuItem, InputLabel, NativeSelect, InputBase } from "@mui/material";
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { styled } from '@mui/system';
import { styled, alpha } from '@mui/material/styles';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import DataContext from '../../../context/DataContext';
import SearchIcon from '@mui/icons-material/Search';
const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    '& .MuiCalendarPicker-root': {
        '& .MuiPickersCalendarHeader-label': {
            // Additional styling for month and year selection if needed
        },
    },
}));

const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '17ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const OnBoradFilter = ({ setPage, districtName, setDistrictName, scsthub, setScsthub, yearName, setYearName, monthName, setMonthName, manualName, setManualName, path, setSearchInputValue, searchInputValue, statusValue, setStatusValue, lblName }) => {

    const { yearListApi, yearListValue, setSelectedYear, scstRemoveFiters } = useContext(DataContext);
    const [cityList, setCityList] = useState([]);
    const [hubList, setHubList] = useState([]);
    var localPath = localStorage.getItem('localPath')
    useDidMountEffect(() => {

        if (localPath !== path) {
            debugger
            handleClear()
            localStorage.setItem('localPath', path)
        } else {
            localStorage.setItem('localPath', path)
        }
    }, [path])
    useDidMountEffect(() => {
        yearListApi()
        getCityApi();
        getScstHub();
        // handleSelectYear(yearName)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInputValue]);
    const getCityApi = async () => {
        var role = localStorage.getItem('role')
        let url
        if (role === 'SCSTHUB') {
            url = 'admin/district/get'
        } else {
            url = `admin/dropdownvalues/get?key=district`
        }
        await apiService(url, "", "get").then(
            (res) => {
                if (role === 'SCSTHUB') {
                    setCityList(res.data);
                } else {
                    var val = res.data.dropDownValues;
                    setCityList(val.districts);
                }

            }
        );
    };
    const getScstHub = async () => {
        await apiService(`partner/hub/filter`, "", "get")
            .then((res) => {
                setHubList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleSelectCity = (value) => {
        setDistrictName(value);
        localStorage.setItem('districtNameScst', value)
        localStorage.setItem('pageScst', 0)
        setPage(0)
    };

    const handleScstHub = (value) => {
        setScsthub(value);
        localStorage.setItem('hubScst', value)
        localStorage.setItem('pageScst', 0)
    };
    let month = [
        'All', 'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]
    const [monthList, setMonthList] = useState(month)
    const handleSelectYear = (value) => {
        let year = new Date().getFullYear()
        const currentMonthIndex = new Date().getMonth()
        setYearName(value)
        setSelectedYear(value)
        localStorage.setItem('yearNameScst', value)
        localStorage.setItem('pageScst', 0)
        setPage(0)
        let yearValue
        if (typeof value === 'string') {
            setMonthName(0)
            setSelectedMonth(monthList[0])
            yearValue = Number(value)
        } else {
            yearValue = value
            setSelectedMonth(monthList[monthName])

        }
        if (year === yearValue) {
            let valPush = []
            for (let i = 0; i <= currentMonthIndex + 1; i++) {
                var element = monthList[i]
                valPush.push(element)
            }
            setMonthList(valPush)
        } else {
            setMonthList(month)
        }
    }
    const [selectedMonth, setSelectedMonth] = useState(monthList[0])
    const handleSelectMonth = (e, value) => {
        const index = monthList.indexOf(value);
        setSelectedMonth(value)
        setMonthName(index)
        localStorage.setItem('monthNameScst', index)
        localStorage.setItem('pageScst', 0)
        setPage(0)

    }
    const handleMenualFiter = (value) => {
        setManualName(value)
        localStorage.setItem('manualNameScst', value)
        localStorage.setItem('pageScst', 0)
        setPage(0)
    }

    const handleStatusFiter = (value) => {
        setStatusValue(value)
        localStorage.setItem('statusValueScst', value)
        localStorage.setItem('pageScst', 0)
        setPage(0)
    }

    const handleClear = () => {
        setDistrictName("");
        setScsthub("");
        setMonthName(0)
        setSelectedMonth('All')
        setYearName(2022)
        setManualName(0)
        scstRemoveFiters()
        if (path === 'evaluation') {
            setStatusValue(1)
        } else {
            setStatusValue(0)
        }

    };

    const handleSearch = (searchValue) => {
        setSearchInputValue(searchValue)
        localStorage.setItem('searchInputValueScst', searchValue)
        localStorage.setItem('pageScst', 0)
        setPage(0)
    }
    return (
        <div>

            <div className="header_tag">
                <h5 className='mb-0'>{lblName}</h5>
                <div className="ml-auto d-flex align-items-center">
                    <SearchinputDiv sx={{ display: { xs: 'none', sm: 'block' } }} onChange={(e) => handleSearch(e.target.value)}>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search by Name"
                            type="search"
                            inputProps={{ 'aria-label': 'Search by Name' }}
                        />
                    </SearchinputDiv>
                    <Button
                        className='ml-3'
                        variant="outlined"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseWidthExample"
                    >
                        <FilterAltIcon />
                        Filters
                    </Button>
                </div>
            </div>
            <div className='d-flex'>
                {/* {
                    (path !== 'jury/scst') && (
                        <div className=" my-2">
                            <Button
                                variant="outlined"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseWidthExample"
                            >
                                <FilterAltIcon />
                                Filters
                            </Button>
                        </div>
                    )
                } */}
                <div className="ml-auto my-2">

                </div>
            </div>
            {/* filter code */}
            <div className="collapse show mt-3 mb-3" id="collapseWidthExample">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-2">
                        <div className="form-group">
                            <FormControl fullWidth>
                                <Autocomplete
                                    disableClearable
                                    disablePortal
                                    value={districtName}
                                    options={cityList}
                                    onChange={(e, selectedOptions) =>
                                        handleSelectCity(selectedOptions)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Startup District" fullWidth />
                                    )}
                                />
                            </FormControl>
                        </div>
                    </div>
                    {
                        (path !== 'validation' && path !== 'evaluation') && (
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disableClearable
                                            disablePortal
                                            value={scsthub}
                                            options={hubList}
                                            onChange={(e, selectedOptions) =>
                                                handleScstHub(selectedOptions)
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} label="Hub" fullWidth />
                                            )}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        )
                    }
                    <div className="col-lg-2">
                        <div className="form-group">
                            <FormControl fullWidth>
                                <Autocomplete
                                    disableClearable
                                    disablePortal
                                    value={yearName}
                                    options={yearListValue}
                                    onChange={(e, selectedOptions) =>
                                        handleSelectYear(selectedOptions)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Year" fullWidth />
                                    )}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-group">
                            <FormControl fullWidth>
                                <Autocomplete
                                    disableClearable
                                    disablePortal
                                    value={selectedMonth}
                                    options={monthList}
                                    onChange={(e, selectedOptions) =>
                                        handleSelectMonth(e, selectedOptions)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Month" fullWidth />
                                    )}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-group">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-filled-label">Onboarded Type</InputLabel>
                                <Select

                                    onChange={(e) => handleMenualFiter(e.target.value)}
                                    value={manualName}
                                >
                                    <MenuItem value={0}>Both</MenuItem>
                                    <MenuItem value={1}>Through Manual</MenuItem>
                                    <MenuItem value={2}>Through Portal</MenuItem>
                                </Select>


                            </FormControl>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-group">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
                                <Select

                                    onChange={(e) => handleStatusFiter(e.target.value)}
                                    value={statusValue}
                                >
                                    {
                                        (path !== 'jury/scst' && path !== 'evaluation') && (
                                            <MenuItem value={0}>Pending </MenuItem>
                                        )
                                    }
                                    {
                                        (path === 'evaluation') ? (
                                            <MenuItem value={1}>Pending </MenuItem>
                                        ) : (
                                            <MenuItem value={1}>Approved </MenuItem>
                                        )
                                    }

                                    {
                                        (path !== 'jury/scst' && path !== 'evaluation') && (
                                            <MenuItem value={2}>Rejected </MenuItem>
                                        )
                                    }
                                    {
                                        (path === 'jury/scst' || path === 'evaluation') && (
                                            <MenuItem value={3}>Recommended to BDD</MenuItem>
                                        )
                                    }
                                    {
                                        (path === 'jury/scst' || path === 'evaluation') && (
                                            <MenuItem value={4}>Recommend to Mentorship / Other Schemes</MenuItem>
                                        )
                                    }
                                </Select>

                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className="mt-3 mb-3">
                    <center>
                        <Button
                            variant="contained"
                            sx={{ marginRight: "10px" }}
                            onClick={() => handleClear()}
                        // data-toggle="collapse"
                        // data-target="#collapseWidthExample"
                        >
                            Clear
                        </Button>
                        {/* <Button variant="contained" onClick={handleFilter}>
                            Filter
                        </Button> */}
                    </center>
                </div>
            </div>
        </div >
    )
}

export default OnBoradFilter