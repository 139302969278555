import React, { useEffect, useContext, useState } from "react";
import {
  Autocomplete, Checkbox,
  // Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
import apiService from "../../../api/apiService";
// import { LoadingButton } from "@mui/lab";
// import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import downloadExcel from "../../../hooks/downloadExcel";
// import { Link } from "react-router-dom";
import DataContext from "../../../context/DataContext";
// import Reviewupdate from '../../partner-module/startup-page/startup-list-page/Reviewupdate';
// import Reviewupdate from './Reviewupdate';
// import AddCommentIcon from "@mui/icons-material/AddComment";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
// import Associatedwith from "./Associatedwith";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Controller, useForm } from "react-hook-form";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import notifyService from "../../../api/notifySerivce";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Link, useParams } from "react-router-dom";
import CustomLink from "../../common-page/CustomLink";
// import PanalRoundJuryList from "./PanalRoundJuryList";
import PanalRoundJuryList from "../tanseed-startups-page/PanalRoundJuryList";
import { LoadingButton } from "@mui/lab";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import downloadExcel from "../../../hooks/downloadExcel";
import CancelIcon from '@mui/icons-material/Cancel';


import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "17ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
const Partnersjurylist = () => {
  const { id } = useParams()
  let ids = atob(id)
  const { navigator, tableIndexValue, setPageLoading, icon, checkedIcon, tanseedJuryRemoveFilter } = useContext(DataContext);
  const [count, setCount] = useState(0);

  var pageNumberPartners = Number(localStorage.getItem('pageNumberJury'))
  const [page, setPage] = useState(pageNumberPartners ? pageNumberPartners : 0);

  var listSizePartners = Number(localStorage.getItem('listSizeJury'))
  const [rowsPerPage, setRowsPerPage] = useState(listSizePartners ? listSizePartners : 10);

  var roundPartners = Number(localStorage.getItem('roundJury'))
  const [round, setRound] = useState(roundPartners ? roundPartners : 1);

  var startupCategory = localStorage.getItem('startupCategoryJury')
  const [startupCategoryValue, setStartupCategoryValue] = useState(startupCategory ? startupCategory : '')

  var sectors = JSON.parse(localStorage.getItem('sectorsJury'))
  const [preferredSectors, setPreferredSectors] = useState(sectors ? sectors : []);

  var partnerValueIdJury = Number(localStorage.getItem('partnerValueIdJury'))
  const [partnerValueId, setPartnerValueID] = useState(partnerValueIdJury ? partnerValueIdJury : "")

  var searchInputValuePartners = localStorage.getItem('searchInputValueJury')
  const [searchInputValue, setSearchInputValue] = useState(searchInputValuePartners ? searchInputValuePartners : '')

  const [partnerValue, setPartnerValue] = useState("")
  const [listData, setListData] = useState([])
  const { control, setValue, clearErrors, getValues, reset } = useForm({});
  const [startupCategoryData, setStartupCategoryData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const [rounds, setRounds] = useState('')
  const [panelId, setPanelId] = useState()

  useDidMountEffect(() => {
    listApi()
  }, [page, rowsPerPage, round, startupCategoryValue, preferredSectors, partnerValueId, searchInputValue])
  useDidMountEffect(() => {
    investerListApi();
    dropdownValueApi()
  }, [])
  const handleSearch = (searchValue) => {
    setSearchInputValue(searchValue);
    localStorage.setItem('searchInputValueJury', searchValue)
  };
  const listApi = () => {
    setPageLoading(true)
    setListData([])
    setRounds('')
    setPanelId()
    setCount(0)
    var req = {
      round: round,
      listSize: rowsPerPage,
      pageNumber: page + 1,
      juryId: ids,
      startupCategory: startupCategoryValue,
      sectors: preferredSectors,
      investorId: partnerValueId,
      searchString: searchInputValue,
    }
    apiService('admin/tanseed/jury/startups/round/list', req, "post")
      .then((result) => {
        setPageLoading(false)
        if (result) {
          if (result.data.startups) {
            setPanelId(result.data.panelRoundId)
            setListData(result.data.startups)
            setCount(result.data.count)
            setRounds(result.data.round)

          }
        }

      }).catch((err) => {
        console.log(err);
      });
  }
  const handleChangeround = (event) => {
    setRound(event.target.value);
    localStorage.setItem('roundJury', event.target.value)
    localStorage.setItem('pageNumberJury', 0)
    setPage(0);
  };
  const handleClear = () => {
    setRound(1);
    setPreferredSectors([]);
    setStartupCategoryValue("");
    setPage(0);
    reset()
    setPartnerValue('')
    setPartnerValueID('')
    tanseedJuryRemoveFilter()

  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem('pageNumberJury', newPage)
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('listSizeJury', parseInt(event.target.value, 10))
    localStorage.setItem('pageNumberJury', 0)
    setPage(0);
  };
  const handleBackapp = () => {
    navigator(`tanseed/juries`)
    tanseedJuryRemoveFilter()
  }
  const [openView, setOpenView] = useState(false);
  const [remarksValue, setRemarksValue] = useState('')
  const handleViewOpen = (value) => {
    setOpenView(true);
    if (value) {
      setRemarksValue(value)
    }

  };
  const handleViewClose = () => {
    setOpenView(false);
  };
  const handleSelectStartupCategory = (value) => {
    setStartupCategoryValue(value)
    localStorage.setItem('startupCategoryJury', value)
    localStorage.setItem('pageNumberJury', 0)
    setPage(0);
  };
  const handleSelectPartner = (selectedOption) => {
    setPartnerValue(selectedOption)
    const selectedPartner = investorsListValue.find(option => option.name === selectedOption);
    if (selectedPartner) {
      console.log('Selected Partner ID:', selectedPartner.id);
      console.log('Selected Partner Name:', selectedPartner.name);
      setPartnerValueID(selectedPartner.id);
      localStorage.setItem('partnerValueIdJury', selectedPartner.id)
    }
    localStorage.setItem('pageNumberJury', 0)
    setPage(0);
  }
  const handleSelectSector = (value) => {
    setPreferredSectors(value);
    localStorage.setItem('sectorsJury', JSON.stringify(value))
    localStorage.setItem('pageNumberJury', 0)
    setPage(0);
  };
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup_min`, "", "get")
      .then((res) => {
        var val = res.data.dropDownValues;
        setSectorData(val.sectors);

        setStartupCategoryData(val.startupCategory);
      })
      .catch((error) => {
        console.error("Error fetching dropdown values:", error);
        // Handle error if needed
      });
  };

  const exportExcel = () => {
    setPageLoading(true)
    var req = {
      download: 'Yes',
      round: round,
      listSize: rowsPerPage,
      pageNumber: page + 1,
      investorId: ids,
      startupCategory: startupCategoryValue,
      sectors: preferredSectors,
      partnerOrInvestorId: partnerValueId
    }
    apiService('admin/tanseed/startups/round/list', req, "downloadpost").then((res) => {
      setPageLoading(false)
      if (res) {
        if (res.data) {
          downloadExcel(res.data, 'Tanseed_Startup_')
        }
      }
    }).catch((err) => {

    });
  }

  const [category, setCategory] = useState([])
  const [openWeightedScore, setOpenWeightedScore] = React.useState(false);
  const handleWeightedscore = (id) => {
    console.log(id, "ids")
    setOpenWeightedScore(true);
    setPageLoading(true)
    var req = {
      "panelroundId": panelId,
      "startupId": id,
    }
    apiService('partner/jury/weightedscore', req, 'post').then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.category) {
          setCategory(res.data.category)
        }
      }
    })
  };
  const handleWeightedscoreClose = () => {
    setOpenWeightedScore(false);
  };
  const [investorsListValue, setInvestorListValue] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const investerListApi = () => {
    setPageLoading(true)
    apiService(`admin/tanseed/associated/investorname/list`, "", "get").then(
      (res) => {
        setPageLoading(false)
        if (res) {

          if (res && res.data && res.data.tanseedAppliedDeatils) {
            setInvestorListValue(res.data.tanseedAppliedDeatils);
            var partnerValueIdJury = Number(localStorage.getItem('partnerValueIdJury'))
            if (partnerValueIdJury) {
              setPartnerValueID(partnerValueIdJury ? partnerValueIdJury : '')
              const selectedPartner = res.data.tanseedAppliedDeatils.find(option => option.id === partnerValueIdJury);
              debugger
              setPartnerValue(selectedPartner.name)
            }
            if (!selectedItem && res.data.tanseedAppliedDeatils.length > 0) {
              setSelectedItem(res.data.tanseedAppliedDeatils[0].id);
            }

          }
        }
      }
    );
  };
  return (
    <section className="">
      <div className="container-fluid">
        <div className="form_div chart">
          <div className="header_tag ">
            <h5 className="d-flex align-item-center"> <span className="back_span mr-3" onClick={handleBackapp}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>TANSEED Startups</h5>
            <div className="ml-3 my-2">
              <Button
                variant="outlined"
                type="button"
                data-toggle="collapse"
                data-target="#collapseWidthExample"
                className="mr-3"
              >
                {" "}
                <FilterAltIcon />
                Filters
              </Button>
              {/* <Button
                startIcon={<CloudDownloadIcon />}
                variant="outlined"
                onClick={exportExcel}
              >
                <span>EXPORT EXCEL</span>
              </Button> */}
            </div>

          </div>
          <div className="collapse show mb-4" id="collapseWidthExample">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-3">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      options={investorsListValue.map((option) => option.name)}
                      value={partnerValue}
                      onChange={(e, selectedOptions) =>
                        handleSelectPartner(selectedOptions)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Partner" />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">Round</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={round}
                      label="Round"
                      onChange={handleChangeround}
                    >
                      <MenuItem value={1}>Round 1 Evaluated</MenuItem>
                      <MenuItem value={2}>Round 2 Evaluated</MenuItem>
                      <MenuItem value={3}>Round 3 Evaluated</MenuItem>
                      <MenuItem value={4}>Round 1 Not Evaluated</MenuItem>
                      <MenuItem value={5}>Round 2 Not Evaluated</MenuItem>
                      <MenuItem value={6}>Round 3 Not Evaluated</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel id="demo-simple-select-label">Category</InputLabel> */}
                    <Autocomplete
                      disableClearable
                      disablePortal
                      options={startupCategoryData}
                      value={startupCategoryValue}
                      onChange={(e, selectedOptions) =>
                        handleSelectStartupCategory(selectedOptions)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Category" />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel id="demo-simple-select-label">Sectors</InputLabel> */}
                    <Autocomplete
                      disableClearable
                      disablePortal
                      multiple
                      limitTags={2}
                      value={preferredSectors}
                      options={sectorData}
                      onChange={(e, selectedOptions) =>
                        handleSelectSector(selectedOptions)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Sectors" />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                    />
                    <FormHelperText className="fnt-sm c-blue">
                      Select one or more
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
            </div>
            <center>
              <Button
                variant="contained"
                sx={{ marginRight: "10px" }}
                onClick={() => handleClear()}
                // data-toggle="collapse"
                // data-target="#collapseWidthExample"
              >
                Clear
              </Button>
              {/* <Button
                startIcon={<CloudDownloadIcon />}
                variant="outlined"
                onClick={exportExcel}
              >
                <span>EXPORT EXCEL</span>
              </Button> */}
            </center>
          </div>
          <div className="d-flex mb-3">
            <SearchinputDiv
              sx={{ display: { xs: "none", sm: "block" } }}
              onChange={(e) => handleSearch(e.target.value)}
            >
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                value={searchInputValue}
                type="search"
                placeholder="Search with Name"
                inputProps={{ "aria-label": "search" }}
              />
            </SearchinputDiv>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <div className="table_tag">
              {listData.length ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>No.</TableCell>
                        <TableCell>Startup Name</TableCell>
                        {/* <TableCell className="text-center">Assigned Juries</TableCell> */}

                        {/* <TableCell>Current Round</TableCell> */}
                        <TableCell>Category</TableCell>
                        <TableCell>Sector</TableCell>
                        <TableCell className="text-center">Remarks </TableCell>
                        {/* {
                            rounds !== 1 && rounds !== 4 && (
                              <TableCell align='center'>Weighted Mean</TableCell>
                            )
                          } */}
                        <TableCell className="text-center">Average Score</TableCell>
                        <TableCell className="text-center">Recommended</TableCell>
                        {/* {
                            rounds !== 1 && rounds !== 4 && (
                              <>
                                <TableCell align='center'>Weighted Score</TableCell>
                                <TableCell align='center'>Weighted Rank</TableCell>
                              </>
                            )
                          } */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listData.map((list, index) => (
                        <TableRow key={index}>
                          <TableCell >
                            {tableIndexValue(rowsPerPage, page, index)}
                          </TableCell>
                          <TableCell >
                            <CustomLink to={`/tanseed/juries/details/${btoa(list.startupId)}/${btoa(ids)}/${btoa(list.partnerOrInvestorId)}`}>{list.startupName}</CustomLink>
                            {/* {list.startupName} */}
                          </TableCell>
                          {/* <TableCell className="text-center">
                                  {list.totalJury}
                                </TableCell> */}

                          {/* <TableCell >
                                  {list.currentRound}
                                </TableCell> */}
                          <TableCell >
                            {list.category}
                          </TableCell>
                          <TableCell >
                            {list.sector}
                          </TableCell>
                          <TableCell className="text-center c-pointer view-btn" >
                            {
                              list.remarks ? (<button onClick={() => handleViewOpen(list.remarks)}> <b>view</b></button>) : (`-`)
                            }
                          </TableCell>
                          {/* {
                              rounds !== 1 && rounds !== 4 && (
                                <TableCell align='center'>{list.weightedMean}</TableCell>
                              )
                            } */}
                          {/* onClick={() => handleViewOpen(list.jury)} */}
                          <TableCell className="text-center">
                            <span className="" >{list.score}</span>
                          </TableCell>
                          <TableCell className="text-center">{list.status}</TableCell>
                          {/* {
                              rounds !== 1 && rounds !== 4 && (
                                <>
                                  <TableCell align='center' >
                                    <Link className="">{list.weightedScore}</Link> <br/>
                                    <span style={{ color: list.categoryJuries === 6 ? '#388e3c' : '#F55A00' }}>
                                      {
                                        list.categoryJuries > 0 && (
                                          <>
                                            {' '}
                                            (<strong>{list.categoryJuries}</strong> Category Jury Filled)
                                          </>
                                        )
                                      }
                                    </span>
                                  </TableCell>
                                  <TableCell align='center' >{list.weightedRank}</TableCell>
                                </>
                              )
                            } */}
                        </TableRow>
                      ))}
                    </TableBody>
                    <caption>
                      <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        style={{ float: 'left' }}
                      />
                    </caption>
                  </Table>
                </TableContainer>
              ) : (
                <div className="nodata_tag">
                  <img
                    src="/images/Tanfundlogo-black-1.png"
                    className="no_data_logo"
                    alt=""
                  />
                  <h4>
                    A Startups will be assigned as soon as the criteria
                    matches
                  </h4>
                </div>
              )}
            </div>
          </Box>
        </div>
      </div>
      <Dialog open={openView} maxWidth="md">
        {/* <DialogTitle id="responsive-dialog-title">
          Juries <div className='mt-3 close-icon'><span onClick={handleViewClose}><CancelIcon /></span></div>
        </DialogTitle> */}
        <DialogContent className="text-center p-3">
          <p>{remarksValue}</p>
          <div className='p-3'>
            <Button className='m-3' onClick={handleViewClose} variant="outlined">
              OK
            </Button>
          </div>
        </DialogContent>
        {/* <PanalRoundJuryList juryList={juryList} handleViewClose={handleViewClose} /> */}
      </Dialog>

      <Dialog
        open={openWeightedScore}
        onClose={handleWeightedscoreClose}
        minWidth={'lg'}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {
          category.length ? (
            <>
              <div className='mt-3 close-icon'><span onClick={handleWeightedscoreClose}><CancelIcon /></span></div>
              <TableContainer component={Paper} className='p-3'>
                <Table aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Category</TableCell>
                      <TableCell>Juries</TableCell>
                      <TableCell>Weighted Score</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      category && category.map((list, index) => (
                        <TableRow key={index}>
                          <TableCell>{list.name}</TableCell>
                          <TableCell>{list.juries}</TableCell>
                          <TableCell>{list.weightedScore}</TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
              <div className='my-3 close-icon'><span onClick={handleWeightedscoreClose}><CancelIcon /></span></div>
              <div className='nodata_tag'>
                <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                <h4>No Data Found</h4>
              </div>
            </>
          )
        }
      </Dialog>


    </section>
  );
};

export default Partnersjurylist
