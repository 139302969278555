import React, { useContext, useState } from 'react'
import { Box, Button, Container, Drawer, Tab } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DataContext from '../../context/DataContext';
import { Link, useParams } from 'react-router-dom';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import apiService from '../../api/apiService';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Scsttab from './Scsttab';
import { Stack } from '@mui/material';
import Validatetab from './Validatetab';
import Scoremodal from './Scoremodal'
// import Startuplistfilter from './Startuplistfilter';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Startuplistfilter from '../investor-module/associated-startups-page/associated-details-page/Startuplistfilter';
import { FaCheckCircle } from 'react-icons/fa';
import Viewimage from '../../api/Viewimage';
import CommonViewPitchDeck from '../common-page/CommonViewPitchDeck';
const ScstDetails = ({ path }) => {
    const { navigator } = useContext(DataContext)
    const handleBack = () => {
        navigator(`/scst/startups/${path}`)
    }
    const [startupGetData, setStartupGetData] = useState({})
    const { id } = useParams()
    const { startupId } = useParams()
    let idNumber = atob(startupId)
    let partnerOrInvestorId = atob(id)
    useDidMountEffect(() => {
        // eslint-disable-next-line
        viewStartupDetails(idNumber, partnerOrInvestorId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [startupValue, setStartupValue] = useState({})
    const viewStartupDetails = async () => {
        setNavTabValue('1')
        setStartupGetData({})
        setStartupValue({})
        apiService(`partner/startups/get?id=${idNumber}`, '', 'get').then((res) => {
            if (res) {
                if (res.data) {
                    if (res.data.applicationForm) {
                        setStartupGetData(res.data.applicationForm)
                    }
                    setStartupValue(res.data)

                    // if (res.data.showRounds === true) {
                    //     roundPartnerList()
                    // }
                } else {
                    setStartupGetData({})
                }
            }
        })
    }
    const [navTabValue, setNavTabValue] = useState('1');
    const handleNavTabChange = (event, newValue) => {
        setNavTabValue(newValue);
    };
    const [openList, setOpenList] = React.useState(false);
    const handleStartupList = () => {
        setOpenList(true);
    }

    const handleClose = (value) => {
        setOpenList(false);
    };

    const isRegistered = startupGetData.dpiitRegistered;

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const handleClickOpen = (value) => {
        setViewImage(value);
        setOpen(true);
    };
    const handleClose1 = () => {
        setOpen(false);
    };

    return (
        <div className='box-show'>
            <section className='bg-white'>
                <div className='row'>
                    <div className='col-lg-2 p-0'>
                        <div className='box-line'>
                            <Startuplistfilter path={path} startupName={startupValue.startupName} viewStartupDetails={viewStartupDetails} handleClose={handleClose} />
                        </div>
                    </div>
                    <div className='col-lg-10 p-0'>
                        <div className='p-relative'>
                            <div className='p-3'>
                                {/* <div className='header_tag pb-0 d-flex start_aligin_center'>                                    
                                    <h5><Button onClick={handleBack}><ArrowBackIcon /></Button> Startup Details <Button variant="outlined" sx={{ display: { sm: 'block', lg: 'none', marginLeft: '10px' } }} onClick={handleStartupList}>
                                        <FilterAltOffIcon />
                                    </Button></h5>
                                </div>
                                <hr /> */}
                                {/* <div className='row'>
                            <div className='col-lg-4 col-12'>
                                <div className='d-flex'>
                                    <label className='mr-3'>Startup Name :</label>
                                    <p className=''><b>{startupValue.startupName}</b></p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className='d-flex'>
                                    <label className='mr-3'>Email Id :</label>
                                    <p className=''><b>{startupValue.startupEmail ? (startupValue.startupEmail) : ('-')}</b></p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className='d-flex'>
                                    <label className='mr-3'>Phone Number :</label>
                                    <p className=''><b>{startupValue.startupPhone ? (startupValue.startupPhone) : ('-')}</b></p>
                                </div>
                            </div>
                        </div> */}
                                <div className='dflex'>
                                    <div className='d-flex align-items-center'>
                                        <div><Button onClick={handleBack}><ArrowBackIcon /></Button></div>
                                        <img src={startupGetData.startupLogoName} className='img-fluid user_logos' alt='' />
                                        <div className='content'>
                                            <h3 className='startupname-sur'>{startupValue.startupName}</h3>
                                            <span className='black-clr-sur'><img src='./images/phone.png' alt='' className='mr-2' /> {startupValue.startupPhone ? (startupValue.startupPhone) : ('-')}</span>
                                            <span className='black-clr-sur ml-3'><img src='./images/email.png' alt='' className='mr-2' /> {startupValue.startupEmail ? (startupValue.startupEmail) : ('-')}</span>
                                            <span className='black-clr-sur ml-3'>Member Since:{startupValue.date}</span>
                                        </div>
                                    </div>

                                    <div className='ml-auto mr-2'>
                                        <p className='dflex'>
                                            {/* <b>{startupValue.startupName}</b> */}
                                            <div
                                                className={`dpiit-button ${isRegistered ? 'registered' : 'not-registered'}`}
                                            >
                                                {isRegistered && <FaCheckCircle className="icon" />}
                                                {isRegistered ? 'DPIIT REGISTERED' : 'Non DPIIT REGISTERED'}
                                            </div>
                                        </p>
                                        {/* {details.financialProjectionsName && ( */}
                                        <div className="d-flex">
                                            <div className="ml-auto text-right">
                                                <p className='mb-3'>
                                                    <span
                                                        className="viewdetails-sur"
                                                        onClick={() => {
                                                            handleClickOpen(startupGetData.pitchDeck);
                                                        }}
                                                    >
                                                        View PitchDeck
                                                    </span>
                                                </p>

                                                {
                                                    path === 'validation' ? (
                                                        <Scoremodal idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} />
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        {/* )} */}
                                    </div>
                                </div>
                                {/* <div className='header_tag'>
                                    <span className='d-flex'><img src={startupGetData.startupLogoName} className='img-fluid user_logos' alt='' /><h3 className='startupname'>{startupValue.startupName}</h3></span>
                                    <span className='black-clr'><img src='./images/phone.png' alt='' className='mr-2' /> {startupValue.startupPhone ? (startupValue.startupPhone) : ('-')}</span>
                                    <span className='black-clr'><img src='./images/email.png' alt='' className='mr-2' /> {startupValue.startupEmail ? (startupValue.startupEmail) : ('-')}</span>
                                    <span className='social-datas'>
                                        <Stack className='social-Stack' direction="row" spacing={1}>
                                            {(startupGetData.linkedIn) && (
                                                <Link to={`${startupGetData.linkedIn}`} target="_blank">
                                                    <img src='./images/linkedin.png' alt='LinkedIn' className='linkedinicon' />
                                                </Link>
                                            )}
                                            {(startupGetData.website) && (
                                                <Link to={`${startupGetData.website}`} target="_blank">
                                                    <img src='./images/globe.png' alt='Website' className='websiteicon' />
                                                </Link>
                                            )}
                                        </Stack>
                                    </span>
                                </div> */}
                                <hr />
                                <Scsttab startupGetData={startupGetData} startupValue={startupValue} idNumber={idNumber} viewStartupDetails={viewStartupDetails} />
                                {/* <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={navTabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleNavTabChange} aria-label="lab API tabs example">
                                        <Tab label="Startups" value="1" />
                                        <Tab label="Validate" value="2" />
                                        
                                    </TabList>
                                </Box>
                                <TabPanel value="1" sx={{ padding: '10px 0px' }}>
                                    <Scsttab startupGetData={startupGetData} startupValue={startupValue} idNumber={idNumber} />
                                </TabPanel>
                                
                                <TabPanel value="2" sx={{ padding: '10px 0px' }}>
                                    <Validatetab />
                                </TabPanel>
                            </TabContext>
                        </Box> */}
                            </div>
                            {/* <Scoremodal idNumber={idNumber} path={path}/> */}

                            <Drawer anchor="left" open={openList} onClose={handleClose} className="scroe_module">

                                <Box sx={{ width: 250 }} role="presentation">
                                    <div className='box-line-sm'>
                                        <Startuplistfilter path={path} startupName={startupValue.startupName} viewStartupDetails={viewStartupDetails} handleClose={handleClose} />
                                    </div>
                                </Box>
                            </Drawer>
                        </div>
                    </div>
                </div>
            </section>
            <CommonViewPitchDeck viewImage={viewImage} open={open} handleClose={handleClose1} />
        </div>
    )
}

export default ScstDetails
