import React, { useContext } from 'react'
import DataContext from '../../../context/DataContext'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import apiService from '../../../api/apiService';
const Viewjurynamelist = ({viewJuryData,id,handleCloseJury}) => {
    const { setPageLoading} = useContext(DataContext)
    const handleUnassign = (jury)=>{
        setPageLoading(true)
        var req = {
            "partnerOrInvestorId": id,
            "assign": false,
            "juryId": [jury]
        }
        // debugger
        apiService('partner/jury/assign',req,'post').then((result) => {
            setPageLoading(false)
            if(result){
                if(result.data.responseStatus==='Success'){
                    // setJuryIds([])
                    // reset()
                    handleCloseJury()
                }
            }
        }).catch((err) => {
            
        });
    }
  return (
      <main>
          <div className='div_header'>
              <h6>View Evaluator</h6>
          </div>
          {
              (viewJuryData.length) ? (
                  <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                          <TableHead>
                              <TableRow>
                                  <TableCell>No</TableCell>
                                  <TableCell>Name</TableCell>
                                  <TableCell className='text-center'>Action</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {viewJuryData.map((list, index) => (
                                  <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>{list.name}</TableCell>
                                      <TableCell  className='text-center'>
                                        <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={()=>handleUnassign(list.id)} />
                                        </TableCell>
                                  </TableRow>
                              ))}
                          </TableBody>
                      </Table>
                  </TableContainer>
              ) : (
                  <div className='nodata_tag'>
                      <p>Evaluator Details is empty</p>
                  </div>
              )
          }
      </main>
  )
}

export default Viewjurynamelist