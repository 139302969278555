import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm, useFormState, Controller } from "react-hook-form";
import FormHelperText from '@mui/material/FormHelperText';
import { TextField, FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import apiService from '../../../../api/apiService';
import Button from '@mui/material/Button';
import cryptoJS from '../../../../hooks/cryptoJS';
import '../signup.css'
import DataContext from '../../../../context/DataContext';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Signupalert from '../Signupalert';
import ReCAPTCHA from 'react-google-recaptcha';
import { LoadingButton } from '@mui/lab';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import CustomLink from '../../../common-page/CustomLink';
const Startupsignup = () => {
    const reCAPTCHA = process.env.REACT_APP_CAPTCHA
    const { validateEmail, validatePhoneNumber, navigator, modalstyle, handleClose, open, handleOpen, loading, setLoading, } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm(
        { values: formData }
    );
    const [capVal, setCapVal] = useState(null)


    const cities = [
        "Ariyalur",
        "Chengalpattu",
        "Chennai",
        "Coimbatore",
        "Cuddalore",
        "Dharmapuri",
        "Dindigul",
        "Erode",
        "Kallakurichi",
        "Kanchipuram",
        "Kanniyakumari",
        "Kanniyakumari",
        "Karur",
        "Krishnagiri",
        "Madurai",
        "Mayiladuthurai",
        "Nagapattinam",
        "Namakkal",
        "Nilgiris",
        "Perambalur",
        "Pudukkottai",
        "Ramanathapuram",
        "Ranipet",
        "Salem",
        "Sivagangai",
        "Tenkasi",
        "Thanjavur",
        "Theni",
        "Thoothukudi",
        "Tiruchirappalli",
        "Tirunelveli",
        "Tirupathur",
        "Tiruppur",
        "Tiruvallur",
        "Tiruvannamalai",
        "Tiruvarur",
        "Vellore",
        "Viluppuram",
        "Virudhunagar"
    ];
    const [cityValue, setCityValue] = useState([]);
    const handleSelectCity = (value) => {
        setCityValue(value)
        setValue('city', value)
        clearErrors('city');
    }

    // const [emailValid, setEmailValid] = useState(true);
    // const [phoneNumberValid, setPhoneNumberValid] = useState(true);
    // const [showSuccessMessage, setShowSuccessMessage] = useState(false);



    // useEffect(() => {
    //     if (emailValid && phoneNumberValid) {
    //         setShowSuccessMessage(true);
    //     } else {
    //         setShowSuccessMessage(false);
    //     }
    // }, [emailValid, phoneNumberValid]);

    // const [i, setI] = useState(0);
    // const [inputValues, setInputValues] = useState([{ phone: '', email: '' }]);

    // const handleInputChange = (e, index) => {
    //     const newInputValues = [...inputValues];
    //     newInputValues[index] = { ...newInputValues[index], [e.target.name]: e.target.value };
    //     setInputValues(newInputValues);
    // };
    useDidMountEffect(() => {
        document.getElementById('theme').setAttribute('href', '/css/admin.css');
    }, [])
    useDidMountEffect(() => {
        homeApi();
    }, [])

    const [homeData, setHomeData] = useState({})
    const homeApi = () => {
        setHomeData({})
        apiService(`home`, '', 'get').then((res) => {
            if (res.data) {
                setHomeData(res.data)
            }
        })
    }

    const onSubmit = (data) => {
        var getform = data
        getform.roleId = 4
        setLoading(true)
        apiService('register', getform, 'unauthpost').then((res) => {
            setLoading(false)
            if (res) {
                if (res.data.response.responseStatus === 'Success') {
                    localStorage.setItem('StartupId', res.data.id)
                    navigator('/sign-up/verify')
                }

            }
        })
    };


    return (
        <>
            <marquee><h3 className='text-danger mt-5'>{homeData.maintenanceMessage}</h3></marquee>
            <section className="signup-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-10">
                            <div className="wrap d-md-flex">
                                {/* <div className="text-wrap-signup p-4 p-lg-5 text-center d-flex align-items-center order-md-last">
                                    <div className="text w-100">
                                        <h2>Startup Registration</h2>
                                        <p>Already have a account ?</p>
                                        <Link to="/sign-in" className="btn btn-white btn-outline-white">Sign In</Link>
                                    </div>
                                </div> */}
                                <div>
                                    <img src="/images/startup.png" className="startup-bar img-fluid" alt="" />
                                </div>
                                <div className="signup-wrap p-4 p-lg-5 bg-white">
                                    <div className="d-flex">
                                        <div className="w-100">
                                            <h3 className="mb-2 font-weight-bold">Who Can Register ?</h3>
                                            <p className="mb-4">Any startup based in Tamil Nadu, whether in the ideation, prototype or in traction stage, and actively seeking funding, is eligible to register.</p>
                                        </div>
                                    </div>
                                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className='row'>
                                            <div className='col-lg-12 col-12 mb-3'>
                                                <div className="form-group mb-3">
                                                    <Controller name="name" control={control} defaultValue="" rules={{ required: 'Startup Name' }}
                                                        render={({ field }) =>
                                                            <TextField
                                                                variant='outlined'
                                                                label="Startup Name"
                                                                placeholder='Startup Name'
                                                                autoFocus
                                                                fullWidth
                                                                {...field}
                                                                type="text"
                                                            />} />
                                                    <FormHelperText className='text-danger'>{errors.name && errors.name.message}</FormHelperText>
                                                </div>
                                            </div>
                                            {/* <div className='col-lg-6 col-12 mb-3'>
                                                <div className="form-group mb-3">
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <Controller name="city" control={control} defaultValue="" rules={{ required: 'City of Incorporation is required' }}
                                                            render={({ field }) =>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    disablePortal
                                                                    {...field}
                                                                    value={cityValue}
                                                                    options={cities}
                                                                    onChange={(e, selectedOptions) => handleSelectCity(selectedOptions)}
                                                                    renderInput={(params) => <TextField {...params} label="City of Incorporation" />}
                                                                />
                                                            } />
                                                        <FormHelperText className='text-danger'>{errors.city && errors.city.message}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div> */}
                                            <div className='col-lg-12 col-12 mb-3'>
                                                <div className="form-group mb-3">
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <Controller name='email' control={control} defaultValue="" rules={{ validate: validateEmail }}
                                                            render={({ field }) => (
                                                                <div>
                                                                    <TextField
                                                                        variant='outlined'
                                                                        label="Email Id"
                                                                        // id={`email-${i}`}
                                                                        type="text"
                                                                        // value={inputValues[i].email}
                                                                        // onChange={(e) => handleInputChange(e, i)}
                                                                        placeholder='Enter email id'
                                                                        {...field}
                                                                        fullWidth
                                                                    />

                                                                </div>
                                                            )} />
                                                        <FormHelperText className='text-danger'>{errors.email && errors.email.message}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-12 mb-3'>
                                                <div className="form-group mb-3">
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <Controller name='phone' control={control} defaultValue="" rules={{ validate: validatePhoneNumber }}
                                                            render={({ field }) => (
                                                                <div>
                                                                    <TextField
                                                                        variant='outlined'
                                                                        label="Phone Number "
                                                                        // id={`phone-${i}`}
                                                                        // type="number"
                                                                        // value={inputValues[i].phone}
                                                                        // onChange={(e) => handleInputChange(e, i)}
                                                                        placeholder='Enter Phone Number'
                                                                        {...field}
                                                                        fullWidth
                                                                        inputProps={
                                                                            { maxLength: 10 }
                                                                        }
                                                                    />

                                                                </div>
                                                            )}
                                                        />
                                                        <FormHelperText className='text-danger'>{errors.phone && errors.phone.message}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className='d-flex justify-content-center mb-3'>
                                                <ReCAPTCHA sitekey={reCAPTCHA} onChange={val => setCapVal(val)} />
                                            </div>
                                            <LoadingButton loading={loading}
                                                disabled={!capVal}
                                                loadingPosition="start" type="submit" variant="contained" className="form-control btn btn-primary submit px-3">Register</LoadingButton>
                                        </div>
                                        <div className='text-center'>
                                            <p className='mb-0 font-lg'>Already Registered & Verification Pending ? <CustomLink to='/sign-up/verify' className='c-blue'>Click Here</CustomLink></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag">
                    <Signupalert handleClose={handleClose} />
                </Box>
            </Modal>
        </>
    )
}

export default Startupsignup