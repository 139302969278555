import React, { useEffect, useState } from 'react'
import apiService from '../../../../../api/apiService'
import { Controller, useForm } from 'react-hook-form';
import notifyService from '../../../../../api/notifySerivce';
import { Autocomplete, FormControl, FormHelperText, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom';
import Viewimage from '../../../../../api/Viewimage';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
const Moreinfosave = ({ idNumber }) => {
    const [formData, setFormData] = useState({});
    const { handleSubmit, getValues, control, formState: { errors } } = useForm(
        { values: formData, }
    );
    useDidMountEffect(() => {
        infoGetInfo()
        historyList()
    }, []);
    const [viewFile, setViewFile] = useState(undefined)
    const infoGetInfo = () => {
        var id = Number(idNumber)
        apiService(`partner/additionalinfo/get?id=${id}`, '', 'get').then((res) => {
            if (res) {
                setFormData(res.data)
                setViewFile(res.data.additionalDocName)

            }
        })
    }
    const [historyListValue, setHistoryListValue] = useState([])
    const historyList = () => {
        var id = Number(idNumber)
        apiService(`partner/startups/additionalinfo/history?startupid=${id}`, '', 'get').then((res) => {
            if (res) {
                if (res.data.additionalInfo) {
                    setHistoryListValue(res.data.additionalInfo)
                }

            }
        })
    }
    const [loading, setLoading] = useState(false);
    const onSubmit = (data) => {
        setLoading(true)
        var getform = data
        getform.startupId = Number(idNumber)
        apiService('partner/additionalinfo/save', getform, 'post').then((res) => {
            setLoading(false);
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Updated successfully !!')
                    infoGetInfo()
                    historyList()
                }
            }
        })
    }
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value) => {
        setViewImage(value)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [reasonName, setReasonName] = useState('')
    useDidMountEffect(() => {
        // getReasonApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // const [reasonList, setReasonList] = useState([])
    const reasonList = [
        'Update details under Startup details section',
        'Update details under Founder info section',
        'Update details under Funding & Financials section',
        'Update details under Incubation/Accelaration section'
    ]
    // const getReasonApi = async () => {
    //     await apiService(`admin/dropdownvalues/get?key=district`, '', 'get').then((res) => {
    //         if (res) {
    //             if (res.data) {
    //                 var val = res.data.dropDownValues;
    //                 setReasonList(val.districts)
    //             }
    //         }

    //     })
    // }

    useDidMountEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reasonName])

    const handleReason = (value) => {
        setReasonName(value)
    }

    return (
        <main>
            <section>
                <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='row align-item-center'>
                        <div className='col-lg-6 col-md-6 col-12 my-3'>
                            <Autocomplete
                                sx={{ width: '450px' }}
                                disableClearable
                                disablePortal
                                value={reasonName}
                                options={reasonList}
                                onChange={(e, selectedOptions) => handleReason(selectedOptions)}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => <TextField {...params} label="Reason" />}
                            />
                        </div>
                        <div className='col-lg-9 col-12'>
                            <FormControl sx={{ width: '100%' }}>
                                <Controller name="comments" control={control} defaultValue="" rules={{ required: 'Comment is requried' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Comment"
                                            placeholder='Enter Comment'
                                            fullWidth
                                            multiline
                                            autoFocus
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.comments && errors.comments.message}</FormHelperText>
                            </FormControl>
                        </div>
                        <div className='col-lg-3 col-12 text-center'>
                            <LoadingButton
                                type="submit"
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<SendIcon />}
                                variant="contained"
                            >
                                <span>Request</span>
                            </LoadingButton>
                        </div>
                        <div className='col-lg-12 col-12 mt-3'>
                            {
                                viewFile !== undefined && (
                                    <p>Attached Documents - <span className='viewdetails' onClick={() => { handleClickOpen(viewFile) }}>Download File</span>
                                    </p>
                                )
                            }
                        </div>
                        {
                            getValues(`startupComments`) && (
                                <div className='col-lg-12 col-12 mt-3'>
                                    <p>Comments from Startups : <b>{getValues(`startupComments`)}</b></p>
                                </div>
                            )
                        }
                    </div>
                </form>
                {
                    (historyListValue.length > 0) && (
                        <div className='col-lg-12 col-12 my-3'>
                            <div className='div_header'>
                                <h6>Histroy Details</h6>
                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>No</TableCell>
                                            <TableCell>By</TableCell>
                                            <TableCell>Remarks</TableCell>
                                            <TableCell align='center'>Time</TableCell>
                                            <TableCell align='center'>Document Uploaded</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {historyListValue.map((list, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>
                                                    {list.by}
                                                </TableCell>
                                                <TableCell>
                                                    {list.remarks}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {list.time}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {list.documentUploaded ? ('Yes') : ('No')}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </div>
                    )
                }
            </section>
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
        </main>
    )
}

export default Moreinfosave