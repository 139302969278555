import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Scsthubavpremarks from '../../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks';
// import Scsthubavpremarks from '../../Scsthubavpremarks';
// import DataContext from '../../../context/DataContext';

const Questionslistdesign = ({ value }) => {
    console.log(value)
    return (
        <>
            <div className='div_header mt-2'>
                <h6>Questions</h6>
            </div>
            {/* <h6 className='mt-2'>Questions</h6> */}
            {/* {
                value.map((data, k) => (
                    <>
                        <div className='row mt-3 align-items-center'>
                            <div className='col-lg-4 col-md-4 col-12'>
                                <p>{k + 1}. {data.question}</p>
                            </div>
                            <div className='col-lg-4 col-md-4 col-12'>
                                {
                                    data.questionType === 'yesno' ? (<span>Answer: <b>{data.answer}</b></span>) : (<span>Score: <b>{data.score}</b></span>)
                                }
                            </div>
                            <div className='col-lg-4 col-md-4 col-12'>
                                <span>Remarks: <b>{data.remarks ? (data.remarks) : (`-`)}</b></span>
                            </div>
                        </div>
                        <hr />
                    </>
                ))
            } */}
            <div className='table_tag'>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Question</TableCell>
                                <TableCell align='center'></TableCell>
                                <TableCell align='center'>Remarks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {value.map((list, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        {list.question}
                                    </TableCell>
                                    <TableCell align='center'>
                                        {
                                            list.questionType === 'yesno' ? (<span>Answer : <b>{list.answer ? (list.answer) : (`-`)}</b></span>) : (<span>Score : <b>{list.score ? (list.score) : (`-`)}</b></span>)
                                        }
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Scsthubavpremarks remarks={list.remarks} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </TableContainer>
            </div>
        </>
    )
}

export default Questionslistdesign