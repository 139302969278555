import React, { useState, useEffect, useContext } from 'react';
// import Loader from 'react-loader-spinner';
import { Navigate, Route, Routes } from 'react-router-dom';
import DataContext, { DataProvider } from './context/DataContext';
import { ReactNotifications } from 'react-notifications-component';
import './App.css';
import 'react-notifications-component/dist/theme.css';
import Baseroute from './components/Baseroute';
import ScrollToTop from './components/common-page/ScrollToTop';




function App() {
  // useEffect(() => {
  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };
  //   document.addEventListener('contextmenu', handleContextMenu);
  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);
  return (
    <div className="App">
      <ReactNotifications />
      <DataProvider>
        <ScrollToTop />
        <Baseroute />
      </DataProvider>
    </div>
  );
}

export default App;

// "start": "NODE_ENV=development node scripts/start.js",
//   "start-prod": "NODE_ENV=production node scripts/start.js",
//     "build-dev": "NODE_ENV=development node scripts/build.js",
//       "build-prod": "NODE_ENV=production node scripts/build.js",