import React, { useEffect, useContext, useState } from 'react';
import { Box, Container, Modal } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import DataContext from '../../../context/DataContext';
import TablePagination from '@mui/material/TablePagination';
import { LoadingButton } from '@mui/lab';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import apiService from '../../../api/apiService';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Jurysave from '../../partner-module/jury-details/Jurysave';
import downloadExcel from '../../../hooks/downloadExcel';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '17ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const Adminjurylist = ({ path }) => {
    const { searchInputValue, open, handleClose, modalstyle, editValue,
        handleChangePage, handleChangeRowsPerPage, partnerListData, count, page, rowsPerPage, handleSearch, userListItem, deleteUser,
    tableIndexValue
    } = useContext(DataContext)
    useDidMountEffect(() => {
        (async () => await userListItem(3))()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, searchInputValue])
    const [loading, setLoading] = useState(false);
    const exportExcel = () => {
        setLoading(true);
        // apiService('export/users?roleid=3', '', 'download').then((res) => {
        //     setLoading(false);
        //     downloadExcel(res.data,'jury_')
        // })
        var req = {
            "download": 'yes',
            "roleId": 3,
            "active": 1
        }

        apiService('user/list', req, 'downloadpost').then((res) => {
            setLoading(false);
            if (res) {
                if (res.data) {
                    downloadExcel(res.data, 'jury_')
                }
            }
        })
    }

    return (
        <section className=''>
            <div className='container-fluid'>
                <div className='form_div chart'>
                    <div className='header_tag'>
                        <h5>Jury List</h5>
                    </div>
                    <div className='table_tag'>
                        <div className='table_header_tag d-flex'>

                            <SearchinputDiv sx={{ display: { xs: 'none', sm: 'block' } }} onChange={(e) => handleSearch(e.target.value)} className='searchBox'>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    value={searchInputValue}
                                    type="search"
                                    placeholder="Search with Name"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </SearchinputDiv>
                            {/* <LoadingButton className='ml-auto'
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<CloudDownloadIcon />}
                            variant="outlined"
                            onClick={exportExcel}
                        >
                            <span>EXPORT JURIES</span>
                        </LoadingButton> */}

                        </div>
                        {
                            (partnerListData.length) ? (
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>No</TableCell>
                                                <TableCell>Organization Name</TableCell>
                                                <TableCell>Email Id</TableCell>
                                                <TableCell>Phone Number</TableCell>
                                                <TableCell>LinkedIn</TableCell>
                                                {/* <TableCell align='center'>Action</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {partnerListData.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {
                                                            tableIndexValue(rowsPerPage, page, index)
                                                        }
                                                    </TableCell>
                                                    <TableCell>{row.name}</TableCell>
                                                    <TableCell>{row.email}</TableCell>
                                                    <TableCell>{row.phone}</TableCell>
                                                    <TableCell>{row.linkedIn}</TableCell>
                                                    {/* <TableCell align='center'>
                                                    <EditIcon style={{ fill: '#66bb6a' }} onClick={() => editValue(row.id)} />
                                                    <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => deleteUser(row.id)} />
                                                </TableCell> */}

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <caption>
                                            <TablePagination
                                                component="div"
                                                count={count}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                rowsPerPage={rowsPerPage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                style={{float:'left'}}
                                            /></caption>
                                    </Table>

                                </TableContainer>
                            ) : (
                                <div className='nodata_tag'>
                                    <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                    <h4>Jury list is empty</h4>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag">
                    <Jurysave handleClose={handleClose} path={path} name={'Jury'} />
                </Box>

            </Modal>
        </section>
    )
}

export default Adminjurylist