import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm, Controller } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import { Dialog } from '@mui/material';
// import Tanseed6 from './form-page/Tanseed6';
import Slide from '@mui/material/Slide';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import notifyService from '../../../api/notifySerivce';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Termsconditionscst = () => {
    const { navigator } = useContext(DataContext)
    const [formDate, setFormData] = useState({});
    const { handleSubmit, control, setValue, clearErrors, formState: { errors } } = useForm(
        { values: formDate }
    );
    useDidMountEffect(() => {
        // eslint-disable-next-line
        (async () => await getApi())()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getApi = async () => {
        await apiService(`startup/startupdeclaration/get`, '', 'get').then((res) => {
            if (res) {
                var response = res.data
                if (response.editForm === false) {
                    setReadyOnlyValue(true)
                }
                // setTermsAndConditionsHTML(response.termsAndConditions)
                // setPrivacyPolicyHTML(response.privacyPolicy)
                setAcceptscstTermsValue(response.acceptscstTerms)
                // setAcceptAgreementValue(response.acceptAgreement)
                // setTermsAndConditionsValue(response.acceptTermsAndConditions)
                // setAcceptTanseedTermValue(response.acceptTanseedTerms)
                setFormData(response)
                // setValue('termsAndConditions', response.termsAndConditions)
                // setValue('accept', response.termsAndConditions)
                // setValue('terms', response.termsAndConditions)
            }
        })
    }

    const [acceptscstTermsValue, setAcceptscstTermsValue] = useState(null);
    const handleChangeacceptscstTerms = (event) => {
        setAcceptscstTermsValue(event.target.checked);
        if (event.target.checked) {
            setValue('acceptscstTerms', event.target.checked)
            clearErrors('acceptscstTerms')
        } else {
            setValue('acceptscstTerms', null)
        }
    };
    const [acceptAgreementValue, setAcceptAgreementValue] = useState(null);
    const handleChangeAcceptAgreement = (event) => {
        setAcceptAgreementValue(event.target.checked);
        if (event.target.checked) {
            setValue('acceptAgreement', event.target.checked)
            clearErrors('acceptAgreement')
        } else {
            setValue('acceptAgreement', null)
        }
    };
    const [termsAndConditionsValue, setTermsAndConditionsValue] = useState(null);
    const handleChangeTermsAndConditions = (event) => {
        setTermsAndConditionsValue(event.target.checked);
        if (event.target.checked) {
            setValue('acceptTermsAndConditions', event.target.checked)
            clearErrors('acceptTermsAndConditions')
        } else {
            setValue('acceptTermsAndConditions', null)
        }
    };
    const [acceptTanseedTermsValue, setAcceptTanseedTermValue] = useState(null);
    const handleChangeAcceptTanseedTerms = (event) => {
        setAcceptTanseedTermValue(event.target.checked);
        if (event.target.checked) {
            setValue('acceptTanseedTerms', event.target.checked)
            clearErrors('acceptTanseedTerms')
        } else {
            setValue('acceptTanseedTerms', null)
        }
    }
    const [loading, setLoading] = useState(false);
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const onSubmit = (data) => {
        if (readyOnlyValue === false) {

            setLoading(true);
            var getform = data
            apiService('startup/startupdeclaration/save', getform, 'post').then((res) => {
                setLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        handleClickOpen()
                    }
                }
            })
            // if (formDate.optedForTanseed === false) {
            //     handleClickOpenApply()
            // }
        }
    };

    const handlePreviousscst = () => {
        navigator('/startup/dashboard/SCST')
    }
    const [openApply, setApplyOpen] = useState(false);

    const handleClickOpenApply = () => {
        setApplyOpen(true);
    };

    const handleApplyClose = () => {
        setApplyOpen(false);
        getApi()
        
    };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        navigator('startup/all-dashboard/scst')
    };

    const onError = (error) => {
        console.log(error);
        notifyService("danger", "Error", "Please check mandatory fields");
      };

    return (
        <>
            <section className=''>
                <div className='container'>
                    <div className='form_div chart'>
                        <div className='terms-head'>
                            <div className='chart-det'>
                                <div>
                                    <h5 className='font-weight-bold mb-3'>Terms And Conditions</h5>
                                </div>
                            </div>
                        </div>
                        <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className=''>
                                <div className='col-lg-12 col-12'>
                                    <div className='d-flex'>
                                        {/* <div>
                                            <Controller name="acceptAgreement" control={control} defaultValue="" rules={{ required: 'Required' }}
                                                render={({ field }) =>
                                                    <FormControlLabel {...field} control={<Checkbox disabled={readyOnlyValue} checked={acceptAgreementValue} onChange={handleChangeAcceptAgreement} />}
                                                    />
                                                } />
                                            <FormHelperText className='text-danger'>{errors.acceptAgreement && errors.acceptAgreement.message}</FormHelperText>
                                        </div> */}
                                        <div className='mt-2'>
                                            <p>
                                                <ol>
                                                    <li>I declare that all the statements made in this application are true, complete and correct to the best of my knowledge and belief. In the event of any information found false or incorrect, my candidature will stand cancelled and all my claims will be forfeited.</li>
                                                    <br />
                                                    <li>I declare that we read the Eligibility Criteria to apply for the Tamil Nadu SC/ST Startup Fund in the StartupTN website (https://startuptn.in/sc-st-fund/) and confirm that I fulfill all the eligibilities.</li>
                                                    <br />
                                                    <li>I also understand that StartupTN reserves all the rights towards the selection of applications based on the defined criteria and that the decision of the committee and jury is final.</li>
                                                    <br />
                                                    <li>I declare that the Startup is registered with TANSIM & Startup India and is headquartered in Tamil Nadu or will relocate the registered office to Tamil Nadu.</li>
                                                    <br />
                                                    <li> I declare that the Startup is registered as a Private Limited Company (under Companies Act, 2013) or as a Limited Liability Partnership (under The Limited Liability Partnership Act, 2008) or a Registered Partnership Firm (under The Partnership Act, 1932) or Udyam Registration for proprietorship</li>
                                                </ol>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-12 mb-3'>
                                    <div className='d-flex'>
                                        <div>
                                            <Controller name="acceptscstTerms" control={control} defaultValue="" rules={{ required: 'Required' }}
                                                render={({ field }) =>
                                                    <FormControlLabel {...field} control={<Checkbox disabled={readyOnlyValue} checked={acceptscstTermsValue} onChange={handleChangeacceptscstTerms}/>} //
                                                    />
                                                } />
                                            <FormHelperText className='text-danger'>{errors.acceptscstTerms && errors.acceptscstTerms.message}</FormHelperText>
                                        </div>
                                        <div className='mt-2'>
                                            <p>
                                                I accept the Terms and Conditions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group text-center">
                                    <Button
                                        // variant="contained" 
                                        onClick={handlePreviousscst} className="btn-primary-cancel px-3 mr-3">
                                        {
                                            (readyOnlyValue === false) ? (`Back`) : (`Close`)
                                        }
                                    </Button>
                                    {
                                        (readyOnlyValue === false) ? (
                                            <LoadingButton
                                                type="submit"
                                                loading={loading}
                                                loadingPosition="start"
                                                // startIcon={<SaveIcon />}
                                                // variant="contained"
                                                className="btn btn-primary-submit px-3"
                                            >
                                                {/* <span onClick={handleClickOpen}>Submit</span> */}
                                                Submit
                                            </LoadingButton>
                                        ) : (<></>)
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Dialog
                    open={openApply}
                    TransitionComponent={Transition}
                    keepMounted
                    className='text-center p-5'
                    fullWidth
                    maxWidth="md"
                >
                    {/* <Tanseed6 handleApplyClose={handleApplyClose} optedForTanseed={formDate.optedForTanseed} page={'terms'} /> */}
                </Dialog>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <div className='text-center'>
                            <img src='/images/verified.png' className='ver-img' />
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <h3 className='black-cl text-center'>Application Submitted Successfully</h3>
                            <h6 className='text-center my-2'>Congratulations on submitting your application</h6>
                            <h6 className='text-center my-2'>We will get back to you soon</h6>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className='btn-cen'>
                            <Button onClick={handleClose} autoFocus variant='outlined'>
                                OK
                            </Button>
                    </DialogActions>
                </Dialog>
            </section>
        </>
    )
}

export default Termsconditionscst