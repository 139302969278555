import React, { useContext, useState } from "react";
import DataContext from "../../../context/DataContext";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useForm, Controller } from "react-hook-form";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

import CustomLink from "../../common-page/CustomLink";
import OnBoradFilter from "./OnBoradFilter";

const StartupOnboraded = ({ role }) => {


    const { setPageLoading, tableIndexValue } = useContext(DataContext);
    const [count, setCount] = useState(0);
    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(10);

    const [scstHubList, setScstHubList] = useState([]);
    // const [districtName, setDistrictName] = useState("");
    // const [scsthub, setScsthub] = useState("");
    // const [yearName, setYearName] = useState(2022);
    // const [monthName, setMonthName] = useState(0);
    // const [manualName, setManualName] = useState(0)
    // const [searchInputValue, setSearchInputValue] = useState('');
    // const [statusValue, setStatusValue] = useState(0)
    var pageNumberPartners = Number(localStorage.getItem('pageScst'))
    const [page, setPage] = useState(pageNumberPartners ? pageNumberPartners : 0);

    var listSizePartners = Number(localStorage.getItem('rowsPerPageScst'))
    const [rowsPerPage, setRowsPerPage] = useState(listSizePartners ? listSizePartners : 10);

    

    var districtNameScst = localStorage.getItem('districtNameScst')
    const [districtName, setDistrictName] = useState(districtNameScst ? districtNameScst : '')

    var hubScst = localStorage.getItem('hubScst')
    const [scsthub, setScsthub] = useState(hubScst ? hubScst : "");

    var yearNameScst = Number(localStorage.getItem('yearNameScst'))
    const [yearName, setYearName] = useState(yearNameScst ? yearNameScst : 2022);

    var monthNameScst = Number(localStorage.getItem('monthNameScst'))
    const [monthName, setMonthName] = useState(monthNameScst ? monthNameScst : 0);

    var manualNameScst = Number(localStorage.getItem('manualNameScst'))
    const [manualName, setManualName] = useState(manualNameScst ? manualNameScst : 0)

    var searchInputValueScst = localStorage.getItem('searchInputValueScst')
    const [searchInputValue, setSearchInputValue] = useState(searchInputValueScst ? searchInputValueScst : '');

    var statusValueScst = Number(localStorage.getItem('statusValueScst'))
    const [statusValue, setStatusValue] = useState(statusValueScst ? statusValueScst : 1)

    useDidMountEffect(() => {
        (async () => await scstHublist())();
        // eslint-disable-next-line
    }, [page, rowsPerPage, districtName, scsthub, yearName, monthName, manualName, searchInputValue, statusValue]);

    const scstHublist = () => {
        setPageLoading(true)
        setScstHubList([]);
        var req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            district: districtName,
            hub: scsthub,
            year: yearName,
            month: monthName,
            onboardType: manualName,
            searchString: searchInputValue,
            status: statusValue
        }
        apiService("partner/scst/admin/startups/list", req, "post").then((res) => {
            setPageLoading(false);
            if (res) {
                setCount(res.data.count);
                if (res.data.startups) {
                    setScstHubList(res.data.startups);
                } else {
                    setScstHubList([]);
                }
            }
        });
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        localStorage.setItem('pageScst', newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        localStorage.setItem('rowsPerPageScst', parseInt(event.target.value, 10))
        localStorage.setItem('pageScst', 0)
        setPage(0);
    };
    return (
        <div>
            <OnBoradFilter
                setPage={setPage}
                districtName={districtName} setDistrictName={setDistrictName}
                scsthub={scsthub} setScsthub={setScsthub}
                yearName={yearName} setYearName={setYearName}
                monthName={monthName} setMonthName={setMonthName}
                manualName={manualName} setManualName={setManualName}
                path={''}
                searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue}
                statusValue={statusValue} setStatusValue={setStatusValue}
                lblName='SC/ST Startups'
            />
            <div className="table_tag">
                {scstHubList.length ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Startup Name</TableCell>
                                    <TableCell>District</TableCell>
                                    <TableCell>Assigned to hub</TableCell>
                                    <TableCell>Entity</TableCell>
                                    <TableCell >Onboarded Type</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell align='center'>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {scstHubList.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {
                                                tableIndexValue(rowsPerPage, page, index)
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <CustomLink to={`/${role}/startups/${btoa(row.startupId)}/${btoa(row.partnerOrInvestorId)}`}>{row.startupName}</CustomLink>
                                        </TableCell>
                                        {/* <TableCell>{row.startupName}</TableCell> */}
                                        <TableCell>{row.district}</TableCell>
                                        <TableCell>{row.investorName}</TableCell>
                                        <TableCell>{row.entityType}</TableCell>
                                        <TableCell>
                                            {
                                                row.onboardType === 1 ? 'Manual' : 'Portal'
                                            }
                                        </TableCell>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell align='center'>{row.status}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <caption>
                                <TablePagination
                                    component="div"
                                    count={count}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    style={{ float: 'left' }}
                                />
                            </caption>
                        </Table>
                    </TableContainer>
                ) : (
                    <div className="nodata_tag">
                        <img
                            src="/images/Tanfundlogo-black-1.png"
                            className="no_data_logo"
                            alt=""
                        />
                        <h4>Startup list is empty</h4>
                    </div>
                )}
            </div>
        </div>
    )
}

export default StartupOnboraded