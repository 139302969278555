import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import notifyService from "../../../api/notifySerivce";
import apiService from "../../../api/apiService";

const AdvisoryMentor = () => {
  const [formData, setFormData] = useState({
    startupFounders: [{}],
    startupAdvisories: [{}],
  });
  const { navigator, handleBacktanseed, Paper, } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);

  // const {
  //   fields: foundersFields,
  //   append: founderAppend,
  //   remove: foundersRemove,
  // } = useFieldArray({
  //   control,
  //   name: "startupFounders",
  // });
  // const {
  //   fields: advisoriesFields,
  //   append: advisoriesAppend,
  //   remove: advisoriesRemove,
  // } = useFieldArray({
  //   control,
  //   name: "startupAdvisories",
  // });
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
  }, []);
  const getApi = async () => {
    await apiService(`startup/team/get`, "", "get").then((res) => {
      if (res) {
        setFormData({});
        let response = res.data;
        if (response.id !== 0) {
          setFormData(response);
          if (response.editForm === false) {
            setReadyOnlyValue(true);
          }
        } else {
          setFormData(response);
        }
      }
    });
  };
  // const addFounderDetailsBtn = () => {
  //   founderAppend({
  //     name: "",
  //     brief: "",
  //     linkedIn: "",
  //   });
  // };
  // const addAdvisoriesDetailsBtn = () => {
  //   advisoriesAppend({
  //     name: "",
  //     linkedIn: "",
  //   });
  // };
  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      apiService("startup/team/save", getform, "post").then((res) => {
        setLoading(false);
        if (res) {
          if (res.data.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            navigator("/startup/traction-achievements/tanseed");
          }
        }
      });
    }
  };
  // delete Founders
  // const deleteFounders = async (index) => {
  //   const id = getValues(`startupFounders.${index}.id`);
  //   if (id !== undefined) {
  //     apiService(`startup/founders/remove?id=${id}`, "", "get").then((res) => {
  //       if (res) {
  //         if (res.data.responseStatus === "Success") {
  //           foundersRemove(index);
  //           getApi();
  //         }
  //       }
  //     });
  //   } else {
  //     foundersRemove(index);
  //   }
  // };
  // delete Advisories
  // const deleteAdvisories = async (index) => {
  //   const id = getValues(`startupAdvisories.${index}.id`);
  //   if (id !== undefined) {
  //     apiService(`startup/advisories/remove?id=${id}`, "", "get").then(
  //       (res) => {
  //         if (res) {
  //           if (res.data.responseStatus === "Success") {
  //             advisoriesRemove(index);
  //             getApi();
  //           }
  //         }
  //       }
  //     );
  //   } else {
  //     advisoriesRemove(index);
  //   }
  // };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBacktanseed}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>
            <h6 className="ml-2 mb-0">Advisory Mentors</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="col-8 mb-3">
                {/* <div className="div_header">
                  <div className="d-flex">
                    <h6>Founder Details</h6>
                    <Button
                      variant="outlined"
                      disabled={readyOnlyValue}
                      className="ml-auto"
                      onClick={() => addFounderDetailsBtn("")}
                    >
                      Add Founder Details
                    </Button>
                  </div>
                </div> */}
                <div>
                 
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                          <TableRow>
{/*                             
                            <TableCell>Founder Name * </TableCell>
                            <TableCell>LinkedIn Profile Link *</TableCell> */}
                           
                          </TableRow>
                        </TableHead>
                        <TableBody>
                       
                            <TableRow>
                             
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name="mentorName"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Name is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Name"
                                        placeholder="Enter Name"
                                        fullwidth
                                        {...field}
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors?.name &&
                                      errors?.name
                                        .message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name="linkedIn"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "LinkedIn is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="LinkedIn"
                                        placeholder="Enter LinkedIn"
                                        fullwidth
                                        {...field}
                                        disabled={readyOnlyValue}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors
                                      ?.linkedIn &&
                                      errors?.linkedIn
                                        .message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                            
                              {/* <TableCell align="center">
                                <DeleteIcon
                                  style={{ fill: "#d32f2f", cursor: "pointer" }}
                                  onClick={() => {
                                    readyOnlyValue === false ? (
                                      deleteFounders(index)
                                    ) : (
                                      <></>
                                    );
                                  }}
                                />
                              </TableCell> */}
                            </TableRow>
                        
                        </TableBody>
                      </Table>
                    </TableContainer>
                 
                </div>
              </div>

             
            </div>
            <div className="form-group text-center">
              <Button
                variant="contained"
                onClick={handleBacktanseed}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AdvisoryMentor;
