import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import useDidMountEffect from '../../hooks/useDidMountEffect';
import Startupnavpage from './startup-navbar/Startupnavpage';

const Startuplayout = () => {
    const navigator = useNavigate()
    let tanseed = localStorage.getItem('tanseed')
    let path
    let redirects = localStorage.getItem('redirects')
    useDidMountEffect(() => {
        if (!localStorage.getItem('jwttoken')) navigator('/sign-in');
        path = window.location.hash
        debugger
    }, [])
    useDidMountEffect(() => {
        document.getElementById('theme').setAttribute('href', '/css/admin.css');
    }, [])
    return (
        <div className='admin_main_div'>
            {
                (path !== '#/startup/info' && redirects != 'TanseedCompleted') && (
                    <Startupnavpage tanseed={tanseed}></Startupnavpage>
                )
            }

            <Outlet></Outlet>
        </div>
    )
}

export default Startuplayout