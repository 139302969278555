import React, { useState } from "react";
import { Tab } from "@mui/material";
import Box from "@mui/material/Box";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import StartupOnboraded from "./onboraded-details/StartupOnboraded";
import Scsthubavpnavbar from './Scsthubavpnavbar'

const Scsthubavplist = ({ role }) => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue)
  };
  return (
    <>
      <Scsthubavpnavbar role={role} />

      <div className="scsthub-div form_div chart">
        <StartupOnboraded role={role} />
      </div>
    </>
  );
};

export default Scsthubavplist;
