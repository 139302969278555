import React, { useContext, useState } from 'react'
import DataContext from '../../../../context/DataContext'
import { Divider, Typography } from '@mui/material';
import apiService from '../../../../api/apiService';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import Questionslistdesign from './Questionslistdesign';
import Scsthubavpremarks from '../../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks';
import Suggestionsview from './Suggestionsview';

const Associatedrounds = ({ startupId, partnerOrInvestorId, name }) => {
    const { navigator, Accordion, AccordionSummary, AccordionDetails, setPageLoading } = useContext(DataContext)
    useDidMountEffect(() => {
        getApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [roundData, setRoundData] = useState([])
    const [formData, setFormData] = useState([])

    const getApi = () => {
        setPageLoading(true)
        setRoundData([])
        if (name === 'partner') {
            apiService(`partner/startup/rounds/get?startupid=${startupId}`, '', 'get').then((result) => {
                setPageLoading(false)
                if (result) {
                    if (result.data) {
                        if (result.data.startupRounds) {
                            setRoundData(result.data.startupRounds)
                        }


                    }
                }
            }).catch((err) => {
                setPageLoading(false)
            });
        } else if (name === 'tanseedadmin') {
            apiService(`admin/startups/rounds/get?id=${startupId}&investorid=${partnerOrInvestorId}`, '', 'get').then((result) => {
                setPageLoading(false)
                if (result) {
                    if (result.data) {
                        setFormData(result.data)
                        if (result.data.startupRounds) {
                            setRoundData(result.data.startupRounds)
                        }
                        if (result.data.mentorthestartup) {
                            setMentor(result.data.mentorthestartup)
                        }
                        if (result.data.recommend) {
                            setRecommend(result.data.recommend)
                        }

                    }
                }
            }).catch((err) => {
                setPageLoading(false)
            });
        } else {
            var req = {
                "startupId": startupId,
                "partnerOrInvestorId": partnerOrInvestorId
            }
            apiService(`jury/startup/rounds/get`, req, 'post').then((result) => {
                setPageLoading(false)
                if (result) {
                    if (result.data) {
                        setFormData(result.data)
                        if (result.data.startupRounds) {
                            setRoundData(result.data.startupRounds)
                        }
                        if (result.data.mentorthestartup) {
                            setMentor(result.data.mentorthestartup)
                        }
                        if (result.data.recommend) {
                            setRecommend(result.data.recommend)
                        }

                    }
                }
            }).catch((err) => {
                setPageLoading(false)
            });
        }

    }
    const [expanded, setExpanded] = useState('');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const [expanded1, setExpanded1] = useState('');
    const handleChange1 = (panel) => (event, newExpanded) => {
        setExpanded1(newExpanded ? panel : false);
    };
    const [recommend, setRecommend] = useState();
    const handleRecommend = (value) => {
        setRecommend(value)
        //   var data = getValues()
        var req = {
            "id": formData.id,
            "recommend": value
        }
        apiService('jury/startup/recommend/save', req, 'post').then((res) => {
            if (res) {
                if (res.data.response.responseStatus === 'Saved Successfully') {
                    getApi()
                }
            }
        })
    }
    const [mentor, setMentor] = useState();
    const handleMentor = (value) => {
        setMentor(value)
        //   var data = getValues()
        var req = {
            "id": formData.id,
            "mentorthestartup": value
        }
        apiService('jury/startup/mentorthestartup/save', req, 'post').then((res) => {
            if (res) {
                if (res.data.response.responseStatus === 'Saved Successfully') {
                    getApi()
                }
            }
        })
    }
    return (
        <div>
            {/* <Accordion expanded={expanded === `panel${1}`} onChange={handleChange(`panel${1}`)}>
                <AccordionSummary aria-controls="panel1d-content" id={`panel${1}d-header`}>
                    <Typography>Round 1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <h6>Juries Average Score for the Round: <b>75</b></h6>

                    <Accordion expanded={expanded === `panel${1}`} onChange={handleChange(`panel${1}`)}>
                        <AccordionSummary aria-controls="panel1d-content" id={`panel${1}d-header`}>
                            <Typography>Jury 1</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='d-flex justify-content-between'>
                                <h6>Recommended this Startup: <b>YES</b></h6>
                                <h6>Interested in Mentoring the Startup: <b>YES</b></h6>
                                <h6>Average Questions Score: <b>100</b></h6>
                            </div>
                            <h6 className='mt-2'>Remarks: <b>Test Remarks</b></h6>

                            <h6 className='mt-3'>Question 1:</h6>
                            <h6 className='pl-3'>Score: <b>100</b></h6>

                            <h6>Question 2:</h6>
                            <h6 className='pl-3'>Score: <b>100</b></h6>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === `panel${1}`} onChange={handleChange(`panel${1}`)}>
                        <AccordionSummary aria-controls="panel1d-content" id={`panel${1}d-header`}>
                            <Typography>Jury 2</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='d-flex justify-content-between'>
                                <h6>Recommended this Startup: <b>No</b></h6>
                                <h6>Interested in Mentoring the Startup: <b>YES</b></h6>
                                <h6>Average Questions Score: <b>20</b></h6>
                            </div>
                            <h6 className='mt-2'>Remarks: <b>Test Remarks</b></h6>

                            <h6 className='mt-3'>Question 1:</h6>
                            <h6 className='pl-3'>Score: <b>25</b></h6>

                            <h6>Question 2:</h6>
                            <h6 className='pl-3'>Score: <b>15</b></h6>
                        </AccordionDetails>
                    </Accordion>

                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded1 === `panel${2}`} onChange={handleChange1(`panel${2}`)}>
                <AccordionSummary aria-controls="panel1d-content" id={`panel${1}d-header`}>
                    <Typography>Round 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <h6>Juries Average Score for the Round: <b>75</b></h6>

                    <Accordion expanded={expanded === `panel${2}`} onChange={handleChange(`panel${2}`)}>
                        <AccordionSummary aria-controls="panel1d-content" id={`panel${2}d-header`}>
                            <Typography>Jury 1</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='d-flex justify-content-between'>
                                <h6>Recommended this Startup: <b>YES</b></h6>
                                <h6>Interested in Mentoring the Startup: <b>YES</b></h6>
                                <h6>Average Questions Score: <b>100</b></h6>
                            </div>
                            <h6 className='mt-2'>Remarks: <b>Test Remarks</b></h6>

                            <h6 className='mt-3'>Question 1:</h6>
                            <h6 className='pl-3'>Score: <b>100</b></h6>

                            <h6>Question 2:</h6>
                            <h6 className='pl-3'>Score: <b>100</b></h6>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === `panel${2}`} onChange={handleChange(`panel${2}`)}>
                        <AccordionSummary aria-controls="panel1d-content" id={`panel${2}d-header`}>
                            <Typography>Jury 2</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='d-flex justify-content-between'>
                                <h6>Recommended this Startup: <b>No</b></h6>
                                <h6>Interested in Mentoring the Startup: <b>YES</b></h6>
                                <h6>Average Questions Score: <b>20</b></h6>
                            </div>
                            <h6 className='mt-2'>Remarks: <b>Test Remarks</b></h6>

                            <h6 className='mt-3'>Question 1:</h6>
                            <h6 className='pl-3'>Score: <b>25</b></h6>

                            <h6>Question 2:</h6>
                            <h6 className='pl-3'>Score: <b>15</b></h6>
                        </AccordionDetails>
                    </Accordion>

                </AccordionDetails>
            </Accordion> */}
            {
                (name !== 'partner' && name !== 'tanseedadmin') && (
                    <section className='container-fluid my-4'>
                        <div className='row'>
                            <div className='col-lg-6 d-flex justify-content-between align-items-center'>
                                <p className='mb-0'>Do you Recommend this Startup to Investor ?  </p>
                                <div className='filiterinvestor mr-4'>
                                    <span className={(recommend === 1) ? ('common_span act_span') : ('common_span')} onClick={() => { handleRecommend(1) }}>YES</span>
                                    <span className={(recommend === 2) ? ('common_span act_span') : ('common_span')} onClick={() => { handleRecommend(2) }}>NO</span>
                                </div>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-between align-items-center'>
                                <p className='mb-0'>Would you like to mentor this Startup ?</p>
                                <div className='filiterinvestor mr-4'>
                                    <span className={(mentor === 1) ? ('common_span act_span') : ('common_span')} onClick={() => { handleMentor(1) }}>YES</span>
                                    <span className={(mentor === 2) ? ('common_span act_span') : ('common_span')} onClick={() => { handleMentor(2) }}>NO</span>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }

            {roundData.length > 0 ? roundData.map((field, index) => (
                <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                    <AccordionSummary aria-controls="panel1d-content" id={`panel${index}d-header`}>

                        {
                            (name !== 'partner' && name !== 'tanseedadmin') ? (
                                <>
                                    <div className='d-flex w-100'>
                                        <div><b>{field.roundName}</b></div>
                                        <div className='ml-auto'>Remarks : <Scsthubavpremarks remarks={field.remarks} />
                                        </div>
                                        <div className='ml-auto'>Suggestions : <Suggestionsview suggestions={field.suggestions} />
                                        </div>
                                        {
                                            field.roundNo > 1 && (
                                                <div className='ml-auto'>
                                                    Total Score :
                                                    <b>
                                                        {field.avgScore}
                                                    </b>
                                                </div>
                                            )
                                        }

                                        <div className='ml-auto'>

                                            {
                                                field.roundNo > 1 ? (
                                                    <>
                                                        Do you recommend :
                                                        <b>
                                                            {field.status === 0 && <span> Pending</span>}
                                                            {field.status === 1 && <span className='c-red'> No</span>}
                                                            {field.status === 2 && <span className='c-blue'> Maybe</span>}
                                                            {field.status === 3 && <span className='c-green'> Yes</span>}
                                                            {field.status === 4 && <span className='c-orange'> Can't Review</span>}
                                                        </b>
                                                    </>
                                                ) : (
                                                    <>
                                                        Status :
                                                        <b>
                                                            {field.status === 0 && <span> Pending</span>}
                                                            {field.status === 1 && <span className='c-green'> Recommended</span>}
                                                            {field.status === 2 && <span className='c-red'> Rejected</span>}
                                                        </b>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <Typography> {field.roundName} </Typography>
                            )
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* <div className='round-details'>
                            <h6>Juries Average Score for the Round : <b>{field.avgScore}</b></h6>
                        </div> */}
                        {
                            (name !== 'partner' && name !== 'tanseedadmin') ? (
                                <>
                                    {
                                        field.startupQuestions !== undefined && (
                                            <Questionslistdesign value={field.startupQuestions} />
                                        )
                                    }
                                </>
                            ) : (
                                <>
                                    {
                                        roundData[index].jury && roundData[index].jury.map((list, j) => (
                                            <Accordion expanded={expanded1 === `panel1${j}`} onChange={handleChange1(`panel1${j}`)}>
                                                <AccordionSummary aria-controls="panel1d-content" id={`panel1${j}d-header`}>
                                                    <div className='d-flex w-100'>
                                                        <div><b>{list.name}</b></div>
                                                        <div className='ml-auto'>Remarks : <Scsthubavpremarks remarks={list.remarks} />
                                                        </div>
                                                        <div className='ml-auto'>Suggestions : <Suggestionsview suggestions={list.suggestions} />
                                                        </div>
                                                        <div className='ml-auto'>

                                                            {
                                                                field.roundNo > 1 ? (
                                                                    <>
                                                                        Do you recommend :
                                                                        <b>
                                                                            {list.status === 0 && <span> Pending</span>}
                                                                            {list.status === 1 && <span className='c-red'> No</span>}
                                                                            {list.status === 2 && <span className='c-blue'> Maybe</span>}
                                                                            {list.status === 3 && <span className='c-green'> Yes</span>}
                                                                            {list.status === 4 && <span className='c-orange'> Can't Review</span>}
                                                                        </b>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        Status :
                                                                        <b>
                                                                            {list.status === 0 && <span> Pending</span>}
                                                                            {list.status === 1 && <span className='c-green'> Recommended</span>}
                                                                            {list.status === 2 && <span className='c-red'> Rejected</span>}
                                                                        </b>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className='d-flex justify-content-between my-3'>
                                                        <h6>Recommended this Startup : <b>
                                                            {list.recommended === 1 ? ('Yes') : ('No')}
                                                        </b></h6>
                                                        <h6>Interested in Mentoring the Startup :
                                                            {
                                                                field.roundNo === 1 ? (
                                                                    <b>
                                                                        {list.mentorTheStartup === 0 && (' Undisclosed')}
                                                                        {list.mentorTheStartup === 1 && (' Yes')}
                                                                        {list.mentorTheStartup === 2 && (' No')}
                                                                    </b>
                                                                ) : (
                                                                    <b>
                                                                        {list.mentorTheStartup === 1 ? (' Yes') : (' No')}
                                                                    </b>
                                                                )
                                                            }
                                                        </h6>
                                                        {/* <h6>Average Questions Score: <b>{list.score ? (list.score) : (`-`)}</b></h6> */}
                                                    </div>
                                                    <Divider />
                                                    {
                                                        list.startupQuestions !== undefined && (
                                                            <Questionslistdesign value={list.startupQuestions} />
                                                        )
                                                    }
                                                    {/* <Questionslistdesign value={list.startupQuestions}/> */}
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }
                                </>
                            )
                        }

                    </AccordionDetails>
                </Accordion>
            )) : (
                <>
                    <p className='text-center p-5'>No Rounds Added</p>
                </>
            )
            }
        </div >
    )
}

export default Associatedrounds