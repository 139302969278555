import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormHelperText,
  TextField,
  Switch,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PreviewIcon from "@mui/icons-material/Preview";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";
import { MuiFileInput } from "mui-file-input";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import notifyService from "../../../api/notifySerivce";
const DocumentsUploadtanseed = () => {
  const { handleBacktanseed, VisuallyHiddenInput } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [holdIp, setHoldIp] = useState(false);
  const [pitchDeckValue, setPitchDeckValue] = useState(false);
  const [roundOfInvestments, setRoundOfInvestments] = useState(false);
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
  }, []);
  const getApi = async () => {
    await apiService(`startup/supportingdocs/get`, "", "get").then((res) => {
      if (res) {
        let response = res.data;
        if (response.id !== 0) {
          setFormData({});
          setFormData(response);
          if (response.editForm === false) {
            setReadyOnlyValue(true);
          }
          if (response.holdIP) {
            setHoldIp(response.holdIP)
          }
          if (response.ipCertificateName) {
            setIpCertificateValue(response.ipCertificateName);
            setIpCertificateName(response.ipCertificate);
            setIpCertificateURL(response.ipCertificateName);

            // setFinancialProjectValue(response.financialProjectionsName);
            // setFinancialProjectName(response.financialProjections);
            // setFinancialProjectURL(response.financialProjectionsName);

            // setUserDataValue(response.userDataName);
            // setUserDataName(response.userData);
            // setUserDataURL(response.userDataName);

            // setAnalysisReportValue(response.analysisReportName);
            // setAnalysisReportName(response.analysisReport);
            // setAnalysisReportURL(response.analysisReportName);

            // setSupportingDocValue(response.supportingDocName);
            // setSupportingDocName(response.supportingDoc);
            // setSupportingDocURL(response.supportingDocName);
          }
        } else {
          setFormData(response);
        }
      }
    });
  };

  const [ipCertificateValue, setIpCertificateValue] = useState(null);
  const [ipCertificateName, setIpCertificateName] = useState(null);
  const [ipCertificateURL, setIpCertificateURL] = useState(null);
  const [holdIpName, setHoldIpName] = useState(null);

  const [financialProjectValue, setFinancialProjectValue] = useState(null);
  const [financialProjectName, setFinancialProjectName] = useState(null);
  const [financialProjectURL, setFinancialProjectURL] = useState(null);

  const [userDataValue, setUserDataValue] = useState(null);
  const [userDataName, setUserDataName] = useState(null);
  const [userDataURL, setUserDataURL] = useState(null);

  const [analysisReportValue, setAnalysisReportValue] = useState(null);
  const [analysisReportName, setAnalysisReportName] = useState(null);
  const [analysisReportURL, setAnalysisReportURL] = useState(null);

  const [supportingDocValue, setSupportingDocValue] = useState(null);
  const [supportingDocName, setSupportingDocName] = useState(null);
  const [supportingDocURL, setSupportingDocURL] = useState(null);
  let logoselecetdFile = "";
  const handleFileUpload = (
    event,
    filekey,
    fileName,
    setdata,
    setName,
    setUrl
  ) => {
    setValue(filekey, "");
    setValue(fileName, "");
    setdata("");
    setName("");
    setUrl("");
    debugger;
    if (event.target === undefined) {
      logoselecetdFile = event;
    } else {
      logoselecetdFile = event.target.files[0];
    }
    if (logoselecetdFile) {
      var reader = new FileReader();
      var imagetype = logoselecetdFile.type;
      var imagedatatype = imagetype.split("/");
      var img_crt_type = imagedatatype[1];
      if (
        img_crt_type === "jpeg" ||
        img_crt_type === "jpg" ||
        img_crt_type === "png" ||
        img_crt_type === "pdf"
      ) {
        if (event.target === undefined) {
          setdata(event);
        } else {
          setdata(event.target.files[0]);
        }
        var fileValue = logoselecetdFile;
        reader.readAsDataURL(logoselecetdFile);
        reader.onload = () => {
          var logourl1 = reader.result;
          var spl = logourl1.split(",");
          var ImageValue = spl[1];
          var img_name = fileValue.name;
          setUrl(logourl1);
          setValue(filekey, ImageValue);
          setValue(fileName, img_name);
          setName(img_name);
          clearErrors(filekey);
          clearErrors(fileName);
          //   if (filekey === "product") {
          //     setProductInvalid(false)
          //   }
          debugger;
        };
      }
    }
  };
  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      debugger;
      apiService("startup/supportingdocs/save", getform, "post").then((res) => {
        setLoading(false);
        if (res) {
          if (res.data.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            handleBacktanseed();
          }
        }
      });
    }
  };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBacktanseed}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>{" "}
            <h6 className="ml-2 mb-0">Documents Upload</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <FormLabel>Do you hold IPs *</FormLabel>
                  <Controller
                    name="holdIP"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            setHoldIp(e.target.value);
                          }}
                          defaultValue="null"
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            setHoldIp(e.target.value);
                            setValue("ipCertificate", "");
                          }}
                          defaultValue="null"
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.holdIp && errors.holdIp.message}
                  </FormHelperText>
                </div>
              </div>
              {(holdIp === "true" || holdIp === true) && (
                <div className="col-lg-6 col-12">
                  {formData.ipCertificate !== "c29tZXZhbHVl" ? (
                    <div className="form-group">
                      <FormLabel>
                        Upload Ip Certificate
                        <span className="text-danger">*</span>
                      </FormLabel>
                      <Controller
                        name="ipCertificate"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <MuiFileInput
                            {...field}
                            onChange={(e) =>
                              handleFileUpload(
                                e,
                                "ipCertificate",
                                "ipCertificateName",
                                setIpCertificateValue,
                                setIpCertificateName,
                                setIpCertificateURL
                              )
                            }
                            value={ipCertificateValue}
                            variant="outlined"
                            fullWidth
                            // label="Upload Ip Certificate"
                            placeholder="Choose File"
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.ipCertificate &&
                          errors.ipCertificate.message}
                      </FormHelperText>
                      <span className="font-sm">
                        Maximum 10 mb allowed doc (png, jpg, jpeg, pdf){" "}
                        <span className="text-danger">*</span>
                      </span>
                    </div>
                  ) : (
                    <div>
                      <FormLabel>
                        Upload Ip Certificate
                        <span className="text-danger">*</span>
                      </FormLabel>
                      <div className="row">
                        <div className="col-lg-8 col-12">
                          <Controller
                            name="ipCertificate"
                            control={control}
                            defaultValue=""
                            rules={{
                              required:
                                "In Corporation Certificate File is required",
                            }}
                            render={({ field }) => (
                              <Button
                                component="label"
                                variant="contained"
                                {...field}
                                fullWidth
                                onChange={(e) =>
                                  handleFileUpload(
                                    e,
                                    "ipCertificate",
                                    "ipCertificateName",
                                    setIpCertificateValue,
                                    setIpCertificateName,
                                    setIpCertificateURL
                                  )
                                }
                                startIcon={<CloudUploadIcon />}
                                href="#file-upload"
                                disabled={readyOnlyValue}
                              >
                                Change
                                {readyOnlyValue === false ? (
                                  <VisuallyHiddenInput type="file" />
                                ) : (
                                  <></>
                                )}
                              </Button>
                            )}
                          />
                          <span className="font-sm">
                            Maximum 10 mb allowed doc (png, jpg, jpeg, pdf){" "}
                            <span className="text-danger">*</span>
                          </span>
                        </div>
                        <div className="col-lg-4 col-12">
                          <Fab variant="extended">
                            <Link
                              to={`${ipCertificateURL}`}
                              target="_blank"
                              color="black"
                            >
                              <PreviewIcon sx={{ mr: 1 }} />
                              View Document
                            </Link>
                          </Fab>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="div_header">
              <div className="d-flex">
                <h6>Support</h6>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <FormLabel>
                    Looking to Raise the Next Round of Investment?
                  </FormLabel>
                  <Controller
                    name="supportRequired"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          // onChange={(e) => {
                          //   setRoundOfInvestments(e.target.value);
                          // }}
                          defaultValue="null"
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          // onChange={(e) => {
                          //   setRoundOfInvestments(e.target.value);
                          //   setValue("RoundOfInvestments", "");
                          // }}
                          defaultValue="null"
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.supportRequired && errors.supportRequired.message}
                  </FormHelperText>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="furtherSupport"
                      control={control}
                      defaultValue=""
                      fullWidth
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          id="outlined-multiline-static"
                          label="Further Support Expectations"
                          placeholder="Enter Further Support Expectations"
                          fullWidth
                          {...field}
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.furtherSupport && errors.furtherSupport.message}
                    </FormHelperText>
                  </div>
                </div>
              </div>

            </div>

            <div className="form-group text-center">
              <Button
                variant="contained"
                onClick={handleBacktanseed}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default DocumentsUploadtanseed;
