import React, { useContext, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import DataContext from '../../../context/DataContext';
import TablePagination from '@mui/material/TablePagination';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import apiService from '../../../api/apiService';
import downloadExcel from '../../../hooks/downloadExcel';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import notifyService from '../../../api/notifySerivce';
import { Box, Dialog, Modal } from '@mui/material';
import SendEmail from '../startup-page/SendEmail';
import { useForm } from 'react-hook-form';
import SendEmailTemplate from './SendEmailTemplate';
import Scsthubavpremarks from '../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks';


const TanseedProgressList = ({ startupId }) => {
    const { open, modalstyle, setPageLoading, tableIndexValue, fullScreen } = useContext(DataContext)
    const [partnerListData, setPartnerListData] = useState([])
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [activeStartup, setActiveStartup] = useState(1)
    const [acceptTanseed, setAcceptTanseed] = useState(true);
    const [searchInputValue, setSearchInputValue] = useState('');

    useDidMountEffect(() => {
        userListItemTanseed()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, searchInputValue, activeStartup, acceptTanseed])
    const userListItemTanseed = async () => {
        setPartnerListData([])
        var reqTanseed = {
            "listSize": rowsPerPage,
            "pageNumber": page + 1,
            "startupId": startupId,
            "type": "TANSEED"
        }
        await apiService('admin/workflow/activity/list', reqTanseed, 'post').then((res) => {
            if (res) {
                setCount(res.data.count)
                if (res.data.activity) {
                    setPartnerListData(res.data.activity)

                } else {
                    setPartnerListData([])
                }
            }
        })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };




    return (
        <div className='table_tag'>
            <div className='table_header_tag set-res-flex'>
                <h5>History</h5>
                <div className='ml-auto'>
                    <SendEmailTemplate startupId={startupId} userListItemTanseed={userListItemTanseed}/>
                </div>
            </div>
            <div>
                {
                    (partnerListData.length) ? (
                        <TableContainer component={Paper}>
                            <Table aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>Activity</TableCell>
                                        <TableCell align='center'>Message</TableCell>
                                        <TableCell align='center'>Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {partnerListData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {
                                                    tableIndexValue(rowsPerPage, page, index)
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {row.activity}
                                            </TableCell>
                                            <TableCell align='center'>{row.message ? (<Scsthubavpremarks remarks={row.message} />) : '-'}</TableCell>
                                            <TableCell align='center'>{row.time ? row.time : '-'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <caption>
                                    <TablePagination
                                        component="div"
                                        count={count}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        style={{float:'left'}}
                                    /></caption>
                            </Table>

                        </TableContainer>
                    ) : (
                        <div className='nodata_tag'>
                            <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                            <h4>TANSEED list is empty</h4>
                        </div>
                    )
                }
            </div>


        </div>
    )
}
export default TanseedProgressList