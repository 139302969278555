import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import DataContext from '../../context/DataContext';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import apiService from '../../api/apiService';
import { Autocomplete, InputBase } from '@mui/material'
import { TextField } from '@mui/material';
import CustomLink from '../common-page/CustomLink';
import OnBoradFilter from '../scsthubavp-module/onboraded-details/OnBoradFilter';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import { Tab } from "@mui/material";
import Box from "@mui/material/Box";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Scstjurylist from './Scstjurylist';
const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '17ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const Scstlist = ({ round, path }) => {
    const { setPageLoading, tableIndexValue,scstRemoveFiters } = useContext(DataContext)
    const [scstList, setScstList] = useState([])
    const [count, setCount] = useState(0);
    var pageNumberPartners = Number(localStorage.getItem('pageScst'))
    const [page, setPage] = useState(pageNumberPartners ? pageNumberPartners : 0);

    var listSizePartners = Number(localStorage.getItem('rowsPerPageScst'))
    const [rowsPerPage, setRowsPerPage] = useState(listSizePartners ? listSizePartners : 10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        localStorage.setItem('pageScst', newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        localStorage.setItem('rowsPerPageScst', parseInt(event.target.value, 10))
        localStorage.setItem('pageScst', 0)
        setPage(0);
    };

    var districtNameScst = localStorage.getItem('districtNameScst')
    const [districtName, setDistrictName] = useState(districtNameScst ? districtNameScst : '')

    var hubScst = localStorage.getItem('hubScst')
    const [scsthub, setScsthub] = useState(hubScst ? hubScst : "");

    var yearNameScst = Number(localStorage.getItem('yearNameScst'))
    const [yearName, setYearName] = useState(yearNameScst ? yearNameScst : 2022);
    console.log(yearNameScst)
    var monthNameScst = Number(localStorage.getItem('monthNameScst'))
    const [monthName, setMonthName] = useState(monthNameScst ? monthNameScst : 0);

    var manualNameScst = Number(localStorage.getItem('manualNameScst'))
    const [manualName, setManualName] = useState(manualNameScst ? manualNameScst : 0)

    var searchInputValueScst = localStorage.getItem('searchInputValueScst')
    const [searchInputValue, setSearchInputValue] = useState(searchInputValueScst ? searchInputValueScst : '');

    var statusValueScst = Number(localStorage.getItem('statusValueScst'))
    const [statusValue, setStatusValue] = useState(statusValueScst ? statusValueScst : 1)

    useDidMountEffect(() => {
        scstListItem()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, districtName, round, yearName, monthName, manualName, searchInputValue, statusValue])


    useDidMountEffect(() => {
        if (path === 'evaluation') {
            var statusValueScst = Number(localStorage.getItem('statusValueScst'))
            setStatusValue(statusValueScst ? statusValueScst : 1);
        } else {
            var statusValueScst = Number(localStorage.getItem('statusValueScst'))
            setStatusValue(statusValueScst ? statusValueScst : 0);
        }
    }, [path]);
    const scstListItem = () => {
        setPageLoading(true)
        setScstList([])
        var req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            district: districtName,
            round: round,
            year: yearName,
            month: monthName,
            onboardType: manualName,
            searchString: searchInputValue,
            status: statusValue
        };
        apiService('partner/scst/startups/list', req, 'post').then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data) {
                    setCount(res.data.count)
                    if (res.data.startups) {
                        setScstList(res.data.startups)
                    } else {
                        setScstList([])
                    }
                }

            }
        })
    }
    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            {
                path === 'evaluation' ? (
                    <>
                        <div className="scsthub-div form_div chart">

                            <Box sx={{ width: "100%", typography: "body1" }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <TabList
                                            onChange={handleChange}
                                            aria-label="lab API tabs example"
                                        >
                                            <Tab label="Startups" value="1" />
                                            <Tab
                                                label="Evaluators"
                                                value="2"
                                                onClick={()=>scstRemoveFiters()}
                                            />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        {/* <div className='header_tag'>
                                            <h5>Startup List</h5>
                                        </div> */}
                                        <OnBoradFilter
                                            setPage={setPage}
                                            districtName={districtName} setDistrictName={setDistrictName}
                                            scsthub={scsthub} setScsthub={setScsthub}
                                            yearName={yearName} setYearName={setYearName}
                                            monthName={monthName} setMonthName={setMonthName}
                                            manualName={manualName} setManualName={setManualName} path={path}
                                            searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue}
                                            statusValue={statusValue} setStatusValue={setStatusValue}
                                        />
                                        <div className='table_tag'>
                                            {
                                                (scstList.length) ? (
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>No</TableCell>
                                                                    <TableCell>Startup Name</TableCell>
                                                                    <TableCell>District</TableCell>
                                                                    {/* <TableCell>Assigned to hub</TableCell> */}
                                                                    <TableCell>Entity</TableCell>
                                                                    <TableCell>Onboarded Type</TableCell>
                                                                    <TableCell>Date</TableCell>
                                                                    <TableCell align='center'>Status</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {scstList.map((row, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell>
                                                                            {
                                                                                tableIndexValue(rowsPerPage, page, index)
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <CustomLink to={`/scst/startups/${path}/details/${btoa(row.startupId)}/${btoa(row.partnerOrInvestorId)}`}>{row.startupName}</CustomLink>
                                                                        </TableCell>
                                                                        <TableCell>{row.district}</TableCell>
                                                                        {/* <TableCell>{row.entityType}</TableCell> */}
                                                                        <TableCell>{row.entityType}</TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                row.onboardType === 1 ? 'Manual' : 'Portal'
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>{row.date}</TableCell>
                                                                        <TableCell align='center'>{row.status}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                            <caption>
                                                                <TablePagination
                                                                    component="div"
                                                                    count={count}
                                                                    page={page}
                                                                    onPageChange={handleChangePage}
                                                                    rowsPerPage={rowsPerPage}
                                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                                    style={{ float: 'left' }}
                                                                /></caption>
                                                        </Table>
                                                    </TableContainer>
                                                ) : (
                                                    <div className='nodata_tag'>
                                                        <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                                        <h4>Startup list is empty</h4>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </TabPanel>
                                    <TabPanel value="2" >
                                        <Scstjurylist path={'scst'} />
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                    </>
                ) : (
                    <>
                        <section>
                            <div className='container-fluid'>
                                <div className='form_div chart'>
                                    {/* <div className='header_tag d-flex'>
                                        <h5>Startup List</h5>
                                    </div> */}
                                    <OnBoradFilter
                                        setPage={setPage}
                                        districtName={districtName} setDistrictName={setDistrictName}
                                        scsthub={scsthub} setScsthub={setScsthub}
                                        yearName={yearName} setYearName={setYearName}
                                        monthName={monthName} setMonthName={setMonthName}
                                        manualName={manualName} setManualName={setManualName} path={path}
                                        searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue}
                                        statusValue={statusValue} setStatusValue={setStatusValue}
                                        lblName='Startup List'
                                    />
                                    <div className='table_tag'>
                                        {
                                            (scstList.length) ? (
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>No</TableCell>
                                                                <TableCell>Startup Name</TableCell>
                                                                <TableCell>District</TableCell>
                                                                {/* <TableCell>Assigned to hub</TableCell> */}
                                                                <TableCell>Entity</TableCell>
                                                                <TableCell>Onboarded Type</TableCell>
                                                                <TableCell>Date</TableCell>
                                                                <TableCell align='center'>Status</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {scstList.map((row, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        {
                                                                            tableIndexValue(rowsPerPage, page, index)
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <CustomLink to={`/scst/startups/${path}/details/${btoa(row.startupId)}/${btoa(row.partnerOrInvestorId)}`}>{row.startupName}</CustomLink>
                                                                    </TableCell>
                                                                    <TableCell>{row.district}</TableCell>
                                                                    {/* <TableCell>{row.entityType}</TableCell> */}
                                                                    <TableCell>{row.entityType}</TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            row.onboardType === 1 ? 'Manual' : 'Portal'
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>{row.date}</TableCell>
                                                                    <TableCell align='center'>{row.status}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                        <caption>
                                                            <TablePagination
                                                                component="div"
                                                                count={count}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                rowsPerPage={rowsPerPage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                                style={{ float: 'left' }}
                                                            /></caption>
                                                    </Table>
                                                </TableContainer>
                                            ) : (
                                                <div className='nodata_tag'>
                                                    <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                                    <h4>Startup list is empty</h4>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )
            }


        </>

    )
}

export default Scstlist
