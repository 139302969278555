import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import apiService from '../../../api/apiService';
import Additionalinfo from '../additional-info-page/Additionalinfo';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import Dialog from '@mui/material/Dialog';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import Startuphistroy from '../additional-info-page/Startuphistroy';
import { Divider, TablePagination } from '@mui/material';
import DataContext from '../../../context/DataContext';
import ScstBddStartups from '../../scstBdd-page/scstBdd-details/ScstBddStartups';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEffect } from 'react';
// import Resubmit from '../../investor-module/associated-startups-page/associated-details-page/pages/Resubmit';
// import StartupAdditionalinfo from './StartupAdditionalinfo';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const Progresslist = ({ name }) => {
  const userId = localStorage.getItem("userId")
  const { setPageLoading, setHasNotification, hasNotification } = useContext(DataContext)
  useDidMountEffect(() => {
    listApi()
  }, [])
  const [listData, setListData] = useState([])
  const listApi = () => {
    var req = {
      "startupId": userId
    }
    apiService('partner/clarification/list', req, 'post').then((result) => {
      if (result) {
        if (result.data) {
          if (result.data.clarificationDetails) {
            setListData(result.data.clarificationDetails)
          }
          if (result.data.clarificationDetails) {
            const notifi = result.data.clarificationDetails.map(notifi => notifi.notification)
            setHasNotification(notifi)
            debugger
          }
        }
      }
    }).catch((err) => {

    });
  }
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useDidMountEffect(() => {
    progressList()
  }, [name, page, rowsPerPage])
  const [progressData, setProgressData] = useState([])
  const [partnerId, setPartnerId] = useState()
  const progressList = () => {
    setPageLoading(true)
    var reqData = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      fund: name
    };
    apiService(`startup/partners/list`, reqData, 'post').then((res) => {
      setPageLoading(false)
      if (res) {
        if (res.data.startupPartner) {
          setCount(res.data.count);
          setProgressData(res.data.startupPartner)
          // setPartnerId(res.data.startupPartner.partnerorInvestorId)
          setPartnerId(res.data.startupPartner[0].partnerorInvestorId)
          console.log(res.data.startupPartner[0].partnerorInvestorId, 'id')
        } else {
          setProgressData([])
        }
      }
    })
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [statusValue, setStatusValue] = useState(0)
  useDidMountEffect(() => {
    if (partnerId) {
      historyList()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusValue, partnerId]);

  const handleStatus = (value) => {
    setStatusValue(value)
  }

  const [historyListValue, setHistoryListValue] = useState([])
  const [countHistory, setCountHistory] = useState(0);
  const [pageHistory, setPageHistory] = useState(0);
  const [rowsPerPageHistory, setRowsPerPageHistory] = useState(10);
  const [historyNotify, setHistoryNotify] = useState(false)
  const historyList = () => {
    setHistoryListValue([])
    setPageLoading(true)
    var req = {
      listSize: rowsPerPageHistory,
      pageNumber: pageHistory + 1,
      userId: partnerId,
      status: statusValue
    }
    apiService('startup/additionalinfo/history', req, 'post').then((res) => {
      setPageLoading(false)
      if (res && res.data) {
        setCountHistory(res.data.count)
        setHistoryNotify(res.data.additionalInfoRequested)
        if (res.data.additionalInfo) {
          setHistoryListValue(res.data.additionalInfo)
        }
      }
    })
  }
  const handleHistoryChangePage = (event, newPage) => {
    setPageHistory(newPage);
  };
  const handleChangeHistoryRowsPerPage = (event) => {
    setRowsPerPageHistory(parseInt(event.target.value, 10));
    setPageHistory(0);
  };

  const [addInfoValue, setAddInfoValue] = React.useState('1');
  const handleChangeInfo = (event, newValue) => {
    setAddInfoValue(newValue);
  };


  const [open, setOpen] = React.useState(false);
  const [partnerorInvestorId, setPartnerorInvestorId] = useState()
  const handleClickOpen = (id) => {
    setOpen(true);
    setPartnerorInvestorId(id)
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };




  return (
    <>
      {
        name === 'scst' ? (
          <section className='container-fluid'>
            <div className="form_div chart">
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Validation / Evaluation" {...a11yProps(0)} />
                    <Tab label="BDD"{...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <section className='container-fluid'>
                    <div className='form_div'>
                      <div className='table_tag'>
                        {
                          (progressData.length) ? (
                            <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    {
                                      name === 'scst' ? (
                                        <>
                                          <TableCell>HUB Name</TableCell>
                                          <TableCell align='center'>Comment</TableCell>
                                        </>
                                      ) : (
                                        <>
                                          <TableCell>Investor</TableCell>

                                        </>
                                      )
                                    }
                                    <TableCell align='center'>Application Status</TableCell>
                                    <TableCell align='center'>Additional Info</TableCell>
                                    <TableCell align='center'>Additional Info History</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {progressData.map((list, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      {
                                        name === 'scst' ? (
                                          <>
                                            <TableCell>{list.partnerName}</TableCell>
                                            <TableCell align='center'>
                                              {(list.partnerComments) ? (list.partnerComments) : (`-`)}
                                            </TableCell>
                                          </>
                                        ) : (
                                          <>
                                            <TableCell>{list.partnerName}</TableCell>
                                          </>
                                        )
                                      }
                                      <TableCell align='center'>
                                        {list.status}
                                      </TableCell>
                                      <TableCell align='center'>
                                        <p className="nav-link mb-0"><Additionalinfo status={list.additionalInfo} id={list.partnerorInvestorId} listApi={progressList} /></p>
                                      </TableCell>
                                      <TableCell align='center'>
                                        <span className='reqSpan' onClick={() => handleClickOpen(list.partnerorInvestorId)}>
                                          View
                                        </span>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                                <caption>
                                  <TablePagination
                                    component="div"
                                    count={count}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    style={{ float: 'left' }}
                                  /></caption>
                              </Table>

                            </TableContainer>
                          ) : (
                            <div className='nodata_tag'>
                              <img src='./images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                              <p>A partner will be assigned as soon as the criteria matches</p>
                            </div>
                          )
                        }

                      </div>
                    </div>
                    <Dialog
                      maxWidth={'lg'}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <Startuphistroy partnerorInvestorId={partnerorInvestorId} handleClose={handleClose} />
                    </Dialog>
                  </section>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <ScstBddStartups setListData={setListData} listData={listData} partnerId={partnerId} />
                </CustomTabPanel>
              </Box>

            </div>
          </section>
        ) : (
          <section className='container-fluid'>
            <div className='form_div'>
              <div className='table_tag'>
                {
                  (progressData.length) ? (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell>No</TableCell>
                            {
                              name === 'scst' ? (
                                <>
                                  <TableCell>HUB Name</TableCell>
                                  <TableCell align='center'>Comment</TableCell>
                                </>
                              ) : (
                                <>
                                  <TableCell>Investor</TableCell>

                                </>
                              )
                            }
                            <TableCell align='center'>Application Status</TableCell>
                            <TableCell align='center'>Additional Info</TableCell>
                            <TableCell align='center'>Additional Info History</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {progressData.map((list, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              {
                                name === 'scst' ? (
                                  <>
                                    <TableCell>{list.partnerName}</TableCell>
                                    <TableCell align='center'>
                                      {(list.partnerComments) ? (list.partnerComments) : (`-`)}
                                    </TableCell>
                                  </>
                                ) : (
                                  <>
                                    <TableCell>{list.partnerName}</TableCell>
                                  </>
                                )
                              }
                              <TableCell align='center'>
                                {list.status}
                              </TableCell>
                              <TableCell align='center'>
                                <p className="nav-link mb-0"><Additionalinfo status={list.additionalInfo} id={list.partnerorInvestorId} listApi={progressList} /></p>
                              </TableCell>
                              <TableCell align='center'>
                                <span className='reqSpan' onClick={() => handleClickOpen(list.partnerorInvestorId)}>
                                  View
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <caption>
                          <TablePagination
                            component="div"
                            count={count}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ float: 'left' }}
                          /></caption>
                      </Table>

                    </TableContainer>
                  ) : (
                    <div className='nodata_tag'>
                      <img src='./images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                      <p>A partner will be assigned as soon as the criteria matches</p>
                    </div>
                  )
                }

              </div>
            </div>
            <Dialog
              maxWidth={'lg'}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <Startuphistroy partnerorInvestorId={partnerorInvestorId} handleClose={handleClose} />
            </Dialog>
          </section>
        )
      }

    </>

  )
}

export default Progresslist