import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import { Autocomplete, Box, Button, Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Modal, OutlinedInput, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DataContext from '../../../../context/DataContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import Fundingprograms from '../../dashboard-page/form-page/modal-form/Fundingprograms';
// import Fundingprograms from '../../form-page/modal-form/Fundingprograms';
const InvestorFunding = () => {
    const [formData, setFormData] = useState({
        // fundHistory: [{}]
    });
    const { handleBackinvestor, navigator, MenuProps, Paper, modalstyle, icon, checkedIcon } = useContext(DataContext)
    const { handleSubmit, control, setValue, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        fields: fundFields,
        append: fundAppend,
        remove: fundRemove
    } = useFieldArray({
        control,
        name: "fundHistory"
    });
    const [fundingProgramDetails, setFundingProgramDetails] = useState([])
    // let preferredInvestorTypeList = []
    // let investmentTicketSize = []

    useDidMountEffect(() => {
        // eslint-disable-next-line                
        (async () => await getApi())()
        dropdownValueApi()
        // eslint-disable-next-line
    }, [])
    const [preferredInvestorTypeList, setPreferredInvestorTypeList] = useState([])
    const [investmentTicketSize, setInvestmentTicketSize] = useState([])
    const [instrumentTypeData, setInstrumentTypeData] = useState([])
    const [revenuesList, setLevenuesList] = useState([])
    const [startupinstrumentTypeList, setStartupinstrumentTypeList] = useState([])
    const [fundingProgramsList, setfundingProgramsList] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=startup`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            // setInvestmentTicketSize(val.desiredFundingAmount)
            setStartupinstrumentTypeList(val.previousFundHistoryInstruments)

            debugger
        })
    }
    const getApi = async () => {
        await apiService(`startup/fundingandfinancials/get`, '', 'get').then((res) => {
            if (res) {
                setFormData({})
                let response = res.data
                if (response.id !== 0) {

                    setFormData(response)
                    if (response.editForm === false) {
                        setReadyOnlyValue(true)
                    }
                    if (response.startupFundingPrograms) {
                        setFundingProgramDetails(response.startupFundingPrograms)
                    }
                    if (response.preferredInvestorType) {
                        setPreferredInvestorTypeValue(response.preferredInvestorType);
                    }
                 
                    if (response.investmentInstrumentType) {
                        setInvestmentInstrumentTypeValue(response.investmentInstrumentType)
                    }

                } else {
                    setFormData(response)
                }
            }
        })
    }

    const addFundDetailsBtn = () => {
        fundAppend({
            fundRaised: "",
            organisationName: "",
            instrumentType: ""
        })
    }
    const [fundingProgramIndex, setFundingProgramIndex] = useState('')
    const addFundingProgramBtn = (index) => {
        setFundingProgramIndex(index)
        handleOpen()
    }
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // const fundingStageList = [
    //     "Bootstrapped",
    //     "Friends & Family",
    //     "Seed/Angle Funded",
    //     "Pre-Series",
    //     "Series A or Beyond",
    // ];
    // const [fundingStageValue, setFundingStageValue] = useState(undefined);
    // const handleSelectfundingStage = (value) => {
    //     // setFundingStageValue(value);
    //     setValue('fundingStage', value)
    //     clearErrors('fundingStage');
    // };

    const [preferredInvestorTypeValue, setPreferredInvestorTypeValue] = useState([]);
    const handleSelectPreferredInvestorType = (value) => {
        setPreferredInvestorTypeValue(value);
        setValue('preferredInvestorType', value)
        clearErrors('preferredInvestorType');
    };

    const handleSelectRevenues = (value) => {
        setValue('revenueStage', value)
        clearErrors('revenueStage');
    };

    // const [investTicketSizeValue, setInvestTicketSizeValue] = useState('');
    const handleSelectTicketSize = (value) => {
        // setInvestTicketSizeValue(value);
        setValue('investTicketSize', value)
        clearErrors('investTicketSize');
    };

    const [investmentInstrumentTypeValue, setInvestmentInstrumentTypeValue] = useState([]);
    const handleSelectInvestment = (value) => {
        setInvestmentInstrumentTypeValue(value);
        setValue('investmentInstrumentType', value)
        clearErrors('investmentInstrumentType');
    };

    // const [fundingProgramInvalid, setFundingProgramInvalid] = useState(false);
    // delete funding
  
    // delete Tractions
    const deleteFundingHistory = async (index) => {
        const id = getValues(`fundHistory.${index}.id`);
        if (id !== undefined) {
            apiService(`startup/fundhistory/remove?id=${id}`, '', 'get').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        fundRemove(index)
                        getApi()
                    }
                }
            })
        } else {
            fundRemove(index)
        }
    }
    const onSubmit = (data) => {
        // checkFundingValidation()
        if (readyOnlyValue === false) {
            setLoading(true);
            var getform = data
            if (fundingProgramDetails.length > 0) {
                getform.startupFundingPrograms = fundingProgramDetails
            } else {
                getform.startupFundingPrograms = []
            }

            apiService('startup/fundingandfinancials/save', getform, 'post').then((res) => {
                setLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        navigator('/startup/investor/Team')
                    }
                }
            })
        }
    };
    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className='d-flex align-items-center'>
                        <span className='back_span' onClick={handleBackinvestor}><i className="fas fa-arrow-alt-circle-left"></i></span> <h6 className='ml-2 mb-0'>Funding & Financials</h6>
                    </div>
                    <hr />
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='d-none'>
                                <div className="form-group">
                                    <Controller name="id" control={control} defaultValue="0"
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>
                           
                            
                           
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <FormControl sx={{ width: '100%' }}>
                                        <Controller name="fundAmount" control={control} defaultValue="" rules={{ required: 'Desired Funding Amount is required' }}
                                            render={({ field }) =>
                                                // <Autocomplete
                                                //     disableClearable
                                                //     disablePortal
                                                //     {...field}
                                                //     options={investmentTicketSize}
                                                //     onChange={(e, selectedOptions) => handleSelectTicketSize(selectedOptions)}
                                                //     renderInput={(params) => <TextField {...params} label="Desired Funding Amount (₹)*" />}
                                                //     disabled={readyOnlyValue}
                                                // />
                                                <TextField
                                                variant='outlined'
                                                label="Desired Funding Amount (₹)*"
                                                placeholder='Enter Desired Funding Amount (₹)'
                                                fullWidth
                                                type='number'
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />
                                            } />
                                        <FormHelperText className='text-danger'>{errors.fundAmount && errors.fundAmount.message}</FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                            
                           
                           
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="annualRevenue" control={control} defaultValue="" rules={{ required: 'Annual Revenue is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Annual Revenue (₹)*"
                                                placeholder='Enter Annual Revenue (₹)'
                                                fullWidth
                                                 type='number'
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.annualRevenue && errors.annualRevenue.message}</FormHelperText>
                                </div>
                            </div>
                            
                            
                            <div className='col-12 mb-3'>
                                <div className='div_header'>
                                    <div className='d-flex'>
                                        <h6>Previous Fundraising History</h6>
                                        <Button variant="outlined" disabled={readyOnlyValue} className='ml-auto' onClick={() => addFundDetailsBtn('')}>Add Fundraising History</Button>
                                    </div>
                                </div>
                                <div>
                                    {
                                        (fundFields.length > 0) ? (
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                    {/* <TableHead>
                                                <TableRow>
                                                    <TableCell>No</TableCell>
                                                    <TableCell>Fund Raised *</TableCell>
                                                    <TableCell>Organization Name *</TableCell>
                                                    <TableCell>Instrument Type *</TableCell>
                                                    <TableCell align='center'>Action</TableCell>
                                                </TableRow>
                                            </TableHead> */}
                                                    <TableBody>
                                                        {fundFields.map((field, index, type) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>
                                                                    <FormControl sx={{ width: '100%' }} >
                                                                        <Controller name={`fundHistory.${index}.fundRaised`} control={control} defaultValue="" rules={{ required: 'Fund Raised is required' }}
                                                                            render={({ field }) =>
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    label="Fund Raised (₹)*"
                                                                                    placeholder='Enter Fund Raised (₹)'
                                                                                    fullwidth
                                                                                    {...field}
                                                                                    inputProps={
                                                                                        { readOnly: readyOnlyValue, }
                                                                                    }
                                                                                />} />
                                                                        <FormHelperText className='text-danger'>
                                                                            {errors.fundHistory?.[index]?.fundRaised && errors.fundHistory?.[index]?.fundRaised.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FormControl sx={{ width: '100%' }} >
                                                                        <Controller name={`fundHistory.${index}.organisationName`} control={control} defaultValue="" rules={{ required: 'Organization Name is required' }}
                                                                            render={({ field }) =>
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    label="Organization Name *"
                                                                                    placeholder='Enter Organization Name'
                                                                                    fullwidth
                                                                                    {...field}
                                                                                    inputProps={
                                                                                        { readOnly: readyOnlyValue, }
                                                                                    }
                                                                                />} />
                                                                        <FormHelperText className='text-danger'>
                                                                            {errors.fundHistory?.[index]?.organisationName && errors.fundHistory?.[index]?.organisationName.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell sx={{ width: '25%' }}>
                                                                    <FormControl sx={{ width: '100%' }} >
                                                                        <InputLabel htmlFor="grouped-native-select">Instrument Type *</InputLabel>
                                                                        <Controller name={`fundHistory.${index}.instrumentType`} control={control} defaultValue="" rules={{ required: 'Instrument Type is required' }}
                                                                            render={({ field }) =>
                                                                                <Select
                                                                                    {...field}
                                                                                    readOnly={readyOnlyValue}
                                                                                    defaultValue={type}
                                                                                    input={<OutlinedInput label="Name" />}
                                                                                    MenuProps={MenuProps}
                                                                                >
                                                                                    {startupinstrumentTypeList && startupinstrumentTypeList.map((name) => (
                                                                                        <MenuItem
                                                                                            key={name}
                                                                                            value={name}
                                                                                        >
                                                                                            {name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>} />
                                                                        <FormHelperText className='text-danger'>
                                                                            {errors.fundHistory?.[index]?.instrumentType && errors.fundHistory?.[index]?.instrumentType.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteFundingHistory(index) : <></> }} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>

                                            </TableContainer>
                                        ) : (
                                            <div className='nodata_tag'>
                                                <p>Fundraising history details is empty</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                           
                        </div>
                        <div className="form-group text-center">
                            <Button variant="contained" onClick={handleBackinvestor} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </LoadingButton>
                                ) : (<></>)
                            }

                        </div>
                    </form>
                    <Modal
                        open={open}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={modalstyle} className="form_tag_lg">
                            <Fundingprograms handleClose={handleClose} fundingProgramDetails={fundingProgramDetails} setFundingProgramDetails={setFundingProgramDetails} fundingProgramIndex={fundingProgramIndex} fundingProgramsList={fundingProgramsList} />
                        </Box>
                    </Modal>
                </div>
            </section>
        </>
    )
}

export default InvestorFunding