import React, { useEffect, useContext, useState } from 'react';
import { Checkbox, Container } from '@mui/material';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import apiService from '../../../api/apiService';
import { LoadingButton } from '@mui/lab';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import downloadExcel from '../../../hooks/downloadExcel';
import { Link } from 'react-router-dom';
import DataContext from '../../../context/DataContext';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import CustomLink from '../../common-page/CustomLink';

const Adminassociated = ({name,path,endpoint}) => {
    const { modalstyle, searchInputValue, startupListData, page, rowsPerPage, startuplistItem } = useContext(DataContext)
    const [checkedList, setCheckedList] = useState([]);
    useDidMountEffect(() => {
        (async () => await startuplistItem(name, endpoint))()
        // eslint-disable-next-line
    }, [page, rowsPerPage, searchInputValue, checkedList, name, path, endpoint]) 
    return (
        <section className=''>
            <div className='container-fluid'>
                <div className='form_div chart'>
                    <div className='header_tag'>
                        <h5>Associated Startups List</h5>
                    </div>
                    <div className='table_tag'>                        
                        {
                            (startupListData.length) ? (
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='center'>No</TableCell>
                                                <TableCell>Startup Name</TableCell>
                                                <TableCell align='center'>Partner / Investor Name</TableCell>
                                                <TableCell align='center'>Selected</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {startupListData.map((list, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align='center'>{index + 1}</TableCell>
                                                    <TableCell>
                                                    <CustomLink to={`/admin/associated-startups/details/${btoa(list.startupId)}/${btoa(list.partnerOrInvestorId)}`}>{list.startupName}</CustomLink>
                               
                                                        {/* {list.startupName} */}
                                                    </TableCell>
                                                    <TableCell align='center'>{list.partnerName}</TableCell>
                                                    <TableCell align='center'>
                                                        {
                                                            (list.selected === 1) ? (<span className='yes_span'>Yes</span>) : <></>
                                                        }
                                                        {
                                                            (list.selected === 2) ? (<span className='no_span'>No</span>) : <> </>
                                                        }
                                                        {
                                                            (list.selected === 0) ? (<span className='review_span'>Under Review</span>) : <></>
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            ) : (
                                <div className='nodata_tag'>
                                    <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                    <h4>Startup Association Inprogress</h4>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>            
        </section>
    )
}

export default Adminassociated