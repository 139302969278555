import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import Scsthubavpremarks from './Scsthubavpremarks';

const Scsthubavpvalidation = ({ listData }) => {
    const [viewQuestionData, setViewQuestionData] = useState([])
    const [startupData, setStartupData] = useState({})
    useDidMountEffect(() => {
        if (listData) {
            setStartupData(listData)
            if (listData.startupQuestions) {
                setViewQuestionData(listData.startupQuestions)
            }
        }
    }, [listData])

    const isRemark = startupData.remarks
    return (

        <>
            {
                startupData && viewQuestionData && (
                    <div>
                        <div className='table_tag'>
                            <div className='table_header_tag d-flex justify-content-between'>
                                {/* <div>Startup Name : <b>{startupData.startupName}</b></div> */}
                                {/* {isRemark && <div>Remarks : <Scsthubavpremarks remarks={startupData.remarks} /></div>} */}
                                {
                                    (viewQuestionData.length >= 1) && (
                                        <>
                                            <div>Remarks : <Scsthubavpremarks remarks={startupData.remarks} /></div>
                                            <div className='ml-auto'>Status :
                                                <b>
                                                    {startupData.status === 0 && <span> Pending</span>}
                                                    {startupData.status === 1 && <span className='c-green'> Approved</span>}
                                                    {startupData.status === 2 && <span className='c-red'> Rejected</span>}
                                                </b>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            {
                                (viewQuestionData.length) ? (
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>No</TableCell>
                                                    <TableCell>Question</TableCell>
                                                    <TableCell align='center'>Answer</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {viewQuestionData.map((list, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>
                                                            {
                                                                list.question
                                                            }

                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            {
                                                                list.questionType === 'yesno' ? (
                                                                    list.answer
                                                                ) : (
                                                                    list.score
                                                                )
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>

                                    </TableContainer>
                                ) : (
                                    <div className='nodata_tag'>
                                        <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                        <p>No Data Available</p>
                                    </div>
                                )
                            }

                        </div>
                    </div >
                )
            }
        </>

    )
}

export default Scsthubavpvalidation